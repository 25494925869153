import React from "react";
import useLoadingComponentStyles from "../../styles/kozel/loadingComponent";
import goatGif from '../../assets/images/goat-this.gif';
import {Grid} from "@mui/material";

const LoadingComponent: React.FC = () => {
    const styles = useLoadingComponentStyles();

    return (
        <Grid className={styles.loadingContainer}>
            <img className={styles.loadingBgGoatGif} src={goatGif} alt={'goatGif'}/>
            <Grid className={styles.loadingSpinner}></Grid>
        </Grid>
    );
};

export default LoadingComponent;