import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import bgImgDark from '../assets/images/bg1.png';
import bgImgLight from '../assets/images/bg2.png';
import bgWoodLight from "../assets/images/patternLemn.jpg";
import patternAlb from "../assets/images/patternAlb.jpg";

const useTeaserStyles = createUseStyles((theme: Theme) => {
    return {
        section: {
            padding: theme.spacing(0),
            position: "relative",
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(1),
            }
        },
        bgImgLight: {
            backgroundImage: `url(${bgImgLight})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        bgImgDark: {
            backgroundImage: `url(${bgImgDark})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        sectionTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vw,5vh)',
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(5),
            '&.contrast': {
                color: theme.palette.secondary.main
            }
        },
        sectionSubtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vw,2vh)',
            color: theme.palette.primary.main,
            '&.contrast': {
                color: theme.palette.secondary.main
            }
        },
        carouselContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: `-${theme.spacing(10)}`
        },
        carouselLeft: {},
        carouselRight: {},
        carousel: {
            width: '100%',
            height: 'auto',
            '& div': {
                display: 'flex',
                justifyContent: 'center',
                textAlign: "center",
                alignItems: "center",
            }
        },
        carouselImage: {
            maxWidth: '100%',
            height: 'auto',
            margin: 'auto',
        },indicatorContainer: {
            position: 'absolute',
            bottom: theme.spacing(2),
            textAlign: 'center',
            width: '100%',
        },
        indicator: {
            zIndex: 1000,
            margin: theme.spacing(0.5),
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            }
        },
        activeIndicator: {
            color: theme.palette.primary.main,
        },
        boxTitle: {
            backgroundImage: `url(${bgWoodLight})`,
            backgroundRepeat: 'repeat',
            backgroundPosition: 'cover',
            objectFit: 'cover',
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(10),
            borderRadius: '7vh',
            marginTop: theme.spacing(10),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(5),
                paddingBottom: theme.spacing(8),
            }
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vh,5vw)',
            fontWeight: "bold",
            marginBottom: theme.spacing(3),
            lineHeight: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(2vh,2vw)',
                fontWeight: "normal",
                letterSpacing: '1px',
            }
        },
        paperForm: {
            borderRadius: '3vw',
            margin: theme.spacing(5),
            marginTop: `-${theme.spacing(10)}`,
            padding: `${theme.spacing(10)} ${theme.spacing(2)}`,
            backgroundImage: `url(${patternAlb})`,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(4),
                marginTop: `-${theme.spacing(10)}`,
                padding: theme.spacing(1),
            },
            '& img': {
                margin: 'auto',
                justifyContent: "center",
                width: '100%',
            }
        },
        transparent: {
            backgroundColor: 'transparent',
        },
        arrowLeft: {
            position: "absolute",
            top: theme.spacing(10),
            left: theme.spacing(10),
            [theme.breakpoints.down("sm")]: {
                top: theme.spacing(1),
                left: theme.spacing(0.5),
                width: '15vw',
            }
        },
        arrowRight: {
            position: "absolute",
            top: theme.spacing(10),
            right: theme.spacing(10),
            '-webkit-transform': 'scaleX(-1)',
            transform: 'scaleX(-1)',
            [theme.breakpoints.down("sm")]: {
                top: theme.spacing(1),
                right: theme.spacing(0.5),
                width: '15vw',
            }
        },
    }
});

export default useTeaserStyles;