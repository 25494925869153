import React, {useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import useFormNcpStyles from "../../../styles/ncp/form_ncp";
import {
    Button, CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper, Switch,
    TextField,
    Typography
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import {insertNcp, NcpPayloadProps} from '../../../features/ncp/ncpSlice';
import promo_despre_alcool from "../../../assets/images/promo-despre-alcool.png";
interface FormNcpProps {
    partner: string;
}

const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
};
const maxFileSize = 10 * 1024 * 1024; // 10MB;
const FormNcp: React.FC<FormNcpProps> = ({partner}) => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector( state => state.ncp);
    const formNcpStyles = useFormNcpStyles();
    const [birthdate, setBirthdate] = useState<Dayjs|null>(null);
    const [lastname, setLastname] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [receiptNr, setReceiptNr] = useState<string>('');
    const [receiptDate, setReceiptDate] = useState<Dayjs|null>(null);
    const [receiptFile, setReceiptFile] = useState<File | null>(null);
    const [accordingRules, setAccordingRules] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const fileInputRef = React.createRef<HTMLInputElement>();
    const [isDatePickerBirthdateOpen, setIsDatePickerBirthdateOpen] = useState(false);
    const [isDatePickerReceiptDateOpen, setIsDatePickerReceiptDateOpen] = useState(false);

    const validateFields = () => {
        const newErrors: { [key: string]: string } = {};

        if (!birthdate) newErrors.birthdate = 'Data nașterii este obligatorie';
        if (!lastname) newErrors.lastname = 'Numele este obligatoriu';
        if (!firstname) newErrors.firstname = 'Prenumele este obligatoriu';
        if (!phone) newErrors.phone = 'Numărul de telefon este obligatoriu';
        if (!receiptNr) newErrors.receiptNr = 'Numărul bonului fiscal este obligatoriu';
        if (!receiptDate) newErrors.receiptDate = 'Data bonului fiscal este obligatorie';
        if (!receiptFile) newErrors.receiptFile = 'Bonul fiscal trebuie încărcat';
        if (!accordingRules) newErrors.accordingRules = 'Trebuie să acceptați regulamentul';

        setErrors(newErrors);
        validateBirthdate(birthdate);
        validatePhone(phone);
        validateFileSize(receiptFile);

        return Object.keys(newErrors).length === 0;
    };
    const validateFileSize = (file: File|null) => {
        if (file) {
            if (file.size > maxFileSize) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    receiptFile: 'Fișierul nu trebuie să fie mai mare de 10MB.',
                }));
            }
        }
    }
    const validatePhone = (phoneNr:string) => {
        if(phoneNr){
            if (phoneNr.length !== 10) {
                setErrors(prevErrors => ({ ...prevErrors, phone: 'Numărul de telefon trebuie sa fie de 10 cifre' }));
                return false;
            } else {
                setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
                return true;
            }
        }
    };
    const validateBirthdate = (date: Dayjs | null) => {
        if (date) {
            const today = dayjs();
            const age = today.diff(date, 'year');
            if (age < 18) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    birthdate: 'Trebuie să aveți cel puțin 18 ani.',
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    birthdate: '',
                }));
            }
        }
    };
    const handleBirthdateChange = (newValue: Dayjs | null) => {
        setBirthdate(newValue);
        validateBirthdate(newValue);
        setIsDatePickerBirthdateOpen(false);
    };
    const handleReceiptDateChange = (newValue: Dayjs | null) => {
        setReceiptDate(newValue);
        setIsDatePickerReceiptDateOpen(false);
    }

    const resetState = () => {
        setBirthdate(null);
        setLastname('');
        setFirstname('');
        setPhone('');
        setReceiptNr('');
        setReceiptDate(null);
        setReceiptFile(null);
        setAccordingRules(false);
        setErrors({});
    }
    const handleSubmit = async () => {
        if (validateFields()) {
            try {
                if(!receiptFile || !birthdate || !receiptDate) {
                    return ;
                }
                const receiptFileBase64 = await convertFileToBase64(receiptFile);

                const data: NcpPayloadProps = {
                    birthdate: birthdate.format('YYYY-MM-DD'),
                    lastname,
                    firstname,
                    phone,
                    receiptNr,
                    receiptDate: receiptDate.format('YYYY-MM-DD'),
                    receiptFile: receiptFileBase64,
                    partner,
                    accordingMarketing: false,
                };

                dispatch(insertNcp(data));
                resetState();

            } catch (error) {
                console.error("Error converting file to Base64:", error);
            }
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            if (file.size > maxFileSize) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    receiptFile: 'Fișierul nu trebuie să fie mai mare de 10MB.',
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    receiptFile: '',
                }));
                setReceiptFile(file);
            }
        }
    };


    const handleFileInputClick = (e: any) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleBirthdateFocus = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDatePickerBirthdateOpen(true);
    };

    const handleReceiptDateFocus = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDatePickerReceiptDateOpen(true);
    };

    const handleReceiptNrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setReceiptNr(value);
        }
    };
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setPhone(value);
        }
    };

    if(loading) {
        return <CircularProgress />;
    }

    return (
        <Grid container>
            <Grid item xs={12} className={formNcpStyles.boxTitle}>
                <Typography className={formNcpStyles.title}>FORMULAR DE ÎNSCRIERE</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={formNcpStyles.paperForm}>
                    <DatePicker
                        value={birthdate}
                        onAccept={handleBirthdateChange}
                        label={'Data nașterii*'}
                        open={isDatePickerBirthdateOpen}
                        onOpen={() => setIsDatePickerBirthdateOpen(true)}
                        onClose={() => setIsDatePickerBirthdateOpen(false)}
                        format={'DD/MM/YYYY'}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !!errors.birthdate,
                                helperText: errors.birthdate,
                                className: formNcpStyles.formInput,
                                variant: 'outlined',
                                color: 'primary',
                                onClick: handleBirthdateFocus,
                            },
                        }}
                    />
                    <TextField
                        value={lastname}
                        onChange={e => setLastname(e.target.value)}
                        fullWidth
                        label={'Nume*'}
                        error={!!errors.lastname}
                        helperText={errors.lastname}
                        className={formNcpStyles.formInput}
                        variant="outlined"
                        color={'primary'}
                    />
                    <TextField
                        value={firstname}
                        onChange={e => setFirstname(e.target.value)}
                        fullWidth
                        label={'Prenume*'}
                        error={!!errors.firstname}
                        helperText={errors.firstname}
                        className={formNcpStyles.formInput}
                        variant="outlined"
                        color={'primary'}
                    />
                    <TextField
                        value={phone}
                        onChange={handlePhoneChange}
                        fullWidth
                        label={'Număr de telefon*'}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        className={formNcpStyles.formInput}
                        variant="outlined"
                        color={'primary'}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <TextField
                        value={receiptNr}
                        onChange={handleReceiptNrChange}
                        fullWidth
                        label={'Număr bon fiscal*'}
                        error={!!errors.receiptNr}
                        helperText={errors.receiptNr}
                        className={formNcpStyles.formInput}
                        variant="outlined"
                        color={'primary'}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <DatePicker
                        value={receiptDate}
                        onAccept={handleReceiptDateChange}
                        label={'Data bon fiscal*'}
                        open={isDatePickerReceiptDateOpen}
                        onOpen={() => setIsDatePickerReceiptDateOpen(true)}
                        onClose={() => setIsDatePickerReceiptDateOpen(false)}
                        maxDate={dayjs()}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !!errors.receiptDate,
                                helperText: errors.receiptDate,
                                className: formNcpStyles.formInput,
                                variant: 'outlined',
                                color: 'primary',
                                onClick: handleReceiptDateFocus,
                            },
                        }}
                    />
                    <TextField
                        fullWidth
                        label={'Bon fiscal*'}
                        error={!!errors.receiptFile}
                        helperText={errors.receiptFile}
                        className={formNcpStyles.formInput}
                        variant="outlined"
                        color={'primary'}
                        onClick={handleFileInputClick}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleFileInputClick}>
                                        <InsertPhotoIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            readOnly: true
                        }}
                        value={receiptFile ? receiptFile.name : ''}
                    />
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        style={{display: 'none'}}
                        ref={fileInputRef}
                    />
                    <FormControlLabel
                        className={formNcpStyles.formControlCheckbox}
                        labelPlacement={'start'}
                        control={<Switch
                                checked={accordingRules}
                                onChange={e => setAccordingRules(e.target.checked)}
                                inputProps={{'aria-label': 'controlled'}}
                            />}
                        label={<Typography className={formNcpStyles.formControlCheckboxLabel}>Am citit integral, am inteles si sunt de acord cu prevederile
                            <a target={'_blank'} rel="noreferrer"  href={`/pdf/2024/27-Regulament Kozel - Mixeaza Tapan si Castiga cu Profi.pdf`}> Regulamentului Campaniei</a></Typography>}
                    />
                    {errors.accordingRules && <Typography color="error">{errors.accordingRules}</Typography>}

                    <Grid item xs={12}>
                        {loading ?
                            <Button className={formNcpStyles.submitButton}><CircularProgress/></Button> :
                            <Button onClick={handleSubmit} className={formNcpStyles.submitButton}>ÎNSCRIE-TE ÎN CAMPANIE</Button>}

                    </Grid>
                    <Grid item xs={12} marginTop={3}>
                        <img src={promo_despre_alcool} alt={'promo despre alcool'} className={formNcpStyles.mesajResponsabilitate}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={formNcpStyles.textKeepReceipt}>
                            Păstrează în original toate bonurile fiscale înscrise în campanie pentru validarea premiului în cazul în care vei fi declarat potențial câștigător.
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default FormNcp;