import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import usePromoKozelEventsStyles from "../../styles/kozelMeetBoardGameAccess/promo_kozel_events";
import icon_arrow from "../../assets/images/icon_arrow.png";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";

const PromoKozelEvents: React.FC = () => {
    const styles = usePromoKozelEventsStyles();
    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.title}>Fii la curent cu evenimentele Kozel Meeet!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.subtitle}>Joia berea ne adună, să ne jucăm împreună!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <NavLink to={paths.kozelMeetSpecialLocations} className={styles.btn}>Vezi toate evenimentele</NavLink>
            </Grid>
            <img src={icon_arrow} alt={'arrow'} className={styles.arrow}/>
        </Grid>
    );
}

export default PromoKozelEvents;
