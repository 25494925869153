import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useMyGroup = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
        },
        text: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2.5vh,2.5vw)',
            color: theme.colors.latte_cream,
            fontWeight: 900,
            padding: `${theme.spacing(5)} ${theme.spacing(15)}`,
            textAlign: "center",
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
            }
        }
    };
});

export default useMyGroup;