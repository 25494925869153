import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {getGameHistory} from "../../features/games/gameSlice";
import {gameNameApi} from "./BottleGame";
import TeaserComponent from "../TeaserComponent";
import {getMyGroup} from "../../features/profile/myGroupSlice";
import useGameHistoryStyles from "../../styles/kozelMeet/gameHistory";
import PopupComponent from "../kozel/PopupComponent";

const HistoryList: React.FC = () => {
    const {members} = useAppSelector((state) => state.myGroup);
    const styles = useGameHistoryStyles();
    const [openPopupHistory, setOpenPopupHistory] = useState(false);
    const {games} = useAppSelector((state) => state.game);
    const {history} = games[gameNameApi];
    const handleOpenPopupHistory = () => {
        setOpenPopupHistory(true);
    }
    const handleClosePopupHistory = () => {
        setOpenPopupHistory(false);
    }

    const getMemberNickName = (id: number): string => {
        if (id === 0) {
            return 'Țapul Kozel';
        }
        const member = members.find(item => item.member_id === id);
        if (!member) {
            return `G.O.A.T#${id}`;
        }
        if (!member.nick_name) {
            return `G.O.A.T#${id}`;
        }

        return member.nick_name;
    }

    return (
        <Grid container className={styles.containerHistoryList}>
            {
                history && history.slice(0, 3).map((item: any, index: number) => (
                    <Grid key={index} item xs={12} className={styles.containerItemHistoryList}>
                        <Grid container>
                            <Grid item lg={2} xs={12}>
                                <Typography className={styles.senderText}>{getMemberNickName(item.sender)}</Typography></Grid>
                            <Grid item lg={8} xs={12}>
                                <Typography className={styles.challengeText}>{item.challenge}</Typography></Grid>
                            <Grid item lg={2} xs={12}>
                                <Typography
                                    className={styles.receiverText}>{getMemberNickName(item.receiver)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            }
            <Grid item xs={12} className={styles.containerItemHistoryList}>
                <Button onClick={handleOpenPopupHistory} className={styles.togglePopup}>Vezi tot</Button>
            </Grid>
            <PopupComponent isOpen={openPopupHistory} onClose={handleClosePopupHistory}>
                <Grid container className={styles.containerHistoryList}>
                    {history && history.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} className={styles.containerItemHistoryList}>
                            <Grid container>
                                <Grid item lg={2} xs={12}>
                                    <Typography
                                        className={styles.senderText}>{getMemberNickName(item.sender)}</Typography></Grid>
                                <Grid item lg={8} xs={12}>
                                    <Typography className={styles.challengeText}>{item.challenge}</Typography></Grid>
                                <Grid item lg={2} xs={12}>
                                    <Typography
                                        className={styles.receiverText}>{getMemberNickName(item.receiver)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </PopupComponent>
        </Grid>
    );
}
const GameSpinBottleHistory: React.FC = () => {
    const dispatch = useAppDispatch();
    const {games} = useAppSelector((state) => state.game);
    const {history} = games[gameNameApi];
    const styles = useGameHistoryStyles();

    useEffect(() => {
        dispatch(getMyGroup());
        dispatch(getGameHistory({gameName: gameNameApi}))
    }, [dispatch]);

    if (history === null || (history && history.length < 1)) {
        return null;
    }

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} className={styles.containerItem}>
                <TeaserComponent title={<Typography className={styles.teaserTitle}>Istoricul provocărilor</Typography>}
                                 content={<HistoryList/>}/>
            </Grid>
        </Grid>
    )
}

export default GameSpinBottleHistory;