import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useTeaserKozelLocationsStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                backgroundColor: theme.colors.dark_chocolate,
                paddingTop: theme.spacing(5),
            },
            button: {
                fontFamily: theme.fonts.poplar,
                backgroundColor: theme.colors.latte_cream,
                textTransform: 'uppercase',
                fontWeight: 900,
                color: theme.palette.primary.main,
                fontSize: 'max(1.5vh,1.5vw)',
                padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
                marginTop: theme.spacing(5),
                alignSelf: 'start',
                width: '70%',
                textAlign: 'center',
                '&:hover': {
                    color: theme.palette.primary.light,
                },
                [theme.breakpoints.down('lg')]: {
                    marginTop: theme.spacing(3),
                }
            },
            buttonLarger: {
                fontFamily: theme.fonts.poplar,
                backgroundColor: theme.colors.latte_cream,
                textTransform: 'uppercase',
                fontWeight: 900,
                color: theme.palette.primary.main,
                fontSize: 'max(1.6vh,1.6vw)',
                padding: `${theme.spacing(2)} ${theme.spacing(15)}`,
                marginTop: theme.spacing(5),
                '&:hover': {
                    color: theme.palette.primary.light,
                },
                [theme.breakpoints.down('lg')]: {
                    marginTop: theme.spacing(3),
                    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
                }
            },
            boxBorderWithCenterTitle: {
                borderTopWidth: '3px',
                borderTopColor: theme.colors.ivory_beige,
                borderTopStyle: "solid",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                width: '100%',
            },
            centerTopTitle: {
                color: theme.colors.ivory_beige,
                fontFamily: theme.fonts.apex,
                fontWeight: theme.typography.fontWeightBold,
                letterSpacing: '25px',
                fontSize: '3vw',
                marginTop: '-1.5vw',
                textAlign: "center",
                backgroundColor: theme.colors.dark_chocolate,
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5),
                lineHeight: 1,
                [theme.breakpoints.down('lg')]:{
                    letterSpacing: '10px',
                }
            },
            boxTeaser: {
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
                }
            },
            boxLeft:{
                display: 'flex',
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                padding: `${theme.spacing(5)} ${theme.spacing(15)}`,
                [theme.breakpoints.down('lg')]:{
                    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
                }
            },
            boxRight: {
                padding: theme.spacing(5),
                [theme.breakpoints.down('lg')]:{
                    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
                }
            },
            textTeaser: {
                color: theme.colors.ivory_beige,
                fontFamily: theme.fonts.poplar,
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: 'max(2vw,2vh)',
                letterSpacing: '1px',
                lineHeight: 1.3,
            },
            text: {
                color: theme.colors.ivory_beige,
                fontFamily: theme.fonts.apex,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: 'max(1.8vw,1.8vh)',
                textAlign: 'center',
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
                }
            },
            logoKozelMeet: {
                width: '75%',
                [theme.breakpoints.down('lg')]:{
                    width: '75%',
                },
            },
            banner: {
                width: '100%',
                height: 'auto',
            },
        };
    }
)

export default useTeaserKozelLocationsStyles;
