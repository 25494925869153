import React, {useState} from "react";
import {Button, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import useGroupInvite from "../../styles/my_group/groupInvite";
import PopupComponent from "../kozel/PopupComponent";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import goatGif from '../../assets/images/goat-this.gif';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import {showNotification} from "../../features/notification/notificationSlice";
import clsx from "clsx";
import {joinGroupKey} from "../RedirectJoinGroupCOmponent";
import {createMyGroup} from "../../features/profile/myGroupSlice";

interface GroupInviteProps {
    isOpenPopup: boolean,
    handleClosePopup: () => void,
}

const CreateGroup: React.FC = () => {
    const styles = useGroupInvite();
    const dispatch = useAppDispatch();
    const [name, setName] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleCreateGroup = () => {
        if (validateName() === '') {
            dispatch(createMyGroup({name}))
        }
    }

    const validateName = () => {
        if (name.length < 3) {
            const errMsg = 'Campul trepuie sa aiba cel putin 3 caractere';
            setErrors({name: errMsg})

            return errMsg;
        }

        setErrors({name: ''})

        return '';

    }

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} className={styles.containerItem}>
                <img src={goatGif} alt={'goatGif'} className={styles.goatGif}/>
            </Grid>
            <Grid item xs={12} className={styles.containerItem}>
                <Typography className={styles.title}>Goatiful!</Typography>
                <Typography className={styles.text}>Crează grupul si invită-ți pâna la 4 prieteni</Typography>
            </Grid>
            <Grid item xs={12} className={styles.containerItem}>
                <TextField
                    className={styles.inputTextField}
                    fullWidth
                    label={'Numele grupului*'}
                    error={!!errors.name}
                    helperText={errors.name}
                    variant="outlined"
                    type={'text'}
                    name={'name'}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} className={styles.containerItem}>
                <Button onClick={handleCreateGroup} className={styles.button}>Crează grupul</Button>
            </Grid>
        </Grid>
    );
}

const InviteFriends: React.FC = () => {
    const dispatch = useAppDispatch();
    const styles = useGroupInvite();
    const {referral_code} = useAppSelector((state) => state.myGroup);
    const fullLink = `${window.location.origin}/referral-code?${joinGroupKey}=${referral_code}`;
    const hasShare = () => {
        return typeof navigator.share !== 'undefined';
    }
    const handleCopyCode = async () => {
        try {
            await navigator.clipboard.writeText(referral_code||"").then(() => {
                dispatch(showNotification({
                    message: 'Cod Copiat.',
                    variant: 'success'
                }))
            });
        } catch (err) {
            console.error("Eroare la copiere:", err);
            dispatch(showNotification({
                message: 'A aparut o eroare la copierea codului.',
                variant: 'error'
            }))
        }
    };
    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(fullLink).then(() => {
                dispatch(showNotification({
                    message: 'Link Copiat.',
                    variant: 'success'
                }))
            });
        } catch (err) {
            console.error("Eroare la copiere:", err);
            dispatch(showNotification({
                message: 'A aparut o eroare la copierea link-ului.',
                variant: 'error'
            }))
        }
    };
    const handleShareCode = async () => {
        if (hasShare()) {
            try {
                await navigator.share({
                    title: 'Alăturăte grupului meu',
                    text: referral_code||'',
                });
            } catch (err) {
                console.log("Eroare la partajare:", err);
            }
        }
    };
    const handleShareLink = async () => {
        if (hasShare()) {
            try {
                await navigator.share({
                    title: 'Alăturăte grupului meu',
                    text: fullLink,
                });
            } catch (err) {
                console.log("Eroare la partajare:", err);
            }
        }
    };

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} className={styles.containerItem}>
                <Typography className={styles.text}>Invită ţi prietenii în comunitatea Kozel și creează-ţi gașca de
                    festival!</Typography>
            </Grid>
            <Grid item xs={12} className={styles.containerItem}>
                <Typography className={styles.text2}>Fiecare prieten invitat, care întră in grup aduce 10 puncte în
                    contul grupului tău!</Typography>
            </Grid>
            <Grid item xs={12} className={clsx(styles.containerItem, styles.MT5)}>
                <TextField
                    fullWidth
                    margin="normal"
                    value={fullLink}
                    className={styles.inputInvitationField}
                    aria-readonly={true}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ContentCopyIcon className={styles.buttonCopy} onClick={handleCopyLink}/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {hasShare() && <ShareIcon className={styles.buttonShare} onClick={handleShareLink}/>}
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} className={styles.containerItem}>
                <TextField
                    fullWidth
                    margin="normal"
                    value={referral_code}
                    className={styles.inputInvitationField}
                    aria-readonly={true}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ContentCopyIcon className={styles.buttonCopy} onClick={handleCopyCode}/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {hasShare() && <ShareIcon className={styles.buttonShare} onClick={handleShareCode}/>}
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    )
}
const GroupInvite: React.FC<GroupInviteProps> = ({isOpenPopup, handleClosePopup}) => {
    const {id} = useAppSelector((state) => state.myGroup);

    return (
        <PopupComponent isOpen={isOpenPopup} onClose={handleClosePopup}>
            {id ? <InviteFriends/> : <CreateGroup/>}
        </PopupComponent>
    )
};

export default GroupInvite;