import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useSpecialLocationStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                justifyContent: "center",
                alignItems: 'center',
                alignContent: 'center',
                backgroundColor: theme.colors.ivory_beige,
            },
            title: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
                textAlign: 'center',
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(3vh,3vw)',
                color: theme.colors.dark_chocolate,

                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
                }
            },
            crownIcon: {
                width: 'auto',
                height: 'max(3vh,3vw)',
            },
        };
    }
)

export default useSpecialLocationStyles;
