import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useFirstScreenStyles = createUseStyles((theme: Theme) => {
        return {
            container:{
                justifyContent: "center",
                alignItems: 'center',
                alignContent: 'center',
            },
            bgDarkChoco:{
                backgroundColor: theme.colors.dark_chocolate,
            },
            logoKozelMeet: {
                width: '75%',
            },
            subtitle: {
                fontFamily: theme.fonts.apex,
                color: theme.colors.ivory_beige,
                fontSize: 'max(2vh,2vw)',
                fontWeight: theme.typography.fontWeightBold,
                textAlign: 'center',
                lineHeight: 1.1,
            },
            subtitle2: {
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(1.5vh,1.5vw)',
                color: theme.colors.ivory_beige,
                paddingLeft: theme.spacing(5),
                paddingRight:  theme.spacing(5),
                textAlign: 'center',
                lineHeight: 1.1,
            },
            colorDesertSandGold: {
                color: theme.colors.desert_sand_gold,
            },
            button: {
                fontFamily: theme.fonts.poplar,
                backgroundColor: theme.colors.latte_cream,
                textTransform: 'uppercase',
                fontWeight: 900,
                color: theme.palette.primary.main,
                fontSize: 'max(1.8vh,1.8vw)',
                padding: `${theme.spacing(2)} ${theme.spacing(15)}`,
                marginTop: theme.spacing(5),
                '&:hover': {
                    color: theme.palette.primary.light,
                },
                [theme.breakpoints.down('lg')]: {
                    marginTop: theme.spacing(3),
                    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
                }
            },
            textBenefits: {
                display: 'flex',
                gap: 5,
                fontFamily: theme.fonts.apex,
                color: theme.colors.latte_cream,
                padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
                fontSize: `max(1.5vh,1.5vw)`,
                alignItems: 'center',
                fontStyle: 'italic',
                paddingBottom: theme.spacing(5),
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
                }
            },
            contentTeaserListItemIcon: {
                width: 'max(2.2vh,2.2vw)',
                height: 'max(2.2vh,2.2vw)',
                marginRight: theme.spacing(1),
            },
        };
    }
)

export default useFirstScreenStyles;
