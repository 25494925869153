import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import LoadingComponent from "./kozel/LoadingComponent";
import {useLocation} from "react-router-dom";
import {paths} from "../AppRouters";
import Home from "../pages/Home";
import {useAppDispatch, useAppSelector} from "../stores/store";
import {openLoginModal} from "../features/auth/authSlice";
import {getGameStatus} from "../features/games/gameSlice";
import Challenge1 from "./challengeUntold/Challenge1";
import Challenge2 from "./challengeUntold/Challenge2";
import Challenge3 from "./challengeUntold/Challenge3";
import ChallengeFinal from "./challengeUntold/ChallengeFinal";
import ChallengeOps from "./challengeUntold/ChallengeOps";

export const gameNameApi = 'challenge-untold';
const ChallengeUntold: React.FC = () => {
    const [currentScanStep, setCurrentScanStep] = useState(0);
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const {games} = useAppSelector(state => state.game);
    const { state } = games[gameNameApi];
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // preluare game status din backend
        dispatch(getGameStatus({gameName: gameNameApi}))
        if (!isAuthenticated) {
            dispatch(openLoginModal());
            setCurrentScanStep(-1);
            return;
        }
        // validare eligibil pas scanat ( in functie de statusul din backend)
        if (location.pathname === paths.untoldChallenge.challenge1) {
            setCurrentScanStep(1);
            return;
        }
        if (location.pathname === paths.untoldChallenge.challenge2) {
            setCurrentScanStep(2);
            return;
        }
        if (location.pathname === paths.untoldChallenge.challenge3) {
            setCurrentScanStep(3);
            return;
        }
        if (location.pathname === paths.untoldChallenge.challengeFinal) {
            setCurrentScanStep(4);
            return;
        }

        setCurrentScanStep(-1);
        return;
    }, [dispatch, isAuthenticated, location.pathname]);

    if (currentScanStep === 0 || state === null) {
        return (
            <LoadingComponent/>
        )
    }

    if(state && state.lastStep === 4){
        return (
            <ChallengeFinal />
        )
    }

    if (currentScanStep === 1) {
        return (
            (state && state.lastStep === null) ? <Challenge1 /> : <ChallengeOps />
        )
    }

    if (currentScanStep === 2) {
        return (
            (state && state.lastStep === 1) ? <Challenge2 /> : <ChallengeOps />
        )
    }

    if (currentScanStep === 3) {
        return (
            (state && state.lastStep === 2) ? <Challenge3 /> : <ChallengeOps />
        )
    }

    if (currentScanStep === 4) {
        return (
            (state && state.lastStep === 3) ? <ChallengeFinal /> : <ChallengeOps />
        )
    }

    return (
        <Home/>
    );
}

export default ChallengeUntold;