import React from 'react';
import useBereaMeaStyles from "../styles/berea_mea";
import {Button, Grid, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import clsx from 'clsx';
import {useInView} from 'react-intersection-observer';
import berea_mea_1 from '../assets/images/berea_mea_1.png';
import berea_mea_2 from '../assets/images/berea_mea_2.png';
import berea_mea_3 from '../assets/images/berea_mea_3.png';
import berea_mea_4 from '../assets/images/berea_mea_4.png';
import {paths} from "../AppRouters";

const BereaMea: React.FC = () => {
    const bereaMeaStyles = useBereaMeaStyles();
    const {ref, inView} = useInView({
        threshold: 0.5,
    });
    const {ref: refImg2, inView: inViewImg2} = useInView({
        threshold: 0.1,
    });
    const {ref: refImg3, inView: inViewImg3} = useInView({
        threshold: 0.1,
    });
    const {ref: refImg4, inView: inViewImg4} = useInView({
        threshold: 0.5,
    });

    return (
        <Grid container>
            <Grid item xs={12} className={clsx(bereaMeaStyles.bg1, bereaMeaStyles.section)}>
                <Grid container className={bereaMeaStyles.containerSubSection}>
                    <Grid item md={6} xs={12} className={clsx(bereaMeaStyles.subSectionLeft,'spacing-5')}>
                        <Typography className={bereaMeaStyles.title}>Fă cunoștința cu berea țapănă din Cehia!</Typography>
                        <Typography className={bereaMeaStyles.subTitle}>Kozel este cea mai iubită bere ceha din lume. Te poți
                            convinge savurând cele două beri emblematice - Kozel Premium si Kozel Dark</Typography>
                        <NavLink className={bereaMeaStyles.buttonLink} to={paths.ourBeers}>
                            <Button className={bereaMeaStyles.button}>Descopera berile noastre</Button>
                        </NavLink>
                    </Grid>
                    <Grid item md={6} xs={12} className={clsx(bereaMeaStyles.subSectionRight,bereaMeaStyles.wrapperBereaMea1)}>
                        <img ref={ref} src={berea_mea_1} alt={'Berea mea 1'}
                             className={`${bereaMeaStyles.imgBereaMea1} ${inView ? 'visible' : ''}`}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={clsx(bereaMeaStyles.bg2, bereaMeaStyles.section)}>
                <Grid container className={bereaMeaStyles.containerSubSection}>
                    <Grid item md={6} xs={12} className={clsx(bereaMeaStyles.subSectionLeft,bereaMeaStyles.wrapperImage)}>
                        <img ref={refImg3} src={berea_mea_3} alt={'Berea mea 2'}
                             className={`${bereaMeaStyles.imgBereaMea3} ${inViewImg3 ? 'visible' : ''}`}/>
                        <img ref={refImg2} src={berea_mea_2} alt={'Berea mea 3'}
                             className={`${bereaMeaStyles.imgBereaMea2} ${inViewImg2 ? 'visible' : ''}`}/>
                    </Grid>
                    <Grid item md={6} xs={12} className={clsx(bereaMeaStyles.subSectionRight,'spacing-5')}>
                        <Typography className={clsx(bereaMeaStyles.title, 'titleContrast')}>Savurează berea Kozel într-un mod
                            surprinzător</Typography>
                        <Typography className={clsx(bereaMeaStyles.subTitle,'subTitleContrast')}>Există o mulțime de moduri inventive prin care
                            să te
                            bucuri de berile noastre gustoase. Vrem să te inspirăm atât pe tine, cât și pe toți prietenii noștri
                            din jurul lumii!</Typography>
                        <NavLink className={bereaMeaStyles.buttonLink} to={paths.servingRitual}>
                            <Button className={clsx(bereaMeaStyles.button, bereaMeaStyles.buttonContrast)}>
                                Descoperă Ritualurile de servire
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={clsx(bereaMeaStyles.bg1, bereaMeaStyles.section)}>
                <Grid container className={bereaMeaStyles.containerSubSection}>
                    <Grid item md={6} xs={12} className={clsx(bereaMeaStyles.subSectionLeft,'spacing-5')}>
                        <Typography className={bereaMeaStyles.title}>Bere țapănă incă din 1874</Typography>
                        <Typography className={bereaMeaStyles.subTitle}>Din 1874 si până acum, istoria noastră este plină de
                            lucruri interesante și de povestiri inedite - pornind de la cum am ajuns să avem o asemenea
                            mascotă.</Typography>
                        <NavLink className={bereaMeaStyles.buttonLink} to={paths.ourTradition}>
                            <Button className={bereaMeaStyles.button}>Descoperă-ne istoria</Button>
                        </NavLink>
                    </Grid>
                    <Grid item md={6} xs={12} className={bereaMeaStyles.subSectionRight}>
                        <img ref={refImg4} src={berea_mea_4} alt={'Berea mea 4'}
                             className={`${bereaMeaStyles.imgBereaMea4} ${inViewImg4 ? 'visible' : ''}`}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BereaMea;