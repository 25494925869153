import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import bg1 from '../assets/images/bg1.png';
import bg2 from '../assets/images/bg2.png';

const useBereaMeaStyles = createUseStyles((theme: Theme) => {
    return {
        bg1: {
            backgroundImage: `url(${bg1})`,
            backgroundRepeat: 'no-repeat',
            marginTop: 'auto',
            backgroundSize: 'cover',
        },
        bg2: {
            backgroundImage: `url(${bg2})`,
            backgroundRepeat: 'no-repeat',
            marginTop: 'auto',
            backgroundSize: 'cover',
        },
        section: {
            position: 'relative',
            height: '90vh',
        },
        containerSubSection: {
            height: '100%',
        },
        subSectionLeft: {
            minHeight: '25vh',
            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
            '&.spacing-5': {
                padding: theme.spacing(5),
            }
        },
        subSectionRight: {
            minHeight: '25vh',
            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
            '&.spacing-5': {
                padding: theme.spacing(5),
            }
        },
        wrapperImage: {
            [theme.breakpoints.down('sm')]: {
                order: 3,
                position: 'relative',
            }
        },
        wrapperBereaMea1: {
            display: 'flex',
            justifyContent: 'end',
            alignContent: 'end',
            alignItems: 'end',
        },
        imgBereaMea1: {
            height: 'auto',
            width: '35vw',
            opacity: 0,
            transition: 'opacity 1.5s ease-in-out',
            '&.visible': {
                opacity: 1,
            },
            [theme.breakpoints.down('sm')]: {
                width: '75vw',
            }
        },
        imgBereaMea2: {
            position: 'absolute',
            right: 0,
            bottom: '-1vw',
            height: 'auto',
            width: '30vw',
            transform: 'rotate(30deg)',
            opacity: 0,
            transition: 'opacity 1.5s ease-in-out',
            '&.visible': {
                opacity: 1,
            },
            [theme.breakpoints.down('sm')]: {
                transform: 'rotate(-25deg)',
                right: '-3vw',
                top: '0',
                width: '50vw',
                '&.visible': {
                    opacity: 1,
                    width: '60vw',
                    transition: 'all 1.5s ease-in-out',
                },
            }
        },
        imgBereaMea3: {
            position: 'absolute',
            top: 0,
            left: '-15vw',
            height: 'auto',
            width: '30vw',
            opacity: 0,
            transition: 'opacity 1s ease-in-out',
            transform: 'rotate(75deg)',
            '&.visible': {
                opacity: 1,
                transform: 'translateX(10vw) translateY(6vw) rotate(50deg)',
                transition: 'transform 1.5s ease-in-out',
            },
            [theme.breakpoints.down('sm')]: {
                transform: 'none',
                top: 'unset',
                left: '-10vw',
                bottom: '-10vw',
                width: '50vw',
                '&.visible': {
                    opacity: 1,
                    width: '60vw',
                    transform: 'translateX(3vw) translateY(-5vw) rotate(7deg)',
                    transition: 'all 1.5s ease-in-out',
                },
            }
        },
        imgBereaMea4: {
            position: 'absolute',
            right: '5vw',
            bottom: 0,
            height: 'auto',
            width: '35vw',
            opacity: 0,
            transition: 'opacity 1.5s ease-in-out',
            '&.visible': {
                opacity: 1,
            },
            [theme.breakpoints.down('sm')]: {
                width: '80vw',
                margin: 'auto',
            }
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(4vh,4vw)',
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(7),
            lineHeight: 1,
            '&.titleContrast': {
                color: theme.palette.primary.main,
            },
            [theme.breakpoints.down('sm')]: {
                lineHeight: 1.2,
            }
        },
        subTitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vh,2vw)',
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(5),
            '&.subTitleContrast': {
                color: theme.palette.primary.main,
            }
        },
        buttonLink: {},
        button: {
            fontFamily: theme.fonts.poplar,
            padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
            fontSize: 'max(1.8vh,1.8vw)',
            backgroundColor: `${theme.palette.secondary.main}`,
            color: `${theme.palette.primary.main}`,
            marginTop: theme.spacing(6),
            letterSpacing: '1px',
            textTransform: 'none',
            '&:hover,&:active': {
                backgroundColor: `${theme.palette.primary.main}`,
                color: `${theme.palette.secondary.main}`,
                border: `1px solid ${theme.palette.secondary.main}`,
            }
        },
        buttonContrast: {
            backgroundColor: `${theme.palette.primary.main}`,
            color: `${theme.palette.secondary.main}`,
            '&:hover,&:active': {
                backgroundColor: `${theme.palette.secondary.main}`,
                color: `${theme.palette.primary.main}`,
                border: `1px solid ${theme.palette.primary.main}`,
            }
        },
    }
});

export default useBereaMeaStyles;