import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {logout} from "../auth/authSlice";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';
import {showNotification} from "../notification/notificationSlice";

export interface ProfileState {
    id: number | null,
    crm_id: number | null,
    nick_name: string | null | undefined,
    phone: string,
    loading: boolean,
    coins: bigint | null,
}

const initialState: ProfileState = {
    id: null,
    crm_id: null,
    nick_name: null,
    phone: '',
    loading: false,
    coins: null,
}

export const getProfile = createAsyncThunk(
    'profile/profile',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get(apiEndpoints.contactGetProfile);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (nick_name: string, thunkAPI) => {
        try {
            await apiInstance.post(apiEndpoints.contactUpdateProfile, {nick_name});
            thunkAPI.dispatch(showNotification({message: 'Profile updated successfully', variant: 'success'}));
            return {data: {nick_name}, message: ''};
        } catch (error) {
            thunkAPI.dispatch(showNotification({message: 'Failed to update profile', variant: 'error'}));
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

export const getCoins = createAsyncThunk(
    'contact/getCoins',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get(apiEndpoints.contactGetCoins);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
)

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getProfile.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.id = action.payload.data.id
                state.crm_id = action.payload.data.crm_id
                state.nick_name = action.payload.data.nick_name
                state.phone = action.payload.data.phone
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.nick_name = action.payload.data.nick_name;
            })
            .addCase(updateProfile.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCoins.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCoins.fulfilled, (state, action) => {
                state.loading = false;
                state.coins = action.payload.data.total
            })
            .addCase(getCoins.rejected, (state) => {
                state.loading = false;
            })
            .addCase(logout, (state) => {
                return initialState;
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default profileSlice.reducer;