import React from 'react';
import {Grid, Typography} from "@mui/material";
import useFirstScreenStyles from "../../../styles/ncp/promotion_profi_october/first_screen";
import voucher_in_cehia from '../../../assets/images/promotii/profi-octombrie/voucher_in_cehia.png';
import bax_24_dark from '../../../assets/images/promotii/profi-octombrie/bax_24_dark.png';
import promo_despre_alcool from "../../../assets/images/promo-despre-alcool.png";
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../../styles/themes/default";

const FirstScreen: React.FC = () => {
    const styles = useFirstScreenStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            <Grid container className={styles.boxWoodDark}>
                <Grid item xs={12}>
                    <Typography className={styles.title}>MIXEAZĂ <span className={'colorSand'}>ȚAP</span>ĂN
                        ȘI CÂȘTIGĂ</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={styles.subTitle}>Cumpără bere Kozel în orice tip de ambalaj, de minim 25 lei
                        pe
                        un singur bon fiscal și poţi câștiga prin tragere la sorţi unul din premiile
                        campaniei</Typography>
                </Grid>
                <Grid item xs={12} className={styles.paddingTopNormal}>
                    <Grid container>
                        <Grid item lg={6} xs={12} order={1}>
                            <Grid container>
                                <Grid item xs={12} className={styles.textGroupPrize}>
                                    <Typography className={styles.textCount}>1</Typography>
                                    <AddIcon className={styles.multiply}/>
                                    <Typography className={styles.textPrizeBox}>
                                        <Typography className={styles.textPrizeTitle}>VOUCHER</Typography>
                                        <Typography className={styles.textPrizeText}>Excursie în Cehia</Typography>
                                        <Typography className={styles.textPrizeText}>pentru 2 persoane</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12} order={fullScreen ? 3 : 2}>
                            <Grid container>
                                <Grid item xs={12} className={styles.textGroupPrize}>
                                    <Typography className={styles.textCount}>250</Typography>
                                    <AddIcon className={styles.multiply}/>
                                    <Typography className={styles.textPrizeBox}>
                                        <Typography className={styles.textPrizeText}>BAX 24 DOZE</Typography>
                                        <Typography className={styles.textPrizeText}>KOZEL DARK</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} xs={12} order={fullScreen ? 2 : 3}>
                            <img
                                className={styles.imgPrize}
                                src={voucher_in_cehia}
                                alt={'voucher_in_cehia'}
                                style={{maxWidth: '320px', maxHeight: '210px'}}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12} order={4}>
                            <img
                                className={styles.imgPrize}
                                src={bax_24_dark}
                                alt={'bax_24_dark'}
                                style={{maxWidth: '320px', maxHeight: '210px'}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} className={styles.promotionInterval}>
                    <Typography>Durata promotiei: <b>14.10.2024 – 27.10.2023</b></Typography>
                    <img src={promo_despre_alcool} alt={'promo despre alcool'}/>
                </Grid>
            </Grid>
        </>
    )
}

export default FirstScreen;