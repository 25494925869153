import React, {useEffect, useRef, useState} from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {Button, Container, Grid, Typography} from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import useCalendarKozelMeetStyles from "../../../styles/kozelMeetBoardGame/what_find_here/calendarKozelMeet";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import meet_logo from '../../../assets/images/kozel_meet/meet_logo.png';
import {NavLink} from "react-router-dom";
import {paths} from "../../../AppRouters";
import interactionPlugin from "@fullcalendar/interaction";
import ModalWrapper from '../../ModalWrapper';
import goat_gif from '../../../assets/images/goat-this.gif';
import { CalendarEventInterface, calendarEventList } from './calendarEventList';
import clsx from 'clsx';
import eventDaySplash from '../../../assets/images/kozel_meet/TEST-EVENTS3.png';

dayjs.extend(isToday);
const CalendarKozelMeet: React.FC = () => {
    const styles = useCalendarKozelMeetStyles();
    const calendarRef = useRef<FullCalendar | null>(null);
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [dayEventList, setDayEventList] = useState<CalendarEventInterface[]>([]);
    const [events, setEvents] = useState<CalendarEventInterface[]>([]);

    const getThursdayClass = (day: dayjs.Dayjs) => {
        if (day.isBefore(dayjs(), 'day')) {
            return styles.pastThursday;
        } else if (day.isToday()) {
            return styles.currentThursday;
        } else {
            return styles.futureThursday;
        }
    };

    const handlePrevMonth = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.prev();
            setCurrentDate(dayjs(calendarApi.getDate()));
        }
    };

    const handleNextMonth = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.next();
            setCurrentDate(dayjs(calendarApi.getDate()));
        }
    };

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            setCurrentDate(dayjs(calendarApi.getDate()));
        }
    }, []);

    useEffect(() => {
        setEvents(calendarEventList);
    }, []);

    const handleDateClick = (arg: any) => {
        setPopupText(arg.dateStr);
        const dayEvents = events.filter(item => item.date === arg.dateStr);
        setDayEventList(dayEvents);
        setModalIsOpen(true);
    }

    const handleEventClick = (arg: any) => {
        console.log(arg.event);
        setPopupText('Eveniment special la ' + arg.event.title + ', ' + arg.event.extendedProps.city +  ' in intervalul ' + arg.event.extendedProps.hour);
        setModalIsOpen(true);
    } 

    const DayEventsComponent: React.FC<{events: CalendarEventInterface[]}> = ({events}) => {
        const sortedEvents = events.sort(
            (a, b) => (a.city + a.title).localeCompare(b.city + b.title)
        );

        return (
        <div>
            {sortedEvents.map(item => (
            <Typography key={item.title} className={styles.popupSubtitle} variant="h5" mt={2} gutterBottom>
                {item.city} - {item.title} <br/> {item.hour} 
            </Typography>
            ))}
        </div>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className={styles.boxActions}>
                    <Grid item>
                        <Button className={styles.buttonPrevMonth} onClick={handlePrevMonth}><ChevronLeftIcon/></Button>
                    </Grid>
                    <Grid item>
                        <Typography className={styles.textMonthYear}>{currentDate.format('MMMM YYYY')}</Typography>
                    </Grid>
                    <Grid item>
                        <Button className={styles.buttonNextMonth} onClick={handleNextMonth}><ChevronRightIcon/></Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.calendarHeader}>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    firstDay={1}
                    // showNonCurrentDates={false}
                    // events={events}
                    dateClick={handleDateClick}
                    // eventClick={handleEventClick}
                    fixedWeekCount={false}
                    headerToolbar={false}
                    dayCellContent={(arg) => {
                        const date = dayjs(arg.date);
                        const isThursday = date.day() == 4;
                        if (isThursday) {
                            return (
                                <Grid container className={styles.dayContainer}>
                                    <Grid item className={styles.dayItem}>
                                        {/* <NavLink to={paths.kozelMeetSpecialLocations}> */}
                                            {/* <img src={meet_logo} alt={"meet_logo"} style={{width: '100%'}} className={styles.imgKozel}/> */}
                                            
                                        {/* </NavLink> */}
                                        <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: '100%' }}>
                                            <Grid item style={{ height: '30%'}}>
                                            </Grid>
                                            <Grid item>
                                                <img src={eventDaySplash} alt={"meet_logo"} style={{width: '100%'}} className={styles.imgSplashKozel}/>
                                            </Grid>
                                        </Grid>
                                        <Typography className={styles.dateDay}>{date.date()}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        } else {
                            return <Grid container className={styles.dayContainer}>
                                <Grid item className={styles.dayItem}>
                                    <Typography className={styles.dateDay}>{date.date()}</Typography>
                                </Grid>
                            </Grid>
                        }
                    }}
                />
            </Grid>

            <ModalWrapper isOpen={modalIsOpen} onRequestClose={(event, reason) => {                
                setModalIsOpen(false);
            }}>
                <Container className={styles.container}>
                    <Container className={styles.popupWrapper}>
                        <img src={goat_gif} alt={'Cool Goat'} className={styles.goatGif}/>
                        <Typography className={styles.popupTitle} variant="h4" mt={0}>{popupText}</Typography>    
                        {dayEventList.length > 0 ? <DayEventsComponent events={dayEventList} /> : <Typography className={styles.popupSubtitle} variant="h4" mt={5} gutterBottom>Nu există niciun eveniment astăzi!</Typography>}  
                        <NavLink to={''} onClick={() => setModalIsOpen(false)}>
                            <Typography className={clsx(styles.button, styles.buttonContrast)} variant="h4" mt={5}>Închide</Typography>    
                        </NavLink>
                    </Container>
                </Container>
            </ModalWrapper>
        </Grid>
    );
}

export default CalendarKozelMeet;
