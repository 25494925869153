import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import {darken, lighten} from "@mui/material";

const useFormRequestAccessStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
            paddingTop: theme.spacing(5),
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        item: {
            display: "flex",
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            marginBottom: theme.spacing(5),
        },
        boxBorderWithCenterTitle: {
            borderTopWidth: '3px',
            borderTopColor: theme.colors.ivory_beige,
            borderTopStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: '100%',
        },
        centerTopTitle: {
            color: theme.colors.ivory_beige,
            fontFamily: theme.fonts.apex,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '25px',
            fontSize: '3vw',
            marginTop: '-1.5vw',
            textAlign: "center",
            backgroundColor: theme.colors.dark_chocolate,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            lineHeight: 1,
            [theme.breakpoints.down('lg')]: {
                letterSpacing: '10px',
            }
        },
        logoKozelMeet: {
            width: '75%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
        },
        title: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.ivory_beige,
            fontSize: 'max(3vh,3vw)',
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            lineHeight: 1.3,
        },
        borderDottedBottom: {
            borderBottomStyle: "dotted",
            borderBottomColor: theme.colors.desert_sand_gold,
            borderBottomWidth: "10px",
            paddingBottom: theme.spacing(5),
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                borderBottomWidth: "5px",
                paddingBottom: theme.spacing(2),
            },
        },
        boxSelectLocations: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingBottom: theme.spacing(0),
            },
        },
        textLocations: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vh,2vw)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.ivory_beige,
            textAlign: 'center',
        },
        formControlSelect: {
            backgroundColor: theme.colors.dark_chocolate,
            padding: theme.spacing(1),
            width: '100%',
        },
        selectLocation: {
            backgroundColor: theme.colors.latte_cream,
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1vw,1vh)',
            color: theme.colors.dark_chocolate,
            textAlign: 'center',
            '&:hover': {
                backgroundColor: theme.colors.latte_cream,
            },
            '& .MuiSelect-icon': {
                color: theme.colors.dark_chocolate,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
            },
            '& .MuiMenu-list': {
                backgroundColor: theme.colors.latte_cream,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vw,2vh)',
            }
        },
        menuList: {
            backgroundColor: theme.colors.latte_cream,
        },
        menuItem: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1vw,1vh)',
            color: theme.colors.dark_chocolate,
            backgroundColor: theme.colors.latte_cream,
            textAlign: 'center',
            '&.Mui-selected': {
                backgroundColor: theme.colors.latte_cream,
                '&:hover': {
                    backgroundColor: theme.colors.dark_chocolate,
                    color: theme.colors.latte_cream,
                },
            },
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vw,2vh)',
                textAlign: 'center',
                justifyContent: 'center',
            }
        },
        boxDotted: {
            borderStyle: "dotted",
            borderColor: theme.colors.desert_sand_gold,
            borderWidth: "10px",
            borderRadius: '25px',
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
            }
        },
        itemFormFile: {
            padding: ` 0 ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                padding: ` 0 ${theme.spacing(2)}`,
            }
        },

        containerFormUpload: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingTop: theme.spacing(2),
                paddingRight: theme.spacing(0),
                paddingLeft: theme.spacing(0),
                flexDirection: 'column-reverse',
            }
        },
        containerItemFormUpload: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down('lg')]: {
                '&:first-child': {
                    paddingTop: theme.spacing(2),
                },
            }
        },
        boxFormImgArrow: {
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            }
        },
        btnUploadFile: {
            backgroundColor: theme.colors.latte_cream,
            color: theme.colors.dark_chocolate,
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            fontSize: 'max(1.5vw,1.5vh)',
            fontFamily: theme.fonts.poplar,
            '&:hover': {
                backgroundColor: lighten(theme.colors.latte_cream, 0.5),
                color: darken(theme.colors.dark_chocolate, 0.5),
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vw,2vh)',
            }
        },
        imgUploaded: {
            width: '75%',
            height: "auto",
            maxHeight: '300px',
            maxWidth: '500px',
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`
        },
        formIconArrow: {
            width: '50%',
            height: 'auto',
            transform: 'scaleX(-1) rotate(45deg)',
        },
        textUploadFile: {
            padding: `0 ${theme.spacing(8)}`,
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2vw,2vh)',
            color: theme.colors.ivory_beige,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(3)}`,
                textAlign: 'center',
            }
        },
        textTips: {
            padding: `${theme.spacing(2)} ${theme.spacing(8)}`,
            fontFamily: theme.fonts.apex,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.colors.ivory_beige,
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
            },
            '& a':{
                color: 'inherit',
                textDecoration: 'underline',
                // textTransform: 'uppercase',
                fontStyle: 'italic',
            },
        },
        errorMessageGetAccess: {}
    };
})

export default useFormRequestAccessStyles;
