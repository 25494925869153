import React, { useState } from 'react';
import useStyles from './style';

interface ImageProps {
    src: string;
    alt: string;
}

const Index: React.FC<ImageProps> = ({ src, alt }) => {
    const [maxWidth, setMaxWidth] = useState<number | string>('100%');
    const classes = useStyles({ maxWidth });

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const { naturalWidth } = event.currentTarget;
        setMaxWidth(naturalWidth);
    };

    return <img className={classes.image} src={src} alt={alt} onLoad={handleImageLoad} />;
};

export default Index;
