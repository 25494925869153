import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useTeaserInviteFriends = createUseStyles((theme: Theme) => {
    return {
        container: {
            padding: `max(3vh, 3vw) ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
            justifyContent: 'center',
            alignItems: "center"
        },
        containerItem: {
            justifyContent: 'center',
            alignItems: "center",
            display: 'flex',
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 900,
            fontSize: 'max(3vh,3vw)',
            paddingBottom: theme.spacing(3),
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        teaserContentContainer: {
            justifyContent: 'center',
            alignItems: "center",
        },
        teaserContentContainerItem: {
            justifyContent: 'center',
            alignItems: "center",
            display: 'flex',
        },
        teaserContentImage: {
            maxWidth: '200px',
            width: '100%',
            height: 'auto',
            marginTop: theme.spacing(5),
        },
        teaserContentDescription: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2.5vh,2.5vw)',
            color: theme.colors.latte_cream,
            padding: `0 15vw`,
            marginTop: theme.spacing(5),
            textAlign: 'center',
            letterSpacing: '2px',
            fontWeight: 'bold',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(1.5vh,1.5vw)',
                padding: `0 ${theme.spacing(2)}`,
            }
        },
        teaserContentButtonInvite: {
            margin: `${theme.spacing(5)} 0`,
            fontFamily: theme.fonts.poplar,
            fontWeight:'bold',
            fontSize: 'max(2vh,2vw)',
            backgroundColor: theme.colors.latte_cream,
            color: theme.colors.dark_chocolate,
            textTransform: 'uppercase',
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            }
        },
    };
});

export default useTeaserInviteFriends;