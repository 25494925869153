import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import bgWoodDark from '../../assets/images/ncp/ncp_bg1.jpg';
import bgWoodLight from '../../assets/images/ncp/ncp_bg2.jpg';

const useMixTapanStyles = createUseStyles((theme: Theme) => {
    return {
        boxLeft: {
            backgroundImage: `url(${bgWoodDark})`,
            padding: theme.spacing(8),
            display: "flex",
            flexDirection:'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
            position: "relative",
        },
        boxRight: {
            backgroundImage: `url(${bgWoodLight})`,
            display: "flex",
            flexDirection:'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
        },
        wrapperRightText: {
            padding: theme.spacing(4),
        },
        textSecondary: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vw,2.5vh)',
            color: theme.palette.secondary.main,
        },
        textPrimary: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vw,2.5vh)',
            color: theme.palette.primary.main,
        },
        promotionInterval:{
            display: 'flex',
            justifyContent: "space-around",
            alignItems: 'center',
            alignContent: 'center',
            background: theme.palette.background.default,
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]:{
                flexDirection: 'column'
            }
        },
        buttonRegulament: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1vh,1vw)',
            fontWeight: 'bold',
            color: theme.palette.error.main,
            '&:hover':{
                textDecoration: 'underline',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(2vh,2vw)',
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translate(-50%, 0)'
            }
        },
        dialog: {
            '& .MuiPaper-root': {
                backgroundColor: theme.palette.background.default,
                [theme.breakpoints.up('lg')]: {
                    minWidth: `${theme.breakpoints.values.md}${theme.breakpoints.unit}`
                }
            }
        },
        dialogTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2vh,2vw)',
            color: theme.palette.primary.main,
            textAlign: 'center',
        },
        dialogContent: {},
        dialogContentList: {
            display: 'flex',
            flexWrap: 'wrap',
            '& img': {
                width: '50px',
                height: "auto",
            },
            '& .MuiListItemText-root span': {
                fontFamily: theme.fonts.apex,
                fontSize: 'max(1.5vh,1.5vw)',
                color: theme.palette.primary.main,
                paddingLeft: theme.spacing(2),
                fontWeight: 'bold'
            },
            '& .MuiListItem-root': {
                paddingTop: theme.spacing(2),
                width: '50%',
            }
        },
        buttonCloseModal: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1vh,1vw)',
            paddingLeft: theme.spacing(2),
            paddingRight:  theme.spacing(2),
            '&:hover':{
                color: theme.palette.primary.main,
            }
        }
    }
});

export default useMixTapanStyles;