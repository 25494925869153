import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useTeaserGameStyle = createUseStyles((theme: Theme) => {
    return {
        containerTeaserGame: {
            backgroundColor: theme.colors.dark_chocolate,
        },
        itemTeaserGame: {
            margin: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                margin: `${theme.spacing(5)} ${theme.spacing(2)}`,
            }
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            color: theme.palette.primary.light,
            fontWeight: 'bold',
            fontSize: 'max(5vh,5vw)',
            textAlign: 'center',
            lineHeight: 0.8,
            marginTop: 'min(-1vh,-1vw)',
            textTransform: 'uppercase',
            '& .underTitle': {
                fontSize: 'max(3vh,3vw)',
            }
        },
        containerContent: {
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
            }
        },
        containerItemContent: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            '&:first-child': {
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(2),
            }
        },
        containerSectionItemColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'start',
            alignItems: 'start',
        },
        borderDottedTop: {
            borderTopWidth: '10px',
            borderTopStyle: 'dotted',
            borderTopColor: theme.colors.desert_sand_gold
        },
        containerSection: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        containerSectionItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        subtitleContent: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.light,
            fontWeight: 'bold',
            fontSize: 'max(2vh,2vw)',
            textAlign: 'center',
            '& a': {
                color: theme.colors.desert_sand_gold,
                textDecoration: 'underline',
            }
        },
        containerTapZone: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        boxImgTap: {
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                order: 2,
                paddingTop: theme.spacing(2),
                textAlign: 'center',
            }
        },
        imgTap: {
            maxWidth: '200px',
            width: '100%',
            height: 'auto',
        },
        boxTapMessage: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',

            [theme.breakpoints.down('lg')]: {
                order: 1,
            }

        },
        tapMessage: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(2vw,2vh)',
            padding: theme.spacing(2),
            backgroundColor: theme.colors.latte_cream,
            margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
            borderRadius: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`
        },
        titleContent: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(3vw,3vh)',

        },
        titleContent2: {
            extend: 'titleContent',
            color: theme.palette.primary.light,
        },
        arrowImg: {
            maxWidth: '150px',
            width: '100%',
            height: 'auto',
            [theme.breakpoints.down("lg")]: {
                display: 'none',
            }
        },
        boxItemBottleChose: {
            paddingTop: theme.spacing(5),
        },
        imgBottlePremium: {
            maxWidth: '100px',
            cursor: 'pointer',
            marginRight: theme.spacing(10),
            padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(0)} ${theme.spacing(6)}`,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.colors.latte_cream,
            [theme.breakpoints.down('lg')]: {
                marginRight: theme.spacing(1),
                padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(2)}`,
            },
            '&.selected': {
                borderWidth: theme.spacing(1),
                borderColor: theme.colors.desert_sand_gold,
                backgroundColor: theme.colors.latte_cream,
                padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(5)}`,
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(1)}`,
                },
            },
        },
        imgBottleDark: {
            extend: 'imgBottlePremium',
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                marginLeft: theme.spacing(2),
            }
        },
        buttonCta: {
            marginTop: theme.spacing(5),
            fontFamily: theme.fonts.apex,
            color: theme.colors.dark_chocolate,
            backgroundColor: theme.colors.latte_cream,
            fontWeight: 'bold',
            fontSize: 'max(2vh,2vw)',
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            }
        },
    }
});

export default useTeaserGameStyle;