import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import navbarBgImg from '../assets/images/patternAlb.jpg';
import bodyBgImg from '../assets/images/patternAlb.jpg';

const useNavbarStyles = createUseStyles((theme: Theme) => {
    return {
        containerHeader: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: 'center',
            alignContent: 'center',
        },
        containerHeaderItem: {
            padding: `${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        },
        logoHeader: {
            maxWidth: '150px',
            width: '100%',
            height: 'auto',
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            }
        },
        ctas: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'end',
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'row',
            },
        },
        closeButtonHeader: {
            color: theme.colors.dark_chocolate,
            position: "sticky",
            top: theme.spacing(2),
            left: theme.spacing(2),
            cursor: 'pointer',
        },
        container: {},
        appBar: {
            backgroundImage: `url(${navbarBgImg})`,
            backgroundRepeat: 'repeat',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        logo: {
            height: '100%',
            width: 'auto',
            padding: theme.spacing(2),
            maxHeight: '50px',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '20px',
            }
        },
        burgerButton: {
            color: theme.palette.primary.main
        },
        burgerIcon: {
            color: theme.palette.primary.main
        },
        navbarDrawer: {
            position: 'relative',
            '& .MuiPaper-root': {
                backgroundImage: `url(${bodyBgImg})`,
                width: '30vw',
                [theme.breakpoints.down('sm')]: {
                    width: '75vw',
                }
            },
        },
        navbarDrawerList: {
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(1),
            },
        },
        navbarDrawerListItem: {
            marginRight: theme.spacing(5),
            paddingRight: theme.spacing(5),
            marginTop: theme.spacing(1),
            textAlign: "right",
            [theme.breakpoints.down('lg')]: {
                marginRight: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }
        },
        navbarDrawerListItemText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vh,2vw)',
            fontWeight: "bold",
            lineHeight: 1,
            paddingBottom: 0,
            margin: 0,
            color: theme.palette.primary.main,
            '& .MuiTypography-root': {
                fontSize: 'max(2vh,2vw)',
                fontWeight: "bold",
                lineHeight: 1,
            },

        },
        button: {
            display: "flex",
            fontSize: '16px',
            color: theme.colors.dark_chocolate,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'right',
            justifyContent: 'end',
            gap: 3,
            marginRight: theme.spacing(5),
            paddingRight: 0,
            '&:hover': {
                color: theme.colors.desert_sand_gold,
            },
            [theme.breakpoints.down('lg')]: {
                marginRight: theme.spacing(2),
            }
        },
    }
});

export default useNavbarStyles;