import {Button, CircularProgress, FormControlLabel, Grid, Switch, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import useVoceaRomanieiStyles from "../styles/voceaRomaniei/voceaRomaniei";
import clsx from "clsx";
import logo from '../assets/images/logo_late_cream.png';
import logo_vocea_romaniei from '../assets/images/vocea_romaniei/logo_vocea_romaniei.png';
import teaser_img from '../assets/images/vocea_romaniei/goat.png';
import goatThis from '../assets/images/goat-this.gif';
import {NavLink} from "react-router-dom";
import {paths} from "../AppRouters";
import {isAfterSeptember16th, isBeforeNovember15th} from "../utils/dateTimesUtils";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useAppDispatch, useAppSelector} from "../stores/store";
import dayjs, {Dayjs} from "dayjs";
import {ExternalCampaignPayloadProps, insertExternalCampaign} from "../features/externalCampaign/externalCampaignSlice";
import tzapan_vocea_romaniei from '../assets/images/vocea_romaniei/tzapan_vocea_romaniei.png';

const ContentSuccessSubscribe: React.FC = () => {
    const styles = useVoceaRomanieiStyles();
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={10} lg={8} className={clsx(styles.item, styles.boxTitleBorderDotted, styles.formBox, styles.successBox)}>
                <Typography className={styles.successSubscribe}>FELICITĂRI</Typography>
                <Typography className={styles.confirmSubscribe}>EȘTI ÎNSCRIS ÎN TRAGEREA LA SORȚI PENTRU BILETE LA VOCEA ROMÂNIEI!</Typography>
            </Grid>
        </Grid>
    )
}
const ContentInviteToCreateAccount: React.FC = () => {
    const styles = useVoceaRomanieiStyles();
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={10} lg={10} className={clsx(styles.item, styles.formBox, styles.inviteBox)}>
                <Grid container>
                    <Grid item xs={12} lg={6} className={styles.leftBoxInviteAccount}>
                        <Typography className={styles.inviteTitle}>Fii pe fază aici - vom afișa pe site lista câștigătorilor, după finalizarea perioadei de înscriere.</Typography>
                        <Typography className={styles.inviteText}>Între timp, creează-ți cont pe site, invită-ți
                            prietenii, formează grupul și câștigă puncte pe baza cărora puteți accesa premii și experiențe împreună!</Typography>
                        <NavLink to={paths.register} className={styles.inviteButton}>ÎMI CREEZ CONT</NavLink>
                        <Typography className={styles.starText}>*înscrierea în comunitate îți aduce 50 de puncte</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} className={styles.rightBoxInviteAccount}>
                        <img src={tzapan_vocea_romaniei} alt={'tzapan_vocea_romaniei'} className={styles.imgKozelVoiceRo}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const ContentAfter16September: React.FC = () => {
    const styles = useVoceaRomanieiStyles();
    const dispatch = useAppDispatch();
    const {loading} = useAppSelector(state => state.externalCampaign);
    const [birthdate, setBirthdate] = useState<Dayjs | null>(null);
    const [lastname, setLastname] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isDatePickerBirthdateOpen, setIsDatePickerBirthdateOpen] = useState(false);
    const [accordingRules, setAccordingRules] = useState<boolean>(false);
    const handleSubmit = () => {
        if (validateFields()) {
            try {
                if(!birthdate) {
                    return ;
                }

                const data: ExternalCampaignPayloadProps = {
                    identify_name: 'vocea_romaniei',
                    birthdate: birthdate.format('YYYY-MM-DD'),
                    lastname,
                    firstname,
                    phone,
                };

                dispatch(insertExternalCampaign(data));
                resetState();

            } catch (error) {
                console.error("Error converting file to Base64:", error);
            }
        }
    }
    const validateFields = () => {
        const newErrors: { [key: string]: string } = {};

        if (!birthdate) newErrors.birthdate = 'Data nașterii este obligatorie';
        if (!lastname) newErrors.lastname = 'Numele este obligatoriu';
        if (!firstname) newErrors.firstname = 'Prenumele este obligatoriu';
        if (!phone) newErrors.phone = 'Numărul de telefon este obligatoriu';
        if (!accordingRules) newErrors.accordingRules = 'Trebuie să acceptați regulamentul';

        setErrors(newErrors);
        validateBirthdate(birthdate);
        validatePhone(phone);

        return Object.keys(newErrors).length === 0;
    };

    const validatePhone = (phoneNr:string) => {
        if(phoneNr){
            if (phoneNr.length !== 10) {
                setErrors(prevErrors => ({ ...prevErrors, phone: 'Numărul de telefon trebuie sa fie de 10 cifre' }));
                return false;
            } else {
                setErrors(prevErrors => ({ ...prevErrors, phone: '' }));
                return true;
            }
        }
    };
    const resetState = () => {
        setBirthdate(null);
        setLastname('');
        setFirstname('');
        setPhone('');
        setErrors({});
    }
    const handleBirthdateChange = (newValue: Dayjs | null) => {
        setBirthdate(newValue);
        validateBirthdate(newValue);
        setIsDatePickerBirthdateOpen(false);
    };

    const validateBirthdate = (date: Dayjs | null) => {
        if (date) {
            const today = dayjs();
            const age = today.diff(date, 'year');
            if (age < 18) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    birthdate: 'Trebuie să aveți cel puțin 18 ani.',
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    birthdate: '',
                }));
            }
        }
    };

    const handleBirthdateFocus = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDatePickerBirthdateOpen(true);
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setPhone(value);
        }
    };

    return (
        <>
            <Grid item xs={12} className={styles.item}>
                <Typography className={styles.text}>Înscrie-te în tragerea la sorți pentru biletele la semifinala și
                    finala Vocea României!</Typography>
            </Grid>

            <Grid item xs={10} lg={6} className={clsx(styles.item, styles.boxTitleBorderDotted, styles.formBox)}>
                <DatePicker
                    value={birthdate}
                    onAccept={handleBirthdateChange}
                    label={'Data nașterii*'}
                    open={isDatePickerBirthdateOpen}
                    onOpen={() => setIsDatePickerBirthdateOpen(true)}
                    onClose={() => setIsDatePickerBirthdateOpen(false)}
                    format={'DD/MM/YYYY'}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!errors.birthdate,
                            helperText: errors.birthdate,
                            className: styles.formInput,
                            variant: 'outlined',
                            color: 'primary',
                            onClick: handleBirthdateFocus,
                        },
                    }}
                />
                <TextField
                    value={lastname}
                    onChange={e => setLastname(e.target.value)}
                    fullWidth
                    label={'Nume*'}
                    error={!!errors.lastname}
                    helperText={errors.lastname}
                    className={styles.formInput}
                    variant="outlined"
                    color={'primary'}
                />
                <TextField
                    value={firstname}
                    onChange={e => setFirstname(e.target.value)}
                    fullWidth
                    label={'Prenume*'}
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                    className={styles.formInput}
                    variant="outlined"
                    color={'primary'}
                />
                <TextField
                    value={phone}
                    onChange={handlePhoneChange}
                    fullWidth
                    label={'Număr de telefon*'}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    className={styles.formInput}
                    variant="outlined"
                    color={'primary'}
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />
                <FormControlLabel
                    className={styles.formControlCheckbox}
                    labelPlacement={'start'}
                    control={<Switch
                        checked={accordingRules}
                        onChange={e => setAccordingRules(e.target.checked)}
                        inputProps={{'aria-label': 'controlled'}}
                    />}
                    label={<Typography className={styles.formControlCheckboxLabel}>Am citit integral și sunt de acord cu&nbsp;
                        <a target={'_blank'} rel="noreferrer"  href={`/pdf/2024/25-KOZEL x VOCEA ROMANIEI_regulament autentificat.pdf`}>Regulamentului campaniei</a>.<br/>Înțeleg că datele personale sunt colectate strict în vederea contactării potențialilor câștigători.</Typography>}
                />
                {errors.accordingRules && <Typography color="error">{errors.accordingRules}</Typography>}
                <Grid container>
                    <Grid item xs={12}>
                        {loading ?
                            <Button className={styles.button}><CircularProgress/></Button> :
                            <Button onClick={handleSubmit} className={styles.button}>MĂ ÎNSCRIU</Button>}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
const ContentBefore16September: React.FC = () => {
    const styles = useVoceaRomanieiStyles();
    return (
        <>
            <Grid item xs={10} lg={6} className={clsx(styles.item, styles.boxTitleBorderDotted)}>
                <Typography className={styles.textWaitCampaign}>Înscrierile<br/> încep pe 16 septembrie</Typography>
            </Grid>
        </>
    )
}

const VoceaRomanieiPage: React.FC = () => {
    const styles = useVoceaRomanieiStyles();
    const {success} =useAppSelector(state => state.externalCampaign);
    const {isAuthenticated} = useAppSelector(state => state.auth);

    return <Grid container className={styles.containerWrapper}>
        <Grid item xs={12} className={clsx(styles.containerItemWrapper, styles.bg_vocea_romaniei)}>
            <Grid container className={styles.container}>
                <Grid item xs={12} className={styles.item}>
                    <img src={logo} alt={'logo'} className={styles.logo}/>
                    <Typography className={styles.title}>
                        te trimite la
                    </Typography>
                </Grid>
                <Grid item xs={12} className={styles.item}>
                    <img src={logo_vocea_romaniei} alt={'logo vocea romaniei'} className={styles.logo_vocea_romaniei}/>
                </Grid>

                {isAfterSeptember16th() && !success && <ContentAfter16September/>}
                {!isAfterSeptember16th() && !success && <ContentBefore16September/>}
                {success && <ContentSuccessSubscribe/>}
                {success && !isAuthenticated && <ContentInviteToCreateAccount/>}
                {!success &&
                    <Grid item xs={12} className={styles.item}>
                        <img src={teaser_img} alt={'teaser_vocea_romaniei'} className={styles.teaser_img}/>
                    </Grid>}

                {isAfterSeptember16th() && isBeforeNovember15th() && <Grid item xs={12} className={styles.item}>
                    <NavLink to={'/pdf/2024/25-KOZEL x VOCEA ROMANIEI_regulament autentificat.pdf'} target={'_blank'} className={styles.link_rules}>Vezi Regulamentul aici</NavLink>
                </Grid>}
                <Grid item xs={12} className={clsx(styles.item, styles.boxCampaignDescription)}>
                    <Typography className={styles.campaignDescription}>Perioada campaniei: <b>16 septembrie - 15
                        noiembrie</b></Typography>
                    <Typography className={styles.campaignDescription}>Număr premii: <b>6 invitații duble</b> (3 în
                        semifinale, 3 în finală)</Typography>
                    <Typography className={styles.campaignDescription}>Premiu fără valoare comercială, acesta este de
                        nepreţuit.</Typography>
                    <Typography className={clsx(styles.campaignDescription, styles.paddingTop)}>
                        Premiul va fi oferit prin tragere la sorți.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} className={clsx(styles.containerItemWrapper)}>
            <Grid container className={styles.container}>
                <Grid item xs={12} className={clsx(styles.item, styles.borderTopDotted)}>
                    <Typography className={styles.keepEyesOnUsText}>Fii pe fază, luna aceasta îți pregătim și alte
                        surprize</Typography>
                </Grid>
                <Grid item xs={12} className={styles.item}>
                    <img src={goatThis} alt={'Goat Gif'} className={styles.logoKozel}/>
                </Grid>
                <Grid item xs={12} className={styles.item}>
                    <Typography className={styles.title2}>ȚAPene!</Typography>
                </Grid>
                <Grid item xs={12} className={styles.item}>
                    <Typography className={styles.inviteFriendsText}>
                        Între timp, <NavLink to={paths.myGroup} className={styles.link}>invită-ți prietenii</NavLink> în
                        comunitate, creează-ți echipa pe kozel.ro și pregătiți-vă de distracție și premii
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default VoceaRomanieiPage