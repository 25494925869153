import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../stores/store';
import {closeLoginModal, login} from '../features/auth/authSlice';
import {Button, CircularProgress, FormControlLabel, Grid, Switch, TextField, Typography} from '@mui/material';
import useLoginModalStyles from "../styles/loginModal";
import {NavLink} from "react-router-dom";
import PopupComponent from "./kozel/PopupComponent";
import {paths} from "../AppRouters";

const phoneNumberValidationRule = new RegExp('^(07)[0-9]{8}$', 'i');

interface LoginModalProps {
    onRequestClose: () => void;
    onLoginSuccess: () => void;
}

interface StateErrors {
    phone: string,
    password: string,
}

const LoginModal: React.FC<LoginModalProps> = ({onRequestClose, onLoginSuccess}) => {
    const dispatch = useAppDispatch();
    const loginModalStyles = useLoginModalStyles();
    const {error, loading, isLoginModalOpen} = useAppSelector((state) => state.auth);
    const [phoneInput, setPhoneInput] = useState('');
    const [password, setPassword] = useState('');
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);
    const [errors, setErrors] = useState<StateErrors>({phone: '', password: ''});
    const validateHandleLogin = () => {
        return validatePhone(phoneInput) && validatePassword(password);
    }

    const validatePhone = (phone: string) => {
        if (!phoneNumberValidationRule.test(phone)) {
            setErrors(prevState => ({
                ...prevState,
                phone: 'Numarul de telefon trebuie sa inceapa cu 07 si sa fie de 10 cifre.'
            }));

            return false;
        }

        resetErrorPhone();

        return true;
    }

    const resetErrorPhone = () => {
        setErrors(prevState => ({
            ...prevState,
            phone: ''
        }));
    }

    const validatePassword = (pass: string) => {
        if (pass.length < 8) {
            setErrors(prevState => ({
                ...prevState,
                password: 'Parola trebuie sa fie de minim 8 caractere.'
            }));

            return false;
        }

        resetErrorPassword();
        return true;
    };

    const resetErrorPassword = () => {
        setErrors(prevState => ({
            ...prevState,
            password: ''
        }));
    }
    const handleLogin = () => {
        validateHandleLogin();

        if (validateHandleLogin()) {
            dispatch(login({phone: phoneInput, password, keepLoggedIn}));
        }
    };
    const handleSetPhoneInput = (value: string) => {
        resetErrorPhone();
        setPhoneInput(value.replace(/[^\d.]/g, ''));
    }
    const handleSetPassword = (value: string) => {
        resetErrorPassword();
        setPassword(value);
    }

    return (
        <PopupComponent isOpen={isLoginModalOpen} onClose={onRequestClose}>
            <Grid container>
                <Grid item xs={12} justifyContent={'center'}>
                    <Typography id="login-modal-title" className={loginModalStyles.title}>
                        Login
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={'center'}>
                    {error && <Typography color="error">{error}</Typography>}
                    <TextField
                        label="Telefon"
                        fullWidth
                        className={loginModalStyles.formInput}
                        margin="normal"
                        value={phoneInput}
                        onChange={(e) => handleSetPhoneInput(e.target.value)}
                        error={!!errors.phone}
                        helperText={errors.phone}
                    />
                </Grid>
                <Grid item xs={12} justifyContent={'center'}>
                    <TextField
                        type={'password'}
                        label="Parola"
                        fullWidth
                        className={loginModalStyles.formInput}
                        margin="normal"
                        value={password}
                        onChange={(e) => handleSetPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                </Grid>
                <Grid item xs={12} justifyContent={'center'}>
                    <FormControlLabel
                        className={loginModalStyles.formControlCheckbox}
                        control={
                            <Switch
                                checked={keepLoggedIn}
                                onChange={(e) => setKeepLoggedIn(e.target.checked)}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        }
                        labelPlacement={'start'}
                        label={<Typography className={loginModalStyles.formControlCheckboxLabel}>Raman logat</Typography>}
                    />
                </Grid>
                <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
                    <Button onClick={handleLogin} disabled={loading} className={loginModalStyles.submitButton}>
                        Autentificare {loading ? <CircularProgress size={24}/> : ''}
                    </Button>
                </Grid>
                <Grid item xs={12} justifyContent={'center'}>
                    <Typography className={loginModalStyles.textGoToForgetPassword}><NavLink to={paths.forgetPassword}
                                                                                             onClick={(e) => {
                                                                                                 dispatch(closeLoginModal())
                                                                                             }}>Ți-ai uitat
                        parola?</NavLink></Typography>
                </Grid>
                <Grid item xs={12} justifyContent={'center'}>
                    <Typography className={loginModalStyles.textGoToRegister}>Nu ai cont? <NavLink to={paths.register}
                                                                                                   onClick={(e) => {
                                                                                                       dispatch(closeLoginModal())
                                                                                                   }}>Înregistrează-te</NavLink></Typography></Grid>
            </Grid>
        </PopupComponent>
    );
};

export default LoginModal;
