import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../stores/store';
import {Button, CircularProgress, Grid, TextField, Typography} from '@mui/material';
import {resetPassword, sendPhoneCode} from "../features/auth/forgetPasswordSlice";
import {openLoginModal} from "../features/auth/authSlice";
import useForgetPasswordStyles from "../styles/forgetPassword";
import goatGif from '../assets/images/goat-this.gif';

const phoneNumberValidationRule = new RegExp('^(07)[0-9]{8}$', 'i');
const codeValidationRule = new RegExp('^[0-9]{6}$', 'i');

interface StateErrors {
    phone: string,
    code: string,
    password: string,
    confirmPassword: string,
}

const ForgetPasswordPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const forgetPasswordStyles = useForgetPasswordStyles();
    const {isCodeSent, error, loading, isResetPasswordSuccess} = useAppSelector((state) => state.forgetPassword);
    const [phoneInput, setPhoneInput] = useState('');
    const [codeInput, setCodeInput] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<Partial<StateErrors>>({});

    useEffect(() => {
        if (!isCodeSent) {
            resetState();
        }
    }, [isCodeSent]);

    const resetState = () => {
        setPhoneInput('');
        setCodeInput('');
        setPassword('');
        setConfirmPassword('');
        setErrors({});
    }

    const handleSendCode = () => {
        validatePayloadSendCode();

        if (Object.values(errors).every(currValue => currValue === '')) {
            dispatch(sendPhoneCode({phone: phoneInput}));
        }
    };
    const validatePhone = (phone: string) => {
        if (!phoneNumberValidationRule.test(phone)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                phone: 'Phone number must begin with 07 and has 10 digits',
            }));
            return false;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
        }));

        return true;
    }
    const validateCode = (code: string) => {
        if (!codeValidationRule.test(code)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                code: 'Code must have 6 digits',
            }));
            return;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            code: '',
        }));

        return true;
    }
    const validatePassword = (pass: string) => {
        if (pass.length > 8) {
            setErrors(prevErrors => ({
                ...prevErrors,
                code: 'Parola trebuie sa contina cel putin 8 caractere',
            }));
            return;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            password: '',
        }));

        return true;
    }
    const validateConfirmPassword = (confirmPass: string) => {

        if (password !== confirmPass) {
            setErrors(prevErrors => ({
                ...prevErrors,
                code: 'Confirmarea parolei nu este identica cu parola.',
            }));
            return false;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            confirmPassword: '',
        }));

        return true;
    }

    const validatePayloadRegister = () => {
        validatePhone(phoneInput);
        validateCode(codeInput);
        validatePassword(password);
        validateConfirmPassword(confirmPassword);
    }

    const validatePayloadSendCode = () => {
        validatePhone(phoneInput);
    }
    const handleResetPassword = () => {
        validatePayloadRegister();

        if (Object.values(errors).every(currValue => currValue === '')) {
            const payload = {
                phone: phoneInput,
                code: codeInput,
                password,
                confirmPassword,
            }

            dispatch(resetPassword(payload))
        }
    };
    const handleSetPhoneInput = (value: string) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
        }));
        setPhoneInput(value.replace(/[^\d.]/g, ''));
    }
    const handleSetCodeInput = (value: string) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            code: '',
        }));
        setCodeInput(value.replace(/[^\d.]/g, ''));
    }

    const stepValidatePhone = () => !isCodeSent && !isResetPasswordSuccess;
    const stepResetPassword = () => isCodeSent && !isResetPasswordSuccess;

    if (isResetPasswordSuccess) {
        return (
            <Grid container className={forgetPasswordStyles.container}>
                <Grid item xs={12} lg={6} xl={4} className={forgetPasswordStyles.thankYouContainer}>
                    <img src={goatGif} alt={'Kozel'}/>
                    <Typography className={forgetPasswordStyles.title}>Goatiful!</Typography>
                    <Typography className={forgetPasswordStyles.subtitle}>Ai resetat parola cu success.</Typography>
                    <Typography className={forgetPasswordStyles.textHaveAccount}>Loghează-te și bucurăte de experiența Kozel</Typography>
                    <Button className={forgetPasswordStyles.submitButton} onClick={(e) => {
                        dispatch(openLoginModal())
                    }}>Mă loghez</Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className={forgetPasswordStyles.container}>
            <Grid item xs={12} lg={6} xl={4} className={forgetPasswordStyles.formContainer}>
                <Typography className={forgetPasswordStyles.title}>
                    Resetare parolă
                </Typography>
                <Typography className={forgetPasswordStyles.subtitle}>
                    {!isCodeSent ? '-Validare Telefon-' : ''}
                </Typography>
                {error && <Typography color="error">{error}</Typography>}

                {stepValidatePhone() && (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: 'center'}}>
                        <TextField
                            label="Phone Number"
                            fullWidth
                            margin="normal"
                            value={phoneInput}
                            className={forgetPasswordStyles.formInput}
                            onChange={(e) => handleSetPhoneInput(e.target.value)}
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />
                        <Button onClick={handleSendCode} disabled={loading}
                                className={forgetPasswordStyles.submitButton}>
                            TRIMITE SMS {loading ? <CircularProgress size={24}/> : ''}
                        </Button>
                    </div>)}
                {stepResetPassword() && (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: 'center'}}>
                        <TextField
                            label="Verification Code"
                            fullWidth
                            margin="normal"
                            className={forgetPasswordStyles.formInput}
                            value={codeInput}
                            onChange={(e) => handleSetCodeInput(e.target.value)}
                            error={!!errors.code}
                            helperText={errors.code}
                        />

                        <TextField
                            type={'password'}
                            label="Parola"
                            fullWidth
                            margin="normal"
                            className={forgetPasswordStyles.formInput}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <TextField
                            type={'password'}
                            label="Confirmare parola"
                            fullWidth
                            margin="normal"
                            className={forgetPasswordStyles.formInput}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />
                        <Button onClick={handleResetPassword} disabled={loading}
                                className={forgetPasswordStyles.submitButton}>
                            Reseteaza parola {loading ? <CircularProgress size={24}/> : ''}
                        </Button>
                    </div>
                )}
                <Typography className={forgetPasswordStyles.textHaveAccount}>Ai cont deja? <br/> <span
                                                                                                    onClick={(e) => {
                                                                                                        dispatch(openLoginModal())
                                                                                                    }}>Acceseaza contul
                    aici</span></Typography>
            </Grid>
        </Grid>
    );
};

export default ForgetPasswordPage;
