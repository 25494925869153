export interface CalendarEventInterface {
    title: string,
    date: string,
    city: string,
    hour: string,
}

export const calendarEventList: CalendarEventInterface[] = [
    { title: 'CURTEA BERARILOR - CENTRUL ISTORIC', date: '2024-11-14', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'TERASA CURTEA BERARILOR', date: '2024-11-14', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'TANKERIA', date: '2024-11-14', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'CROSSFIRE', date: '2024-11-14', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'DRAFT PUB', date: '2024-11-14', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'THE PLACE', date: '2024-11-14', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'PAPAO', date: '2024-11-14', city: 'PITEȘTI', hour: '18:00-20:00' },
    { title: 'URBAN HOME', date: '2024-11-14', city: 'PITEȘTI', hour: '20:00-22:00' },
    { title: 'DOME', date: '2024-11-14', city: 'TÂRGU MUREȘ', hour: '20:00-22:00' },
    { title: 'CASA BERII', date: '2024-11-14', city: 'TÂRGU MUREȘ', hour: '22:00-24:00' },
    { title: 'AMBRA ROVINE', date: '2024-11-14', city: 'CRAIOVA', hour: '18:00-20:00' },
    { title: 'SNOBI', date: '2024-11-14', city: 'CRAIOVA', hour: '20:00-22:00' },
    { title: 'CRAZY BITES', date: '2024-11-14', city: 'BRAȘOV', hour: '18:00-22:00' },
    { title: 'ONE & ONLY LORDS PUB', date: '2024-11-14', city: 'ORADEA', hour: '19:00-21:00' },
    { title: 'MOSCOVA EXOTICA', date: '2024-11-14', city: 'ORADEA', hour: '21:00-23:00' },
    { title: 'BRUTO', date: '2024-11-14', city: 'CLUJ', hour: '19:00-21:00' },
    { title: 'INSOMNIA', date: '2024-11-14', city: 'CLUJ', hour: '21:00-23:00' },
    { title: 'IRISH PUB', date: '2024-11-15', city: 'CONSTANȚA', hour: '19:00-21:00' },
    { title: 'BARRELS PUB', date: '2024-11-15', city: 'CONSTANȚA', hour: '21:00-23:00' },
    { title: 'MUSIC PUB', date: '2024-11-21', city: 'SIBIU', hour: '20:00-22:00' },
    { title: 'THE CORNER', date: '2024-11-21', city: 'SIBIU', hour: '22:00-24:00' },
    { title: 'TEMPLE', date: '2024-11-21', city: 'CRAIOVA', hour: '18:00-20:00' },
    { title: 'DOWN TOWN', date: '2024-11-21', city: 'CRAIOVA', hour: '20:00-22:00' },
    { title: 'TRANZIT', date: '2024-11-21', city: 'TÂRGU MUREȘ', hour: '20:00-22:00' },
    { title: 'OFFICE', date: '2024-11-21', city: 'TÂRGU MUREȘ', hour: '22:00-24:00' },
    { title: 'GREEN HOURS', date: '2024-11-21', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'THE DUBLINER IRISH PUB - TITULESCU', date: '2024-11-21', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'DISTRIKT', date: '2024-11-21', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'TRES OLIVOS', date: '2024-11-21', city: 'PLOIEȘTI', hour: '19:00-21:00' },
    { title: 'JAZZ', date: '2024-11-21', city: 'PLOIEȘTI', hour: '21:00-23:00' },
    { title: 'BRUTO', date: '2024-11-21', city: 'CLUJ', hour: '19:00-21:00' },
    { title: 'INSOMNIA', date: '2024-11-21', city: 'CLUJ', hour: '21:00-23:00' },
    { title: 'ENCORE', date: '2024-11-28', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'BARAKA', date: '2024-11-28', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'MOJO', date: '2024-11-28', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'HANUL BERARILOR - CASA SOARE', date: '2024-11-28', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'TOWERS', date: '2024-11-28', city: 'TÂRGOVIȘTE', hour: '21:00-23:00' },
    { title: 'TOWERS 2', date: '2024-11-28', city: 'TÂRGOVIȘTE', hour: '19:00-21:00' },
    { title: 'BERARIUM', date: '2024-11-28', city: 'IAȘI', hour: '19:00-21:00' },
    { title: 'ATELIERUL DE BERE', date: '2024-11-28', city: 'IAȘI', hour: '21:00-23:00' },
    { title: 'LA BOTTEGA BEER & GRILL', date: '2024-11-28', city: 'ARAD', hour: '19:00-21:00' },
    { title: 'CLUB FLEX', date: '2024-11-28', city: 'ARAD', hour: '21:00-23:00' },
    { title: 'BRUTO', date: '2024-11-28', city: 'CLUJ', hour: '19:00-21:00' },
    { title: 'INSOMNIA', date: '2024-11-28', city: 'CLUJ', hour: '21:00-23:00' },
    { title: 'TEMPLE', date: '2024-11-28', city: 'CRAIOVA', hour: '19:00-21:00' },
    { title: 'DOWN TOWN', date: '2024-11-28', city: 'CRAIOVA', hour: '21:00-23:00' },
    { title: 'ONE & ONLY LORDS PUB', date: '2024-11-28', city: 'ORADEA', hour: '19:00-21:00' },
    { title: 'MOSCOVA EXOTICA', date: '2024-11-28', city: 'ORADEA', hour: '21:00-23:00' },
    { title: 'IRISH PUB', date: '2024-11-29', city: 'CONSTANȚA', hour: '19:00-21:00' },
    { title: 'THE LIBRARY', date: '2024-11-29', city: 'CONSTANȚA', hour: '21:00-23:00' },
    { title: 'HANUL SARARIE', date: '2024-12-05', city: 'IAȘI', hour: '19:00-21:00' },
    { title: 'CORSO TERASĂ', date: '2024-12-05', city: 'IAȘI', hour: '21:00-23:00' },
    { title: 'BERĂRIA GAMBRINUS 1', date: '2024-12-05', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'BERĂRIA GAMBRINUS 2', date: '2024-12-05', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'CURTEA BERARILOR - CENTRUL ISTORIC', date: '2024-12-05', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'TERASA CURTEA BERARILOR', date: '2024-12-05', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'EQUINOXE CAFFE', date: '2024-12-05', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'LA POD', date: '2024-12-05', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'ART CAFE DOWNTOWN', date: '2024-12-05', city: 'ALBA IULIA', hour: '19:00-21:00' },
    { title: "FRAM'S", date: '2024-12-05', city: 'ALBA IULIA', hour: '21:00-23:00' },
    { title: 'RIVIERE', date: '2024-12-05', city: 'TIMIȘOARA', hour: '18:00-20:00' },
    { title: 'NORA', date: '2024-12-05', city: 'TIMIȘOARA', hour: '20:00-22:00' },
    { title: 'PAPAO', date: '2024-12-05', city: 'PITEȘTI', hour: '18:00-20:00' },
    { title: 'URBAN HOME', date: '2024-12-05', city: 'PITEȘTI', hour: '20:00-22:00' },
    { title: 'MUSIC PUB', date: '2024-12-05', city: 'SIBIU', hour: '20:00-22:00' },
    { title: 'THE CORNER', date: '2024-12-05', city: 'SIBIU', hour: '22:00-24:00' },
    { title: 'BRUTO', date: '2024-12-05', city: 'CLUJ-NAPOCA', hour: '19:00-21:00' },
    { title: 'INSOMNIA', date: '2024-12-05', city: 'CLUJ-NAPOCA', hour: '21:00-23:00' },
    { title: 'BRUTO', date: '2024-12-12', city: 'CLUJ', hour: '18:00-20:00' },
    { title: 'INSOMNIA', date: '2024-12-12', city: 'CLUJ', hour: '20:00-22:00' },
    { title: 'BERARIUM', date: '2024-12-11', city: 'CRAIOVA', hour: '19:00-21:00' },
    { title: 'ATELIERUL DE BERE', date: '2024-12-11', city: 'CRAIOVA', hour: '21:00-23:00' },
    { title: 'TANKERIA', date: '2024-12-12', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'CROSSFIRE', date: '2024-12-12', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'GREEN HOURS', date: '2024-12-12', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'THE DUBLINER IRISH PUB - TITULESCU', date: '2024-12-12', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: "O'BROTHERS", date: '2024-12-19', city: 'BUZĂU', hour: '18:00-20:00' },
    { title: "SPORT'S PUB", date: '2024-12-19', city: 'BUZĂU', hour: '20:00-22:00' },
    { title: 'PAPAO', date: '2024-12-12', city: 'PITEȘTI', hour: '18:00-20:00' },
    { title: 'URBAN HOME', date: '2024-12-12', city: 'PITEȘTI', hour: '20:00-22:00' },
    { title: 'SZIMPLA', date: '2024-12-12', city: 'SFÂNTU GHEORGHE', hour: '19:00-21:00' },
    { title: 'SEPSIFOOD', date: '2024-12-12', city: 'SFÂNTU GHEORGHE', hour: '21:00-23:00' },
    { title: 'HANUL SARARIE', date: '2024-12-19', city: 'IAȘI', hour: '19:00-21:00' },
    { title: 'CORSO TERASĂ', date: '2024-12-19', city: 'IAȘI', hour: '21:00-23:00' },
    { title: 'ENCORE', date: '2024-12-19', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'BARAKA', date: '2024-12-19', city: 'BUCUREȘTI', hour: '21:00-23:00' },
    { title: 'PAPAO', date: '2025-01-09', city: 'PITEȘTI', hour: '18:00-20:00' },
    { title: 'URBAN HOME', date: '2025-01-09', city: 'PITEȘTI', hour: '20:00-22:00' },
    { title: 'RIBS FOOD', date: '2024-11-14', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: 'HANUL BERARILOR-CASA SOARE 2', date: '2024-11-14', city: 'BUCUREȘTI', hour: '19:00-21:00' },
    { title: "O'BROTHERS", date: '2024-11-14', city: 'BUZĂU', hour: '19:00-21:00' },
    { title: "SPORT'S PUB", date: '2024-11-14', city: 'BUZĂU', hour: '19:00-21:00' },
    { title: 'THE LIBRARY', date: '2024-11-15', city: 'CONSTANȚA', hour: '19:00-21:00' },
    { title: 'SZIMPLA', date: '2024-11-14', city: 'SFÂNTU GHEORGHE', hour: '19:00-21:00' },
    { title: 'NO.1 CAFEE', date: '2024-11-15', city: 'SFÂNTU GHEORGHE', hour: '19:00-21:00' },
    { title: 'CURTEA BERARILOR LA FABRICA', date: '2024-12-19', city: 'TIMIȘOARA', hour: '19:00-21:00' },
    { title: 'CURTEA BERARILOR LA HAN', date: '2024-12-19', city: 'TIMIȘOARA', hour: '21:00-23:00' },
]