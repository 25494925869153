import React from 'react';
import {Grid, Typography} from "@mui/material";
import clsx from "clsx";
import goat_this from "../assets/images/goat-this.gif";
import didYouKnowStyles from "../styles/did_you_know_styles";
import DidYouKnowComponent from "./DidYouKnowComponent";

interface DidYouKnowComponentProps {
    title: React.ReactNode;
    subtitle: React.ReactNode;
}
const DidYouKnowPresentation: React.FC<DidYouKnowComponentProps> = ({title, subtitle}) => {

    const didYouKnowComponentStyles = didYouKnowStyles();

    return (
        <Grid container className={didYouKnowComponentStyles.bgCream}>
            <Grid item xs={12} className={clsx(didYouKnowComponentStyles.section, )}>
                <DidYouKnowComponent title={title}>
                    <Typography className={didYouKnowComponentStyles.title} textAlign={'center'}>{subtitle}</Typography>
                    <img src={goat_this} alt={'kozel'} className={didYouKnowComponentStyles.img}/>
                </DidYouKnowComponent>
            </Grid>
        </Grid>
    )
}

export default DidYouKnowPresentation;