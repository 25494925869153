export interface LocationItemProps {
    city: string,
    name: string,
    isSpecialKozelMeeet: boolean,
    lat: number,
    lng: number,
    zIndex: number,
    [key: string]: any;
}
export const listLocation: LocationItemProps[] = [
    {city:"ALBA IULIA", isSpecialKozelMeeet: false, name: "FRAM'S", lat: 46.070161, lng: 23.57000, zIndex: 100},
    {city:"ALBA IULIA", isSpecialKozelMeeet: false, name: "ART CAFEE DOWNTOWN", lat: 46.069384, lng: 23.578072, zIndex: 100},
    {city:"ARAD", isSpecialKozelMeeet: false, name: "EUPHORIA PUB", lat: 46.173326, lng: 21.318218, zIndex: 100},
    {city:"ARAD", isSpecialKozelMeeet: false, name: "OLD LADY HOOLIGANS S.R.L.", lat: 46.194722, lng: 21.307111, zIndex: 100},
    {city:"CÂMPULUNG", isSpecialKozelMeeet: false, name: "CURTEA VECHE", lat: 45.268387, lng: 25.043301, zIndex: 100},
    {city:"CÂMPULUNG", isSpecialKozelMeeet: false, name: "HANGITA", lat: 45.274972, lng: 25.047639, zIndex: 100},
    {city:"CURTEA DE ARGEȘ", isSpecialKozelMeeet: false, name: "RESTAURANT CURTEA VECHE", lat: 45.142973, lng: 24.676290, zIndex: 100},
    {city:"ORADEA", isSpecialKozelMeeet: false, name: "ONE & ONLY LORDS PUB", lat: 47.054806, lng: 21.929694, zIndex: 100},
    {city:"ORADEA", isSpecialKozelMeeet: false, name: "MOSCOVA EXOTICA", lat: 47.058578, lng: 21.930278, zIndex: 100},
    {city:"ORADEA", isSpecialKozelMeeet: false, name: "CASA PIRAȚILOR", lat: 47.052598, lng: 21.907477, zIndex: 100},
    {city:"BISTRIȚA", isSpecialKozelMeeet: false, name: "LORDINU CENTRAL", lat: 47.132895, lng: 24.49812, zIndex: 100},
    {city:"COLDĂU", isSpecialKozelMeeet: false, name: "SEMCO REGAL", lat: 47.18249, lng: 24.13600, zIndex: 100},
    {city:"BOTOȘANI", isSpecialKozelMeeet: false, name: "PERGOLA FOOD & MORE", lat: 47.744196, lng: 26.658154, zIndex: 100},
    {city:"BOTOȘANI", isSpecialKozelMeeet: false, name: "LA STRADA", lat: 47.745336, lng: 26.661837, zIndex: 100},
    {city:"POIANA BRAȘOV", isSpecialKozelMeeet: false, name: "STÂNA TURISTICĂ", lat: 45.609542, lng: 25.536357, zIndex: 100},
    {city:"BRAȘOV", isSpecialKozelMeeet: false, name: "RESTAURANT SERGIANA - MUREȘENILOR", lat: 45.645236, lng: 25.589829, zIndex: 100},
    {city:"BRAȘOV", isSpecialKozelMeeet: false, name: "CEASU' RĂU", lat: 45.650011, lng: 25.603997, zIndex: 100},
    {city:"REȘIȚA", isSpecialKozelMeeet: false, name: "SARAH NICOLE HOLDING", lat: 45.289395, lng: 21.88790, zIndex: 100},
    {city:"GHERLA", isSpecialKozelMeeet: false, name: "COOP GHERLA GRĂDINA DE VARĂ", lat: 47.031194, lng: 23.90492, zIndex: 100},
    {city:"FLOREȘTI", isSpecialKozelMeeet: false, name: "PIZZA PAZZA", lat: 46.735433, lng: 23.483477, zIndex: 100},
    {city:"CLUJ NAPOCA", isSpecialKozelMeeet: false, name: "INSOMNIA EIDOS", lat: 46.768336, lng: 23.58966, zIndex: 100},
    {city:"CLUJ NAPOCA", isSpecialKozelMeeet: false, name: "BRUTO", lat: 46.777535, lng: 23.60271, zIndex: 100},
    {city:"CLUJ NAPOCA", isSpecialKozelMeeet: false, name: "HARDWARD PUB", lat: 46.757127, lng: 23.5937, zIndex: 100},
    {city:"CLUJ NAPOCA", isSpecialKozelMeeet: false, name: "OLD SCHOOL", lat: 46.773430, lng: 23.589251, zIndex: 100},
    {city:"TÂRGOVIȘTE", isSpecialKozelMeeet: false, name: "HISTORY", lat: 44.927715, lng: 25.458621, zIndex: 100},
    {city:"TÂRGOVIȘTE", isSpecialKozelMeeet: false, name: "AWAN", lat: 44.929067, lng: 25.458916, zIndex: 100},
    {city:"TÂRGOVIȘTE", isSpecialKozelMeeet: false, name: "NABA'H", lat: 44.927688, lng: 25.458094, zIndex: 100},
    {city:"CALAFAT", isSpecialKozelMeeet: false, name: "TERSIDO", lat: 43.988459, lng: 22.926089, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: false, name: "THE TEMPLE BAR", lat: 44.327736, lng: 23.799890, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: false, name: "AMBRA ROVINE", lat: 44.321210, lng: 23.819502, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: false, name: "DOWN TOWN", lat: 44.316732, lng: 23.799183, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: false, name: "SNOBI", lat: 44.304260, lng: 23.798246, zIndex: 100},
    {city:"TÂRGU JIU", isSpecialKozelMeeet: false, name: "AQUA", lat: 45.034059, lng: 23.278091, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: false, name: "EUROMEDICAL PROVIDER (CBT)", lat: 47.163056, lng: 27.58213, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: false, name: "ATELIERUL DE BERE", lat: 47.16029, lng: 27.589635, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: false, name: "CORSO TERASA", lat: 47.167237, lng: 27.578245, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: false, name: "TERASA MAX", lat: 47.150141, lng: 27.574773, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: false, name: "HANU SARARIE", lat: 47.176470, lng: 27.579813, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: false, name: "BERARIUM", lat: 47.158316, lng: 27.589700, zIndex: 100},
    {city:"DROBETA-TURNU SEVERIN", isSpecialKozelMeeet: false, name: "CPUB", lat: 44.637212, lng: 22.648470, zIndex: 100},
    {city:"BAIA MARE", isSpecialKozelMeeet: false, name: "PODUL VIILOR 9", lat: 47.660885, lng: 23.58028, zIndex: 100},
    {city:"BAIA MARE", isSpecialKozelMeeet: false, name: "LA TOUR", lat: 47.658605, lng: 23.581035, zIndex: 100},
    {city:"BORȘA", isSpecialKozelMeeet: false, name: "DARK DISH & DRAFT", lat: 47.655434, lng: 24.664620, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: false, name: "OFFICE CLUB", lat: 46.543182, lng: 24.564177, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: false, name: "TRANZIT", lat: 46.545191, lng: 24.556737, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: false, name: "DOME", lat: 46.547035, lng: 24.56404, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: false, name: "CASA BERII", lat: 46.552214, lng: 24.557047, zIndex: 100},
    {city:"PIATRA NEAMȚ", isSpecialKozelMeeet: false, name: "LA CURTEA DOMNEASCĂ", lat: 46.934213, lng: 26.368274, zIndex: 100},
    {city:"SLATINA", isSpecialKozelMeeet: false, name: "MEMPHYS PUB", lat: 44.425933, lng: 24.377339, zIndex: 100},
    {city:"SINAIA", isSpecialKozelMeeet: false, name: "BASTION", lat: 45.358006, lng: 25.547538, zIndex: 100},
    {city:"SINAIA", isSpecialKozelMeeet: false, name: "WOOD", lat: 45.352343, lng:  25.549795, zIndex: 100},
    {city:"PLOIEȘTI", isSpecialKozelMeeet: false, name: "CURTEA BERARILOR TIMISOREANA", lat: 44.941004, lng: 26.028227, zIndex: 100},
    {city:"SIBIU", isSpecialKozelMeeet: false, name: "CHEERS PUB", lat: 45.787038, lng: 24.14444, zIndex: 100},
    {city:"SIBIU", isSpecialKozelMeeet: false, name: "MUSIC PUB", lat: 45.798741, lng: 24.151824, zIndex: 100},
    {city:"SIBIU", isSpecialKozelMeeet: false, name: "THE CORNER", lat: 45.791753, lng: 24.139148, zIndex: 100},
    {city:"SATU MARE", isSpecialKozelMeeet: false, name: "FRIEND' S CAFE", lat: 47.788200, lng: 22.875936, zIndex: 100},
    {city:"SATU MARE", isSpecialKozelMeeet: false, name: "THE BULL", lat: 47.795360, lng: 22.872938, zIndex: 100},
    {city:"HALMEU", isSpecialKozelMeeet: false, name: "KO&VAR", lat: 47.680401, lng: 22.469918, zIndex: 100},
    {city:"FĂLTICENI", isSpecialKozelMeeet: false, name: "IMPERIAL GRILL&MUSIC", lat: 47.464747, lng: 26.299287, zIndex: 100},
    {city:"FĂLTICENI", isSpecialKozelMeeet: false, name: "IMPERIAL FALEZĂ", lat: 47.462967, lng: 26.301282, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: false, name: "RIVIERE MP RIVERSIDE", lat: 45.748798, lng: 21.22699, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: false, name: "STIL-BERERECE", lat: 45.761451, lng: 21.248916, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: false, name: "PORTO ARTE", lat: 45.750174, lng: 21.220525, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: false, name: "CURTEA BERARILOR", lat: 45.756015, lng: 21.231795, zIndex: 100},
    {city:"BACĂU", isSpecialKozelMeeet: false, name: "VALHALLA", lat: 46.563692, lng: 26.913570, zIndex: 100},
    {city:"MIERCUREA CIUC", isSpecialKozelMeeet: false, name: "OLOMMADAR", lat: 46.361610, lng: 25.806523, zIndex: 100},
    {city:"ODORHEIUL SECUIESC", isSpecialKozelMeeet: false, name: "JAZZ-FACTORY", lat: 46.310756, lng: 25.304459, zIndex: 100},
    {city:"SFÂNTU GHEORGHE", isSpecialKozelMeeet: false, name: "SZIMPLA", lat: 45.864760, lng: 25.791046, zIndex: 100},
    {city:"SFÂNTU GHEORGHE", isSpecialKozelMeeet: false, name: "SEPSIFOOD", lat: 45.879417, lng: 25.801995, zIndex: 100},
    {city:"SFÂNTU GHEORGHE", isSpecialKozelMeeet: false, name: "NO.1 CAFEE", lat: 45.866413, lng: 25.788176, zIndex: 100},
    {city:"SFÂNTU GHEORGHE", isSpecialKozelMeeet: false, name: "REXO CAFFE", lat: 45.858125, lng: 25.79648, zIndex: 100},
    {city:"BUZĂU", isSpecialKozelMeeet: false, name: "CASA BERARILOR", lat: 45.151706, lng: 26.813505, zIndex: 100},
    {city:"GALAȚI", isSpecialKozelMeeet: false, name: "FREDDY'S BAR & GRILL", lat: 45.452760, lng: 28.050612, zIndex: 100},
    {city:"BRĂILA", isSpecialKozelMeeet: false, name: "CRAMA VECHE", lat: 45.271488, lng: 27.970230, zIndex: 100},
    {city:"TULCEA", isSpecialKozelMeeet: false, name: "PIZZERIE ROYAL", lat: 45.166203, lng: 28.795886, zIndex: 100},
    {city:"FOCȘANI", isSpecialKozelMeeet: false, name: "STREET 102 GOURMET", lat: 45.689674, lng: 27.194499, zIndex: 100},
    {city:"RÂMNICU VÂLCEA", isSpecialKozelMeeet: false, name: "LA TRATTORIA", lat: 45.08444, lng: 24.36792, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "MON PARIS", lat: 44.446282, lng: 26.105823, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "GREEN HOURS", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "HANUL BERARILOR-BERARIA GERMANA", lat: 44.421083, lng: 26.086403, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "HANUL BERARILOR-CASA SOARE 2", lat: 44.428587, lng: 26.095471, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "HANUL BERARILOR-ELENA LUPESCU 2", lat: 44.439930, lng: 26.123824, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "TAVERNA LA JAR by BDI", lat: 44.469392, lng: 26.145018, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "TRATTORIA RICCIO", lat: 44.424834, lng: 26.04505, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "LA STRADA", lat: 44.425705, lng: 26.145034, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "CIOTOIANU DRUMUL TABEREI", lat: 44.418785, lng: 26.030697, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "FLAMINGO", lat: 44.417212, lng: 26.02910, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "GREEN BISTRO", lat: 44.430671, lng: 26.150378, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "RESTAURANT DRISTOR", lat: 44.420263, lng: 26.139734, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "CHAMPIONS BAR ", lat: 44.412747, lng: 26.14156, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "VERDETEI 5", lat: 44.427531, lng: 26.123628, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "CITY GRILL - COVACI 2", lat: 44.430412, lng: 26.10263, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "CURTEA BERARILOR", lat: 44.430877, lng: 26.100714, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "CEAUN", lat: 44.460441, lng: 26.11970, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "ROCK SIDE", lat: 44.424046, lng: 26.042159, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "LA MARIA SI ION", lat: 44.445040, lng: 26.055598, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "TRATTORIA 20", lat: 44.445462, lng: 26.054424, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "VICTORIA HUB", lat: 44.44967, lng: 26.0884, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: false, name: "TAVERNA SARBULUI SRL", lat: 44.482944, lng: 26.067534, zIndex: 100},
]
    