import React from 'react';
import {Grid, Typography} from "@mui/material";
import logoKozelMeeet from '../../assets/images/kozel_meet/meet_logo.png';
import useFirstScreenStyles from "../../styles/kozelMeetBoardGame/first_screen";
import TeaserBeMember from "./firstScreen/TeaserBeMember";
import {useAppSelector} from "../../stores/store";
import {paths} from "../../AppRouters";
import {NavLink} from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import clsx from "clsx";

const FirstScreen: React.FC = () => {
    const styles = useFirstScreenStyles();
    const {isAuthenticated} = useAppSelector(state => state.auth);
    return (
        <Grid container
              flexDirection={'column'}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"center"}
              className={clsx(styles.bgDarkChoco,styles.container)}
        >
            <Grid item xs={10} lg={4} mt={2} textAlign={'center'}>
                <img src={logoKozelMeeet} alt={'Logo Kozel meeet'} className={styles.logoKozelMeet} style={{ maxWidth: '430px', maxHeight: '235px'}}/>
            </Grid>
            <Grid item xs={10} lg={4} mt={2}>
                <Typography className={styles.subtitle}>Întotdeauna e loc pentru
                    încă un <span className={styles.colorDesertSandGold}>prieten!</span></Typography>
            </Grid>
            <Grid item xs={12} lg={10} mt={4} mb={5}>
                <TeaserBeMember/>
            </Grid>
            {!isAuthenticated && (
                <>
                    <Grid item xs={12} lg={6}>
                        <Typography className={styles.subtitle2}>TE INVIT SĂ FII PARTE DIN COMUNITATEA KOZEL MEEET ȘI SĂ CELEBREZI FIECARE ÎNTÂLNIRE
                            CU GRUPUL TĂU, CU BEREA ȚAPĂNĂ DIN CEHIA!</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} mt={5}>
                        <NavLink to={paths.register} className={styles.button}>Devin membru</NavLink>
                    </Grid>
                    <Grid item xs={12} lg={8} mt={5}>
                        <Typography className={styles.textBenefits}><InfoIcon className={styles.contentTeaserListItemIcon}/>înscrierea în comunitate îți aduce automat 50 de puncte</Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default FirstScreen;
