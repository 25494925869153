import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useGameChallengeUntoldHistory = createUseStyles((theme: Theme) => {
    return {
        container: {
            justifyContent: 'center',
            alignContent: "center",
            alignItems: 'center',
            gap: theme.spacing(3),
        },
        item: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: "center",
            alignItems: 'center',
        },
        containerTotem: {
            flexWrap: "nowrap",
            padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
            paddingBottom: theme.spacing(5),
            gap: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
                paddingBottom: theme.spacing(3),
                gap: theme.spacing(2),
            }
        },
        itemTotem: {

        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vh,5vw)',
            color: theme.colors.latte_cream,
            textAlign: 'center',
        },
        subtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(3vh,3vw)',
            color: theme.colors.latte_cream,
            textAlign: 'center',
        },
        img: {
            width: '100%',
            maxWidth: '500px',
        },

    }
})

export default useGameChallengeUntoldHistory;