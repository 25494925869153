import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse, ApiSuccessResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';

export interface MyGroupMember {
    member_id: number | null,
    nick_name: string | null
}

export interface JoinGroupPayload {
    referralCode: string,
}

interface MyGroupState {
    id: number | null,
    name: string | null,
    referral_code: string | null,
    is_owner: boolean | null,
    members: MyGroupMember[],
    loading: boolean,
    errors: string,
    errorsJoin: string,
}

const initialState: MyGroupState = {
    id: null,
    name: null,
    referral_code: null,
    is_owner: null,
    members: [],
    loading: false,
    errors: '',
    errorsJoin: '',
}

export const getMyGroup = createAsyncThunk<
    ApiSuccessResponse,
    void,
    { rejectValue: ApiFailResponse }
>(
    'profile/my_group',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get(apiEndpoints.contactGetGroup);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

export const joinContactGroup = createAsyncThunk<
    ApiSuccessResponse,
    JoinGroupPayload,
    { rejectValue: ApiFailResponse }
>(
    'profile/join_group',
    async (payload: JoinGroupPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post(apiEndpoints.contactJoinGroup, payload);
            thunkAPI.dispatch(getMyGroup());
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

export const createMyGroup = createAsyncThunk(
    'profile/create_group',
    async (payload: { name: string }, thunkAPI) => {
        try {
            const response = await apiInstance.post(apiEndpoints.contactGroupCreate, payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

const myGroupSlice = createSlice({
    name: "profile/my_group",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getMyGroup.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMyGroup.fulfilled, (state, action) => {
                state.loading = false;
                state.id = action.payload.data.group_id
                state.name = action.payload.data.group_name
                state.referral_code = action.payload.data.group_referral_code
                state.is_owner = action.payload.data.is_owner
                state.members = action.payload.data.group_member_details
            })
            .addCase(getMyGroup.rejected, (state, action: any) => {
                state.loading = false;
                state.errors = action.payload.message
            })
            .addCase(createMyGroup.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createMyGroup.fulfilled, (state, action) => {
                state.loading = false;
                state.id = action.payload.data.group_id
                state.name = action.payload.data.group_name
                state.referral_code = action.payload.data.group_referral_code
                state.is_owner = action.payload.data.is_owner
                state.members = action.payload.data.group_member_details
            })
            .addCase(createMyGroup.rejected, (state, action: any) => {
                state.loading = false;
                state.errors = action.payload.message
            })
            .addCase(joinContactGroup.pending, (state) => {
                state.loading = true;
            })
            .addCase(joinContactGroup.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(joinContactGroup.rejected, (state, action) => {
                state.loading = false;
                state.errorsJoin = action.payload?.message || ''
            })

        ;
    }
});

// export const {} = profileSlice.actions
export default myGroupSlice.reducer;