// theme.ts
import { createTheme } from '@mui/material/styles';

const colors = {
    latte_cream: '#E3CB9F',
    espresso_brown: '#40301E',
    desert_sand_gold: '#D7A461',
    dark_chocolate: '#413121',
    golden_mustard: '#D1A85E',
    ivory_beige: '#E5DECD',
};

const theme = createTheme({
    typography: {
        fontFamily: 'ApexSerif, Arial, sans-serif',
    },
    fonts: {
        apex: 'ApexSerif, Arial, sans-serif',
        poplar: 'PoplarKozel, Arial, sans-serif',
        neonLight: 'NeonLight, sans-serif',
    },
    colors: colors,
    palette: {
        primary: {
            main: colors.dark_chocolate,
            light: '#ffffff',
        },
        secondary: {
            main: colors.golden_mustard,
            light: colors.ivory_beige,
        },
        background: {
            default: colors.ivory_beige,
            paper: colors.espresso_brown,
        },
    },
    spacing: 8,
});

declare module '@mui/material/styles' {
    interface Theme {
        fonts: {
            apex: string;
            poplar: string;
            neonLight: string;
        };
        colors: {
            latte_cream: string;
            espresso_brown: string;
            desert_sand_gold: string;
            dark_chocolate: string;
            golden_mustard: string;
            ivory_beige: string;
        };
    }

    interface ThemeOptions {
        fonts?: {
            apex?: string;
            poplar?: string;
            neonLight?: string;
        };
        colors?: {
            latte_cream?: string;
            espresso_brown?: string;
            desert_sand_gold?: string;
            dark_chocolate?: string;
            golden_mustard?: string;
            ivory_beige?: string;
        };
    }
}

export type Theme = typeof theme;

export default theme;
