import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const usePopupComponentStyles = createUseStyles((theme: Theme) => {
    return {
        wrapperContainer: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '85vw',
            width: 'auto',
            boxShadow: 24,
            minWidth: '350px',
        },
        wrapperContainerItem: {

        },
        container: {
            backgroundColor: theme.colors.latte_cream,
            borderWidth: '5px',
            borderStyle: "dotted",
            borderColor: theme.colors.desert_sand_gold,
            position: "relative",
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: "center",
            borderRadius: '25px',
        },
        containerItem: {
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
            }
        },
        closeButton: {
            position: 'absolute',
            right: 'min(5%,2vw)',
            top: 'min(5%,2vh)',
            zIndex: 100,
            color: theme.colors.dark_chocolate,
            borderRadius: '100%',
            padding: '0',
            opacity: 0.6,
            cursor: 'pointer',
            '&:hover': {
                opacity: 1,
            }
        }
    }
})

export default usePopupComponentStyles;