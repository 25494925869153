import React from "react";
import { useNavigate } from "react-router-dom";
import {Grid, Typography} from "@mui/material";
import useBannerKozelMeetBoardGameStyle from "../../styles/kozelMeetBoardGame/banner_kozel_meet_board_game";
import logo_late_cream from "../../assets/images/logo_late_cream.png";
// import logo_vocea_romaniei from "../../assets/images/vocea_romaniei/logo_vocea_romaniei.png";
import banner_kozel_meet from "../../assets/images/kozel_meet/board_game/banner homepage-kozel meeet.png";
import clsx from "clsx";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";

const BannerKozelMeetBoardGame: React.FC = () => {
    const styles = useBannerKozelMeetBoardGameStyle();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(paths.kozelMeet);
    }

    return (
        <Grid container>
            <Grid item xs={12} className={clsx(styles.item, styles.bg_vocea_romaniei)} onClick={handleClick}>
                {/* <Grid container
                      className={clsx(styles.boxBannerVoceaRomaniei)}>
                    <Grid item xs={12} lg={5} className={styles.bannerWrapperItem}>
                        <Grid container className={styles.bannerContainer}>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <img src={logo_late_cream} alt={'logo'} className={styles.bannerLogo}/>
                                <Typography className={styles.bannerTitle}>
                                    te trimite la
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <img src={banner_kozel_meet} alt={'logo banner_kozel_meet'}
                                     className={styles.bannerLogoVoiceRo}/>
                            </Grid>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <Typography className={styles.bannerText}>Înscrie-te în tragerea la sorți
                                    pentru biletele la semifinala și finala Vocea României!</Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <NavLink to={paths.voceaRomaniei} className={styles.bannerButton}>MĂ ÎNSCRIU</NavLink>
                            </Grid>
                        </Grid>
                    </Grid>                  
                </Grid> */}
            </Grid>
        </Grid>
    );
}
export default BannerKozelMeetBoardGame;