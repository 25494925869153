import React, {useCallback, useState, useEffect} from 'react';
import {listLocation} from '../../components/locations/selfImplementedLocations';
import { GoogleMapLocation, GoogleMapComponent } from '../../components/GoogleMapComponent';

const KozelMeetSelfImplementedLocations: React.FC = () => {
    const [locations, setLocations] = useState<GoogleMapLocation[]>([]);
    
    useEffect(() => {
        setLocations(listLocation);
    }, []);

    return  (
        <>
            <GoogleMapComponent locations={locations}/>
        </>
    );
};

export default KozelMeetSelfImplementedLocations;
