import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppSelector} from "../stores/store";
import {paths} from "../AppRouters";

export const joinGroupKey: string = 'group_code';
const RedirectJoinGroupComponent = () => {
    const {isAuthenticated} = useAppSelector((state) => state.auth);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const joinCode = searchParams.get(joinGroupKey);

    React.useEffect(() => {
        if (!joinCode) {
            navigate(paths.home);
        }
        if (joinCode) {
            if (isAuthenticated) {
                navigate(`/grupul-meu?${joinGroupKey}=${joinCode}`);
            } else {
                navigate(`/register?${joinGroupKey}=${joinCode}`);
            }
        }
    }, [isAuthenticated, joinCode, navigate]);

    return null;
};

export default RedirectJoinGroupComponent;
