import React from 'react';
import {Grid} from "@mui/material";
import FirstScreen from "../components/kozelMeetBoardGame/FirstScreen";
import FormRequestAccess from "../components/kozelMeetBoardGameAccess/FormRequestAccess";
import JoinUs from "../components/kozelMeetBoardGameAccess/JoinUs";
import PromoKozelEvents from "../components/kozelMeetBoardGameAccess/PromoKozelEvents";
import {useAppSelector} from "../stores/store";
import CodeAccessLp from "../components/kozelMeetBoardGameAccess/CodeAccessLp";


const BoardGameAccess: React.FC = (props) => {
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const {success} = useAppSelector(state => state.kozelMeetBoardGame);

    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <FirstScreen/> */}
                {success ? <CodeAccessLp/> : <FormRequestAccess/>}
                {!isAuthenticated && <JoinUs/>}
                <PromoKozelEvents/>
            </Grid>
        </Grid>
    );
}

export default BoardGameAccess;
