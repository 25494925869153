import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import {alpha} from '@mui/material';
import bg_landscape from '../../assets/images/vocea_romaniei/bg_landscape.jpg';
import bg_portrait from '../../assets/images/vocea_romaniei/bg_portrait.jpg';
import bg_square from '../../assets/images/vocea_romaniei/bg_square.jpg';


const useVoceaRomanieiStyles = createUseStyles((theme: Theme) => {
    return {
        containerWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            backgroundColor: theme.colors.dark_chocolate,
        },
        containerItemWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        bg_vocea_romaniei: {
            marginTop: theme.spacing(10),
            backgroundImage: `url("${bg_landscape}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            '@media (orientation: landscape)': {
                backgroundImage: `url("${bg_landscape}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },

            '@media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1.5/1)': {
                backgroundImage: `url("${bg_square}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },

            '@media (orientation: portrait)': {
                backgroundImage: `url("${bg_portrait}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },
        },
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        item: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        logo: {
            width: 'auto',
            height: '65px',
            maxWidth: '2000px',
            maxHeight: '600px',
            [theme.breakpoints.down('lg')]: {
                height: '60px',
            }
        },
        title: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(4vw, 4vh)',
            marginLeft: theme.spacing(2),
        },
        logo_vocea_romaniei: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: '100%',
            height: 'auto',
            maxWidth: '470px',
            maxHeight: '230px',
            [theme.breakpoints.down('lg')]: {
                width: '80vw',
            }
        },
        text: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vw, 2vh)',
            fontWeight: 'bold',
            textAlign: "center",
            padding: `0 ${theme.spacing(3)}`,
        },
        button: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
            backgroundColor: theme.colors.latte_cream,
            fontFamily: theme.fonts.poplar,
            letterSpacing: '1px',
            color: theme.colors.dark_chocolate,
            fontSize: 'max(2.5vw, 2.5vh)',
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            }
        },
        teaser_img: {
            maxWidth: '1200px',
            maxHeight: '990px',
            width: '50vw',
            height: 'auto',
            [theme.breakpoints.down('lg')]: {
                width: '80vw',
            }
        },
        link_rules: {
            marginTop: theme.spacing(2),
            fontFamily: theme.fonts.apex,
            textTransform: 'uppercase',
            fontSize: 'max(2vh,2vw)',
            color: theme.colors.latte_cream,
            textDecoration: 'underline',
        },
        paddingTop: {
            paddingTop: theme.spacing(5)
        },
        boxCampaignDescription: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(8),
            flexDirection: 'column',
        },
        campaignDescription: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.latte_cream,
        },
        borderTopDotted: {
            borderTopWidth: '15px',
            borderTopColor: theme.colors.desert_sand_gold,
            borderTopStyle: 'dotted',
            paddingTop: theme.spacing(5),
        },
        logoKozel: {
            maxWidth: '350px',
            maxHeight: '350px',
            width: '35vw',
            height: 'auto',
        },
        title2: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vh,3vw)',
            color: theme.colors.latte_cream,
        },
        keepEyesOnUsText: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.latte_cream,
        },
        inviteFriendsText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.latte_cream,
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(8),
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            }
        },
        link: {
            textDecoration: 'underline',
            color: theme.colors.latte_cream,
        },
        boxTitleBorderDotted: {
            padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
            borderStyle: 'dotted',
            borderWidth: '10px',
            borderColor: theme.colors.latte_cream,
            textAlign: 'center',
            marginBottom: theme.spacing(7),
            backgroundColor: alpha(theme.colors.dark_chocolate, 0.85),
            borderRadius: '10px',
            [theme.breakpoints.down("lg")]: {
                padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
            }
        },
        textWaitCampaign: {
            fontFamily: theme.fonts.poplar,
            letterSpacing: '1px',
            textTransform: 'uppercase',
            fontSize: 'max(3vh,3vw)',
            color: theme.colors.latte_cream,
        },
        formBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(3),
        },
        formInput: {
            marginTop: theme.spacing(3),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        successBox: {
            padding: theme.spacing(8),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(3),
            }
        },
        successSubscribe: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vw,5vh)',
            color: theme.colors.latte_cream,
        },
        confirmSubscribe: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vw,2.5vh)',
            color: theme.colors.latte_cream,
            marginTop: theme.spacing(3),
        },
        inviteBox: {
            padding: theme.spacing(3),
            backgroundColor: alpha(theme.colors.dark_chocolate, 0.85),
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(3),
            }
        },
        leftBoxInviteAccount: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'flex-start',
            paddingLeft: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(0),
            }
        },
        rightBoxInviteAccount: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(3),
            }
        },
        imgKozelVoiceRo: {
            width: '85%',
            height: 'auto',
        },
        inviteTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vw,2.5vh)',
            color: theme.colors.latte_cream,
            textAlign: 'left',
        },
        inviteText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.colors.latte_cream,
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            textAlign: 'left',
        },
        inviteButton: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            backgroundColor: theme.colors.latte_cream,
            color: theme.colors.dark_chocolate,
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            }
        },
        starText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.2vw,1.2vh)',
            color: theme.colors.latte_cream,
            marginTop: theme.spacing(3),
            textAlign: 'left',
        },
        formControlCheckbox:{
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '1px',
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
            '& p': {
                textAlign: "left",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'normal',
            },
        },
        formControlCheckboxLabel:{
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '1px',
            color: theme.colors.latte_cream,
            '& a':{
                color: 'inherit',
                textDecoration: 'underline',
                textTransform: 'uppercase',
                fontStyle: 'italic',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: 1,
            },
        },
    }
});

export default useVoceaRomanieiStyles;