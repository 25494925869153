import React from 'react';
import {Grid, Typography} from "@mui/material";
import useTeaserKozelLocationsStyles from "../../styles/kozelMeetBoardGame/teaser_kozel_locations";
import {paths} from "../../AppRouters";
import {NavLink} from "react-router-dom";
import TeaserFrame2 from "../TeaserFrame2";
import meet_logo from '../../assets/images/kozel_meet/meet_logo.png';
import map_ro_kozel_pin from '../../assets/images/kozel_meet/ma_ro_kozel_pin.png';
// import banner_locations from '../../assets/images/kozel_meet/banner_locations.png';
import banner_locations from '../../assets/images/kozel_meet/tzap.jpg';

const TeaserKozelLocations: React.FC = () => {
    const styles = useTeaserKozelLocationsStyles();

    return (
        <Grid container flexDirection={"column"} justifyContent={"center"} alignItems={"center"} alignContent={"center"} className={styles.container}>
            <Grid item xs={12} className={styles.boxBorderWithCenterTitle}>
                <Typography className={styles.centerTopTitle}>INTRODUCING</Typography>
            </Grid>
            <Grid item xs={10} lg={4} display={"flex"} justifyContent={"center"} mt={5}>
                <img src={meet_logo} alt={'MEEET KOZEL LOGO'} className={styles.logoKozelMeet} style={{ maxWidth: '425px',maxHeight: '240px'}}/>
            </Grid>
            <Grid item xs={12} lg={8} className={styles.boxTeaser} mt={5}>
                <TeaserFrame2 title={''} >
                    <Grid container justifyContent={"center"} alignItems={"center"} alignContent={"center"}>
                        <Grid item xs={12} lg={6} className={styles.boxLeft}>
                            <Typography className={styles.textTeaser}>
                                Joia este despre bere,
                                prieteni și jocuri,
                                în locațiile Kozel din țară!
                            </Typography>
                            <NavLink to={paths.kozelMeetSelfImplementedLocations} className={styles.button}>VEZI LOCAȚIILE</NavLink>
                        </Grid>
                        <Grid item xs={12} lg={6} className={styles.boxRight}>
                            <img src={map_ro_kozel_pin} alt={'Img Map Ro Kozel Pin'} width={'100%'}/>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
            <Grid item xs={12} lg={8} pt={5} display={"flex"} flexDirection={"column"} gap={5}>
                <Typography className={styles.text}>În locaţiile Kozel, tot timpul se întâmplă ceva nou – oameni noi, povești noi, glume noi și noi ocazii de distracţie împreună cu grupul tău!</Typography>
                <Typography className={styles.text}>Pe lângă mixul &#354;APăn, fiecare locaţie e echipată cu numeroase jocuri și provocări de grup care vor face ieșirile la bere și mai savuroase.</Typography>
            </Grid>
            <Grid item xs={12} lg={8} pt={5} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"center"} pb={5} pl={1} pr={1}>
                <img src={banner_locations} alt={'Poza te asteptam'} className={styles.banner}/>
            </Grid>
            <Grid item xs={12} lg={8} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"center"} pb={5}>
                <Typography className={styles.text}>În anumite zile de Joi speciale, organizăm evenimente ȚAPene în locațiile Kozel din țară!</Typography>
            </Grid>
            <Grid item xs={12} lg={8} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"center"} pb={5}>
                <Typography className={styles.text}>Pe lângă jocurile cool pe care le găsești în fiecare joi în locațiile noastre, în anumite zile de Joi te așteptăm cu și mai multă distracție și provocări - experiența Kozel Escape Box și alte surprize! See you there!</Typography>
            </Grid>
            <Grid item xs={12} lg={12} display={"flex"} alignItems={"center"} alignContent={"center"} justifyContent={"center"} pb={5}>
                <NavLink to={paths.kozelMeetSpecialLocations} className={styles.buttonLarger}>DESCOPERĂ LOCAȚIILE SPECIALE</NavLink>
            </Grid>

        </Grid>
    );
}

export default TeaserKozelLocations;
