import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Navbar from './components/Navbar';
import ErrorBoundary from "./components/ErrorBoundary";

import {useAppDispatch, useAppSelector} from './stores/store';
import {validateToken} from './features/auth/authSlice';
import {SnackbarProvider, useSnackbar} from "notistack";
import AgeGate from "./components/AgeGate";
import AppRouters from "./AppRouters";
import {clearNotifications} from "./features/notification/notificationSlice";
import {setSnackbar} from "./utils/notificationManager";
import AppModals from "./AppModals";
import useGlobalStyles from './styles/global';
import useCookiesStyles from './styles/cookie';
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import {initGA, logPageView} from "./analytics";
import LoadingComponent from "./components/kozel/LoadingComponent";

const SnackbarInitializer = ({children}: { children: React.ReactNode }) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    setSnackbar(enqueueSnackbar, closeSnackbar);
    return <>{children}</>;
};
const cookieConsentName= 'kozelCookieConsent';
const App: React.FC = () => {
    useGlobalStyles();
    const cookiesStyle = useCookiesStyles();

    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const [keepItAge, setKeepItAge] = useState(
        Boolean(localStorage.getItem('keepItAge')) || false
    );

    const [authLoading, setAuthLoading] = useState(true);
    const storageSave = keepItAge ? localStorage : sessionStorage;
    const [age, setAge] = useState(parseInt(storageSave.getItem('age') || '0'));
    const [expireSetAge, setExpireSetAge] = useState(storageSave.getItem('expire_set_age'));
    const handleAgeChecker = (age: number, keepIt: boolean) => {
        if (keepIt) {
            setKeepItAge(true);
            localStorage.setItem('keepItAge', Boolean(keepIt).toString());
        }
        setAge(age);
        storageSave.setItem('age', age.toString());

        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + parseInt(process.env.REACT_APP_AGEGATE_EXPIRATION_SECONDS || '3600'));
        storageSave.setItem('expire_set_age', expirationDate.toISOString());
        setExpireSetAge(expirationDate.toISOString());
    };

    useEffect(() => {
        if (getCookieConsentValue(cookieConsentName) === "true") {
            initGA();
            logPageView();
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentDate = new Date();
            const expirationDate = new Date(expireSetAge || '');

            if (currentDate > expirationDate) {
                setAge(0);
                storageSave.removeItem('age');
                storageSave.removeItem('expire_set_age');
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [expireSetAge,storageSave]);

    useEffect(() => {
        if (age >= 18) {
            dispatch(validateToken()).finally(() => {
                setAuthLoading(false);
            });
        } else {
            setAuthLoading(false);
        }
    }, [age, dispatch]);

    if (authLoading) return <LoadingComponent/>;

    return (
        <>
            {getCookieConsentValue(cookieConsentName) !== "true" && (<CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName={cookieConsentName}
                containerClasses={cookiesStyle.consentBanner}
                buttonClasses={cookiesStyle.consentButton}
                declineButtonClasses={cookiesStyle.declineButton}
                cookieValue={getCookieConsentValue(cookieConsentName)}
                expires={180}
                debug={true}
                onAccept={() => {
                    initGA();
                    logPageView();
                }}
            >
                <div className={cookiesStyle.consentText}>
                    Acest site foloseste cookies pentru a imbunatatii experienta utilizatorilor.
                </div>
            </CookieConsent>)
            }
            <SnackbarProvider maxSnack={3} onClose={e => {
                dispatch(clearNotifications())
            }}>
                <SnackbarInitializer>
                    {age < 18 ?
                        <AgeGate onAgeSubmit={handleAgeChecker}/> :
                        <Router>
                            <ScrollToTop/>
                            <ErrorBoundary>
                                <Navbar/>
                                <AppModals/>
                                <AppRouters isAuthenticated={isAuthenticated}/>
                                <Footer/>
                            </ErrorBoundary>
                        </Router>
                    }
                </SnackbarInitializer>
            </SnackbarProvider>
        </>
    );
};

export default App;
