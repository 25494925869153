import {createUseStyles} from "react-jss";
import {Theme} from "./themes/default";


const useRegulamenteStyles = createUseStyles((theme: Theme) => {
        return {
            title: {
                fontFamily: theme.fonts.poplar,
                color: theme.colors.dark_chocolate,
                fontSize: 'max(6vw,6vh)',
                textAlign: 'center',
                padding: theme.spacing(10),
                [theme.breakpoints.down('lg')]: {
                    fontSize: 'max(4vw,4vh)',
                    padding: theme.spacing(2),
                }
            },
            wrapperAccordion: {
                padding: theme.spacing(4),
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(2),
                }
            },
            accordionSummary: {
                background: theme.palette.background.default,
                '& .MuiAccordionSummary-content': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(2vh,2vw)',
                    fontWeight: "bold",
                    justifyContent: 'center',
                }
            },
            accordionDetails: {
                background: theme.palette.background.paper,
            },
            list: {
                '& .MuiListItemText-root span': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(1.5vh,1.5vw)',
                    color: theme.colors.latte_cream,
                }
            }

        }
    }
);


export default useRegulamenteStyles;