import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useListInfoStyles = createUseStyles((theme: Theme) => {
    return {
        contentTeaserListItemIcon: {
            width: 'max(3vh,3vw)',
            height: 'max(3vh,3vw)',
            marginRight: theme.spacing(3),
        },
        contentTeaserList: {
            fontFamily: theme.fonts.poplar,
        },
        contentTeaserListItem: {

            alignItems: 'start',
            padding: 0,
            '& .MuiListItemText-root': {
                '& span': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(1.5vh,1.5vw)',
                    color: theme.palette.primary.main,
                    margin: 0,
                    [theme.breakpoints.down('lg')]: {
                        fontSize: 'max(1.5vh,1.5vw)',
                    },
                },
                '&.contrast': {
                    '& span': {
                        color: theme.colors.latte_cream,
                    }
                },
            },
            '& .MuiListItemIcon-root': {
                height: 'calc(max(1.5vh,1.5vw)*1.5)',
                marginTop: '4px',
                marginBottom: '4px',
                [theme.breakpoints.down('lg')]: {
                    minWidth: '15px',
                },
                '& img': {
                    maxHeight: '20px',
                    width: 'auto',
                    height: '100%',
                    marginTop: '10px',
                    marginBottom: '10px',
                    paddingRight: '4px',
                    [theme.breakpoints.down('lg')]: {
                        width: 'auto',
                        marginTop: '0',
                        marginBottom: '0',
                        height: '100%',
                    },
                }
            }
        },
    }
})

export default useListInfoStyles;