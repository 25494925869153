import React from "react";
import {Grid, Typography} from "@mui/material";
import useMainStyles from "../../styles/ncp/promotion_profi_october/main";
import FirstScreen from "./promoProfiOctober/FirstScreen";
import Step from "./Step";
import FormSection from "./promoProfiOctober/FormSection";

const PromotionProfiOctober: React.FC = () => {
    const styles= useMainStyles()
    return (
        <Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FirstScreen/>
                </Grid>
                <Grid item xs={12} className={styles.titleBox}>
                    <Typography className={styles.title}>Kozel și Profi te premiază!</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={styles.subTitle}>14 - 27 octombrie 2024</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={styles.stepsWrapper}>
                        <Grid item md={3} xs={12} className={styles.wrapperStep}>
                            <Step index={1}>Cumpără bere Kozel în orice tip de ambalaj, de minim 25 lei pe un singur bon fiscal</Step>
                        </Grid>
                        <Grid item md={3} xs={12} className={styles.wrapperStep}>
                            <Step index={2}> Înscrie numărul de bon fiscal pe&nbsp;
                                <a href="https://www.kozel.ro" rel="noreferrer" target="_blank">kozel.ro/promotii</a>
                                &nbsp;în perioada 14-27 Octombrie 2024</Step>
                        </Grid>
                        <Grid item md={3} xs={12} className={styles.wrapperStep}>
                            <Step index={3}>Păstrează bonul fiscal pentru
                                validarea premiului, în cazul în care vei fi declarat
                                potenţial câștigător</Step>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormSection/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PromotionProfiOctober;