import React from 'react';
import useKozelMeetStyles from "../styles/kozelMeet/kozelMeet";
import BeMember from "../components/kozelMeet/BeMember";
import {Grid} from "@mui/material";
import MeetInfluencers from "../components/kozelMeet/MeetInfluencers";
import {useAppSelector} from "../stores/store";
import TeaserTeamAndGame from "../components/kozelMeet/TeaserTeamAndGame";
import TeaserGame from "../components/kozelMeet/TeaserGame";
import FirstScreen from "../components/kozelMeetBoardGame/FirstScreen";
import FooterScreen from "../components/kozelMeetBoardGame/FooterScreen";
import TeaserKozelLocations from "../components/kozelMeetBoardGame/TeaserKozelLocations";
import ThursdayInvite from "../components/kozelMeetBoardGame/ThursdayInvite";
import WhatFindHere from "../components/kozelMeetBoardGame/WhatFindHere";

const KozelMeet: React.FC = () => {
    const kozelMeetStyles = useKozelMeetStyles();
    const {isAuthenticated} = useAppSelector((state) => state.auth);

    return (
        <>
            <FirstScreen/>
            <TeaserKozelLocations />
            <ThursdayInvite />
            <WhatFindHere />
            {!isAuthenticated && <FooterScreen/>}
        </>
    )

    return (
        <Grid container className={kozelMeetStyles.container}>
            <Grid item xs={12} lg={10} xl={10}><BeMember/></Grid>
            <Grid item xs={12} lg={10} xl={10}><MeetInfluencers/></Grid>
            {isAuthenticated && <Grid item xs={12} lg={10} xl={10}><TeaserTeamAndGame/></Grid>}
            {isAuthenticated && <Grid item xs={12} lg={10} xl={10}><TeaserGame/></Grid>}
        </Grid>
    );
};

export default KozelMeet;
