import React from 'react';
import TeaserFrame2 from "../../TeaserFrame2";
import {Grid, Typography} from "@mui/material";
import goatGif from '../../../assets/images/goat-this.gif';
import ListInfo from "../../kozel/ListInfo";
import useTeaserBeMemberStyles from "../../../styles/kozelMeetBoardGame/first_screen/teaser_be_member";

const ContentTeaser: React.FC = () => {
    const listItems = [
        {text: 'Prietenii tăi'},
        {text: 'Provocări inedite'},
        {text: 'Premii și experiențe țapene'},
        {text: 'Joculețe interactive, distracție și voie bună'},
        {text: 'Acces la evenimente țapene în locațiile Kozel'},
        {text: 'Te conectezi  cu persoane ce au pasiuni comune, la un mix țapăn Kozel'},
    ]
    return (
        <ListInfo items={listItems} hasContrast={true}/>
    );
}
const TeaserBeMember: React.FC = (props) => {
    const styles = useTeaserBeMemberStyles();
    return (
        <Grid container>
            <Grid item xs={12} className={styles.boxTeaserFrame}>
                <TeaserFrame2 title={''}>
                    <Grid container
                          justifyContent={"center"}
                          alignItems={"center"}
                          alignContent={"center"}
                          p={3}
                    >
                        <Grid item xs={12} lg={4}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              alignContent={"center"}
                              className={styles.boxLeft}
                        >
                            <Typography className={styles.title}>Țapăn!</Typography>
                            <Typography className={styles.subtitle}>Mă bucur că ești aici.</Typography>
                            <img src={goatGif} alt={'Teaser logo kozel'} className={styles.goatGif}/>
                        </Grid>
                        <Grid item xs={12} lg={8} className={styles.boxRight}>
                            <Typography className={styles.teaserTitle}>CE TE AȘTEAPTĂ:</Typography>
                            <ContentTeaser/>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
        </Grid>
    );
}

export default TeaserBeMember;
