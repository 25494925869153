import React from 'react';
import {Grid, Typography} from "@mui/material";
import game_know_tribe from '../../assets/images/kozel_meet/game_know_tribe.png';
import game_propoz from '../../assets/images/kozel_meet/game_propoz.png';
import game_who_you_are from '../../assets/images/kozel_meet/game_who_you_are.png';
import crown from '../../assets/images/icons/crown.png';
import useWhatFindHereStyles from "../../styles/kozelMeetBoardGame/what_find_here";
import CalendarKozelMeet from "./whatFindHere/CalendarKozelMeet";
import SlideShow from "../SlideShow";
import imagesCarousels from "./importImages";


const WhatFindHere: React.FC = (props) => {
    const styles = useWhatFindHereStyles();
    return (
        <Grid container className={styles.container}>
            <Grid item xs={12}>
                <Typography className={styles.title}>Ce găsești aici?</Typography>
            </Grid>
            <Grid item xs={12} lg={10}>
                <Grid container className={styles.gamesContainer}>
                    <Grid item xs={8} lg={4} className={styles.gameItem}>
                        <img src={game_propoz} alt={" Img Completeaza propozitia"} className={styles.img100w}/>
                        <Typography className={styles.gameText}>Un joc distractiv și simplu în care trebuie să creaţi propoziţii amuzante
                            împreună</Typography>
                    </Grid>
                    <Grid item xs={8} lg={4} className={styles.gameItem}>
                        <img src={game_know_tribe} alt={" Img cunoaste-ti tribul"} className={styles.img100w}/>
                        <Typography className={styles.gameText}>Un joc de întrebări amuzante care vă ajută să vă cunoașteţi mai bine </Typography>
                    </Grid>
                    <Grid item xs={8} lg={4} className={styles.gameItem}>
                        <img src={game_who_you_are} alt={"Img Giceste cine esti"} className={styles.img100w}/>
                        <Typography className={styles.gameText}>Un joc cu stickere pe frunte, în care trebuie să ghiciţi diverse
                            personaje în ipostaze inedite</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.title}>Joia Țapănăă!</Typography>
            </Grid>
            <Grid item xs={10} lg={8}>
                <CalendarKozelMeet/>
            </Grid>
            <Grid item xs={12} lg={8} mt={3} style={{display: "none"}}>
                <Typography className={styles.gameText}>Vezi <a target={'_blank'} rel="noreferrer" href={`/pdf/nota-de-informare_prelucrarea-datelor-cu-caracter-personal_site-kozel_obs-mv_v2_30-march-2022.pdf`}> Regulamentul campaniei</a> aici.</Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Typography className={styles.title}>Dă start distracției! Te așteptăm joia în
                    locațiile Kozel, cu berea&nbsp;<img src={crown} alt={'crown'} className={styles.crownIcon}/>&nbsp;din Cehia, jocuri țapene și
                    oameni faini!</Typography>
            </Grid>
            <Grid item xs={12} className={styles.containerSlideShow}>
                <SlideShow
                    duration={10}
                    isForwardDir={true}
                    images={imagesCarousels.filter((_, index) => index % 2 === 0)}
                    slidesToShow={5}
                />
            </Grid>
            <Grid item xs={12} className={styles.containerSlideShow}>
                <SlideShow
                    duration={10}
                    isForwardDir={false}
                    images={imagesCarousels.filter((_, index) => index % 2 !== 0)}
                    slidesToShow={5}
                />
            </Grid>
        </Grid>
    );
}

export default WhatFindHere;
