import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import apiInstance from "../../api/api";
import { handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse, ApiSuccessResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';

interface ForgetPasswordState {
    loading: boolean;
    isCodeSent: boolean;
    isResetPasswordSuccess: boolean;
    error: string;
}

export interface SendSmsPayload {
    phone: string;
}

export interface ResetPasswordPayload {
    phone: string;
    code: string;
    password: string;
    confirmPassword: string;
}

const initialState: ForgetPasswordState = {
    loading: false,
    isCodeSent: false,
    isResetPasswordSuccess: false,
    error: '',
}

export const sendPhoneCode = createAsyncThunk<
any,
    SendSmsPayload,
    { rejectValue: ApiFailResponse }
>(
    'forgetPassword/sensSms',
    async (payload: SendSmsPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post<ApiSuccessResponse>(apiEndpoints.forgetPasswordSendCode, payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
)

export const resetPassword = createAsyncThunk<
    any,
    ResetPasswordPayload,
    { rejectValue: ApiFailResponse }
>(
    'forgetPassword/resetPassword',
    async (payload: ResetPasswordPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post<ApiSuccessResponse>(apiEndpoints.forgetPasswordReset, payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
)

const forgetPasswordSlice = createSlice({
    name: 'forgetPassword',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(sendPhoneCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendPhoneCode.fulfilled,(state)=> {
                state.loading = false;
                state.isCodeSent = true;
            })
            .addCase(sendPhoneCode.rejected, (state, action: PayloadAction<ApiFailResponse | undefined>) => {
                state.loading = false;
                state.error = action.payload?.message || '';
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.loading = false;
                state.isResetPasswordSuccess = true;
            })
            .addCase(resetPassword.rejected, (state,action: PayloadAction<ApiFailResponse | undefined>) => {
                state.loading = false;
                state.error = action.payload?.message || '';
            })
        ;

    }
});

export default forgetPasswordSlice.reducer;