import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useKozelMeetBoardGameCongratsStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            justifyContent: "center",
            alignItems: 'center',
            alignContent: 'center',
        },
        bgDarkChoco: {
            backgroundColor: theme.colors.dark_chocolate,
        },
        logoKozelMeet: {
            width: '75%',
        },
        subtitle: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.ivory_beige,
            fontSize: 'max(2vh,2vw)',
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            lineHeight: 1.1,
        },
        colorDesertSandGold: {
            color: theme.colors.desert_sand_gold,
        },
        boxTeaserFrame2: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        teaserContentContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(1)}  ${theme.spacing(2)}`,
            }
        },
        teaserContentItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(2)}`,
            }
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vh,3vw)',
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 1,
            letterSpacing: '1px',
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            color: theme.colors.latte_cream,
            backgroundColor: theme.colors.dark_chocolate,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(5vw,5vh)',
                padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
                marginTop: 'min(-2.5vw,-2.5vh)',
            }
        },
        teaserSubtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.2vh,1.2vw)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.latte_cream,
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        userAddIcon: {
            width: '65%',
        },
        prizeMsgImg: {
            width: '65%',
        },
        teaserContentSubtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.2vh,1.2vw)',
            color: theme.colors.latte_cream,
            textAlign: 'center',
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
                padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
            }
        },
        teaserContentButton: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.6vh,1.6vw)',
            backgroundColor: theme.colors.latte_cream,
            color: theme.colors.dark_chocolate,
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            marginBottom: theme.spacing(5),
            textAlign: 'center',
            "&:hover": {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2.2vh,2.2vw)',
                padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
            }
        },
        teaserContentTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vh,2.5vw)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            textAlign: 'center',
            lineHeight: 1,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2.6vh,2.6vw)',
                lineHeight: 1.2,
                padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
            }
        },
        haveFunText: {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(5),
        },
        borderDottedBottom: {
            borderBottomStyle: 'dotted',
            borderBottomWidth: '10px',
            borderBottomColor: theme.colors.desert_sand_gold,
            marginBottom: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
    };
})

export default useKozelMeetBoardGameCongratsStyles;
