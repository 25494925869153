import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useUntoldMixologist = createUseStyles((theme: Theme) => {
    return {
        container: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            alignItems: 'center',
        },
        containerItem: {
            textAlign: "center",
        },
        imgMixologist: {
            maxWidth: '350px',
            width: '100%',
            height: 'auto',
            marginBottom: theme.spacing(5),
        },
        imgMixologistText: {
            maxWidth: '600px',
            width: '100%',
            height: 'auto',
        }
    };
});

export default useUntoldMixologist;