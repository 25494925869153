import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import useBottleGameStyles from "../../styles/kozelMeet/bottleGame";
import bottle_dark from '../../assets/images/kozel_meet/game_bottle/bottle_dark.png';
import bottle_premium from '../../assets/images/kozel_meet/game_bottle/bottle_premium.png';
import player_active from '../../assets/images/kozel_meet/game_bottle/player_active.png'
import player_inactive from '../../assets/images/kozel_meet/game_bottle/player_inactive.png'
import meet_logo from '../../assets/images/kozel_meet/meet_logo.png';
import icon_arrow from '../../assets/images/icon_arrow.png'
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {getMyGroup} from "../../features/profile/myGroupSlice";
import {Grid, Typography} from "@mui/material";
import {getGameDetails, getGameStatus, setGameStatus} from "../../features/games/gameSlice";
import {getProfile} from "../../features/profile/profileSlice";
import Confetti from 'react-confetti';

export const gameNameApi = 'spin-the-bottle';

interface Player {
    member_id: number | null;
    nick_name: string;
}

const getRandomPlayerIndex = (players: Player[], excludedPlayers?: Player[]): number => {
    const filteredPlayers = excludedPlayers
        ? players.filter(player => !excludedPlayers.some(excluded => excluded.member_id === player.member_id))
        : players;

    if (filteredPlayers.length === 0) {
        return 0;
    }

    const randomIndex = Math.floor(Math.random() * filteredPlayers.length);
    const randomPlayer = filteredPlayers[randomIndex];

    return players.findIndex(player => player.member_id === randomPlayer.member_id);
}

const getRandomChallenge = (challenges: string[]): string => {
    const index = Math.floor(Math.random() * challenges.length);

    return challenges[index];
}

interface ChallengeWindowProps {
    players: Player[],
    spinBottle: () => void,
    spinning: boolean,
}

const ChallengeWindow: React.FC<ChallengeWindowProps> = ({players, spinBottle, spinning,}) => {
    const styles = useBottleGameStyles();
    const { games } = useAppSelector((state) => state.game);
    const { state } = games[gameNameApi];
    const {members} = useAppSelector(state => state.myGroup);
    const {id} = useAppSelector((state) => state.profile);

    const findPlayerById = (id: number) => {
        return players.find(item => item.member_id === id);
    }

    return (
        <div className={styles.challengeWindow}>
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                gravity={0.05}
                recycle={true}
                numberOfPieces={2000}
            />
            <Grid container className={styles.challengeWindowContainer}>
                <Grid item xs={0} lg={2} className={styles.challengeWindowContainerItem}>
                    <img src={icon_arrow} alt={'icon_arrow'} className={styles.challengeWindowIconArrow}/>
                </Grid>
                <Grid item xs={12} lg={8} className={styles.challengeWindowContainerItem}>
                    <Grid container className={styles.challengeContainer}>
                        <Grid item className={clsx(styles.challengeContainerItem, styles.challengePlayerBox)}>
                            <img src={player_active} alt={'player_active'}/>
                            <Typography className={styles.challengePlayerName}>
                                {((state?.sender && typeof state.sender === 'number') || state?.sender === 0) ? findPlayerById(state.sender)?.nick_name : `G.O.A.T#${id}`}
                            </Typography>
                        </Grid>
                        <Grid item className={styles.challengeContainerItem}>
                            <Typography className={styles.titleChallenge}>Provocare:</Typography>
                            <Typography className={styles.textChallenge}>{state.challenge}</Typography>
                        </Grid>
                        <Grid item className={clsx(styles.challengeContainerItem, styles.challengePlayerBox)}>
                            <img src={player_active} alt={'player_active'}/>
                            <Typography
                                className={styles.challengePlayerName}>{(state?.receiver && typeof state.receiver === 'number') ? findPlayerById(state.receiver)?.nick_name : `G.O.A.T#${id}`}</Typography>
                        </Grid>
                        <Grid item>
                            {(id === state.receiver || members.length < 2) ?
                                <button
                                    onClick={(_) => {
                                        spinBottle();
                                    }}
                                    disabled={spinning}
                                    className={clsx(styles.spinButton, spinning ? 'spinning' : '')}
                                >
                                    ÎNVÂRTE STICLA
                                </button>:
                                <Typography className={styles.textFirstFrame}>
                                    Nu te grăbi, așteaptă să-ți vină rândul să învârți sticla.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} lg={2} className={styles.challengeWindowContainerItem}>
                    <img src={icon_arrow} alt={'icon_arrow'}
                         className={clsx(styles.challengeWindowIconArrow, styles.flipHorizontal)}/>
                </Grid>
            </Grid>
        </div>
    );
}

interface ShowPlayersRoundBottleProps {
    players: Player[],
    activePlayerIndex?: number|null,
}

const ShowPlayersRoundBottle: React.FC<ShowPlayersRoundBottleProps> = ({players, activePlayerIndex,}) => {
    const styles = useBottleGameStyles();
    const getPlayerStyle = (index: number, totalPlayers: number) => {
        const angle = ((index / totalPlayers) * 360 + 90) * (Math.PI / 180); // -90 degrees to start at 12 o'clock
        const radius = 40; // Radius in percentage of the container's size
        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);

        return {
            left: `calc(50% + ${x}%)`,
            top: `calc(50% + ${y}%)`,
            transform: 'translate(-50%, -50%)',
        };
    };

    return <>
        {players.map((player, index) => (
            <div
                key={player.member_id}
                className={clsx(styles.playerBox, {[styles.active]: index === activePlayerIndex})}
                style={getPlayerStyle(index, players.length)}
            >
                <img src={index === activePlayerIndex ? player_active : player_inactive}
                     alt={index === activePlayerIndex ? 'player_active' : 'player_inactive'}
                     className={clsx(styles.playerIcon,styles.imageSize)}
                />
                <Typography
                    className={clsx(styles.playerName,styles.showPlayerName, {[styles.active]: index === activePlayerIndex})}>
                    {player.nick_name}
                </Typography>
            </div>
        ))}
    </>;
}
const BottleGame: React.FC = () => {
    const dispatch = useAppDispatch();
    const styles = useBottleGameStyles();
    const {games} = useAppSelector((state) => state.game)
    const {state, details} = games[gameNameApi];
    const {members, is_owner} = useAppSelector(state => state.myGroup);
    const {id, nick_name} = useAppSelector((state) => state.profile);
    const [showStartFrame, setShowStartFrame] = useState(true);
    const [rotation, setRotation] = useState<number>(0);
    const [spinning, setSpinning] = useState<boolean>(false);
    const [activePlayerIndex, setActivePlayerIndex] = useState<number | null>(null);
    const bottleChose = localStorage.getItem('bottle') === 'dark' ? bottle_dark : bottle_premium;

    let players = members.map(item => (
        {
            member_id: item.member_id,
            nick_name: (item && item.nick_name && item.nick_name.length > 0) ? item.nick_name : 'G.O.A.T#' + item.member_id,
        }
    ));

    if (players.length < 2) {
        players = [
            {
                member_id: id,
                nick_name: (nick_name && nick_name.length > 0) ? nick_name : `G.O.A.T#${id}`,
            },
            {
                member_id: 0,
                nick_name: 'Țapul Kozel',
            }
        ]
    }

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getMyGroup());
        dispatch(getGameStatus({gameName: gameNameApi}));
        dispatch(getGameDetails({gameName: gameNameApi}));
    }, [dispatch]);

    useEffect(() => {
        if (state !== null) {
            setShowStartFrame(false);
        }
    }, [state]);

    const conditionsExcludePlayers = (players: Player[], player: Player) => {
        if (player.member_id === 0) {
            return true;
        }

        if (players.length === 2) {
            return player.member_id === id;
        }

        return player.member_id === id || (state?.sender && player.member_id === state?.sender)
    }

    const excludePlayers = players.filter(player => {
            return conditionsExcludePlayers(players, player);
        }
    );

    const randomIndexToMove = getRandomPlayerIndex(players, excludePlayers);
    const spinBottle = () => {
        if (spinning) return;
        setSpinning(true);

        const degreesPerPlayer = 360 / players.length;
        const targetRotation = randomIndexToMove * degreesPerPlayer + 180;
        const randomExtraSpins = 720; //
        const randomRotation = randomExtraSpins + targetRotation;

        setRotation(rotation + (360 - (rotation % 360)) + randomRotation);

        setTimeout(() => {
            setSpinning(false);
            setActivePlayerIndex(randomIndexToMove);
            dispatch(setGameStatus({
                gameName: gameNameApi,
                payload: {
                    state: {
                        sender: members.length < 2 ? 0 : id,
                        challenge: getRandomChallenge(details.challenges),
                        receiver: players[randomIndexToMove].member_id,
                    }
                }
            }));
        }, 5000);
    };

    const initializeSpinBottle = () => {
        if (spinning) return;
        if(!state?.receiver) return;

        const lastIndexPlayer = players.findIndex(player => player.member_id === state.receiver);
        const degreesPerPlayer = 360 / players.length;
        const targetRotation = lastIndexPlayer * degreesPerPlayer + 180;
        const randomExtraSpins = 0; //
        const randomRotation = randomExtraSpins + targetRotation;
        setRotation(rotation + (360 - (rotation % 360)) + randomRotation);
    }

    useEffect(() => {
        if(!showStartFrame){
            initializeSpinBottle();
        }
    }, [showStartFrame]);

    return (
        <div className={styles.gameContainer}>
            <img className={styles.logoKozelMeet} src={meet_logo} alt={'meet_logo'}/>
            <div className={styles.gameBox}>
                <div className={styles.boxBottle}>
                    <div
                        className={clsx(styles.bottle, (spinning ? 'spinning' : ''))}
                        style={{
                            transform: `rotate(${rotation}deg)`,
                            backgroundImage: `url('${bottleChose}')`,
                        }}
                    />
                </div>
                <div>
                    <ShowPlayersRoundBottle players={players} activePlayerIndex={activePlayerIndex}/>
                </div>
            </div>
            {showStartFrame && !spinning && <div className={styles.startWindow}>
                <Grid container className={styles.startWindowContainer}>
                    <Grid item xs={0} lg={2} className={styles.startWindowContainerItem}>
                        <img src={icon_arrow} alt={'icon_arrow'} className={styles.startWindowIconArrow}/>
                    </Grid>
                    <Grid item xs={12} lg={8} className={styles.startWindowContainerItem}>
                        <Typography className={styles.title}>Învârte sticla <span
                            className={'lowerSize'}>și acceptă provocarea!</span></Typography>
                        {(is_owner || members.length < 2) ? <button onClick={(_) => {
                            spinBottle();
                            setShowStartFrame(false);
                        }}
                                                                    disabled={spinning}
                                                                    className={clsx(styles.spinButton, spinning ? 'spinning' : '')}>START
                        </button> : <Typography className={styles.textFirstFrame}>
                            Nu te grăbi, așteaptă să-ți vină rândul să învârți sticla.
                        </Typography>}
                    </Grid>
                    <Grid item xs={0} lg={2} className={styles.startWindowContainerItem}>
                        <img src={icon_arrow} alt={'icon_arrow'}
                             className={clsx(styles.startWindowIconArrow, styles.flipHorizontal)}/>
                    </Grid>
                </Grid>
            </div>}
            {state !== null && !spinning &&
                <ChallengeWindow players={players} spinBottle={spinBottle} spinning={spinning}/>}
        </div>
    );
};

export default BottleGame;
