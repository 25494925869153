import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import {darken, lighten} from "@mui/material";

const usePromoKozelEventsStyles = createUseStyles((theme: Theme) => {
    return {
        container:{
            backgroundColor: theme.colors.dark_chocolate,
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(10),
            position: "relative",
            [theme.breakpoints.down("lg")]: {
                padding: `${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(0)}`,
            }
        },
        item:{
            paddingBottom: theme.spacing(5),
        },
        title:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            textAlign: "center",
            padding: `${theme.spacing(0)} ${theme.spacing(5)}`,
        },
        subtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vw,2vh)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.latte_cream,
            textAlign: "center",
        },
        btn: {
            color: theme.colors.dark_chocolate,
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vw,1.5vh)',
            backgroundColor: theme.colors.latte_cream,
            padding: `${theme.spacing(1)} ${theme.spacing(25)}`,
            '&:hover': {
                backgroundColor: lighten(theme.colors.dark_chocolate, 0.5),
                color: darken(theme.colors.latte_cream,0.5),
            },
            [theme.breakpoints.down("lg")]: {
                fontSize: 'max(2vw,2vh)',
                padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
            }
        },
        arrow: {
            position: "absolute",
            left: '15%',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100px',
            height: "auto",
            zIndex: 100,
            [theme.breakpoints.down("lg")]: {
                display: 'none',
            }
        },
    };
})

export default usePromoKozelEventsStyles;
