import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import useChallenge1Style from "../../styles/challengeUntold/challenge1";
import kozel_meeet from '../../assets/images/kozel_meet/meet_logo.png';
import untold_challenge_step_1 from '../../assets/images/untold/untold_challenge_step_1.png';
import TeaserFrame2 from "../TeaserFrame2";
import theme from "../../styles/themes/default";
import clsx from "clsx";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";
import useActivity from "../../hooks/useActivity";

const Challenge1: React.FC = () => {
    const styles = useChallenge1Style();
    const {registerActivity} = useActivity();
    //
    useEffect(() => {
        registerActivity({
            name: 'UNTOLD_CHALLENGE_1',
            payload: {},
            callback: () => {},
        })
    }, []);

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} lg={6} className={styles.item}>
                <img src={kozel_meeet} alt={'kozel_meeet'} className={styles.logo} style={{maxWidth: '400px'}}/>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.motoText}>Întotdeauna e loc pentru încă un prieten!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={clsx(styles.item, styles.wrapperTeaserFrame)}>
                <TeaserFrame2
                    title={<Typography className={styles.teaserFrameTitle}>ȚAPĂN!</Typography>}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                    titleMarginTop={'min(-5vh,-5vw)'}
                >
                    <Grid container className={styles.teaserContainer}>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <Typography className={styles.accessText}>Ai accesat prima provocare din cele 3. La sfârșit ai parte de o surpriză ȚAPĂNĂĂ!</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <img src={untold_challenge_step_1} alt={'untold_challenge_step_1'}
                                 className={styles.imgStep}
                                 style={{maxWidth: '240px'}}/>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <Typography className={styles.teaserTitle}>CURSA ȚAPĂNĂ</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem} flexDirection={'column'}>
                            <Typography className={styles.teaserText}>
                                Demonstrează forța și dexteritatea într-un joc de precizie și viteză!
                            </Typography>
                            <Typography className={styles.teaserText}>
                                Concurează împotriva unei echipe sau distrează-te cu prietenii și provocați-vă unii pe
                                ceilalți.
                            </Typography>
                            <Typography className={styles.teaserText}>
                                Ai de împins ȚAPUL Kozel de la linia de START până la FINISH și înapoi la linia de
                                START.
                                Asigură-te că menții ȚAPUL pe poziție și nu intervii peste spațiul celorlați jucători.
                            </Typography>
                            <Typography className={styles.teaserTextSuccess}><b>Succes!</b></Typography>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography className={styles.prizesText}>Rezolvarea primei provocări aduce 50 de puncte grupului tău pe
                    kozel.ro</Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography className={styles.recommendGroupText}>Asigură-te că ai grupul format - accesează secțiunea
                    &nbsp;<NavLink to={paths.myGroup}>Grupul meu</NavLink> și invită-ți prietenii în comunitatea Kozel
                    Meeet!</Typography>
            </Grid>
        </Grid>
    )
}

export default Challenge1;