import React, {useCallback, useState} from 'react';
import {
    GoogleMap,
    InfoWindowF,
    MarkerClustererF,
    MarkerF,
    useJsApiLoader
} from '@react-google-maps/api';
import romaniaBorder from '../components/locations/romaniaBorder.json';
import theme from '../styles/themes/default';
import KozelPinSvg from '../assets/images/kozel_meet/kozel_pin.svg';
import KozelClusterHigh from '../assets/images/kozel_meet/kozel_brown_cluster.svg';
import KozelClusterMedium from '../assets/images/kozel_meet/kozel_desert_sand_light_cluster.svg';
import KozelClusterLow from '../assets/images/kozel_meet/kozel_desert_sand_cluster.svg';

export interface GoogleMapLocation {
    name: string;
    lat: number;
    lng: number;
    zIndex: number;
}

interface GoogleMapComponentProps {
    locations: GoogleMapLocation[]
}

export const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({locations}) => {
    const myMapontainerStyle = {
        width: '95vw',
        height: '75vh',
        marginLeft: '2vw',
        marginRight: '2vw',
    };

    const mapStyles = [
        {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
                { visibility: "on" },
                { color: theme.colors.dark_chocolate },
                { weight: 2 }
            ]
        },
        {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
                { color: theme.colors.dark_chocolate },
            ]
        }
    ];

    const clusterStyles = [
        {// Icon pentru clustere mici
            textColor: theme.colors.dark_chocolate,
            url: KozelClusterLow,
            height: 50,
            width: 50,
            className: 'cluster_custom_low'
        },
        {// Icon pentru clustere medii
            textColor: theme.colors.dark_chocolate,
            url: KozelClusterMedium ,
            height: 60,
            width: 60,
            className: 'cluster_custom_medium'
        },
        {// Icon pentru clustere mari
            textColor: theme.palette.primary.dark,
            url: KozelClusterHigh,
            height: 80,
            width: 80,
            className: 'cluster_custom_high'
        }
    ];

    const clusterOptions = {
        styles: clusterStyles,
        maxZoom: 15,
    };

    const center = {
        lat: 46,
        lng: 25.5,
    };

    const borderPath = romaniaBorder.features[0].geometry.coordinates.flatMap(
        (polygon: number[][][]) =>
            polygon.flatMap((subPolygon: number[][]) =>
                subPolygon
                    .filter((coord): coord is [number, number] => coord.length === 2)
                    .map((coord) => ({ lat: coord[1], lng: coord[0] }))
            )
    );

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API}`
    })
    const [selected, setSelected] = useState<GoogleMapLocation | null>(null);

    const onSelect = useCallback((item: GoogleMapLocation) => {
        setSelected(item);
    }, []);
    const getGoogleMapsUrl = (lat: number, lng: number) => {
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    };

    return isLoaded ? (
        <GoogleMap
                mapContainerStyle={myMapontainerStyle}
                center={center}
                zoom={7.2}
                options={{ styles: mapStyles }}
            >
                <MarkerClustererF  options={clusterOptions}>
                    {(clusterer) =>(
                        <>
                            {locations.map((location, index) => (
                                <MarkerF
                                    key={index}
                                    icon={{
                                        url: KozelPinSvg,
                                        scaledSize: new window.google.maps.Size(50, 50) // Dimensiune ajustabilă
                                    }}
                                    position={location}
                                    clusterer={clusterer}
                                    onClick={() => onSelect(location)}
                                />
                            ))}
                        </>
                    )
                    }
                </MarkerClustererF>
                {selected && (
                    <InfoWindowF
                        position={selected}
                        onCloseClick={() => setSelected(null)}
                    >
                        <div style={{
                            backgroundColor: theme.colors.dark_chocolate,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
                        }}>
                    <span
                        style={{
                            fontFamily: theme.fonts.poplar,
                            fontSize: 'max(1.5vh,1.5vw)',
                            color: theme.colors.latte_cream,
                            padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(2)}`,
                            textAlign: 'center'
                        }}>{selected.name}</span>
                            <a
                                style={{
                                    backgroundColor: theme.colors.latte_cream,
                                    fontFamily: theme.fonts.poplar,
                                    fontSize: 'max(1vh,1vw)',
                                    color: theme.colors.dark_chocolate,
                                    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
                                    textTransform: 'uppercase',
                                }}
                                href={getGoogleMapsUrl(selected.lat, selected.lng)} target={'_blank'}>Hai în locaţie</a>
                        </div>
                    </InfoWindowF>
                )}
            </GoogleMap>
    ) : <></>
}

export default GoogleMapComponent;