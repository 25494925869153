import React from "react";
import {Grid} from "@mui/material";
import useUntoldMixologist from "../../styles/my_group/untoldMixologist";
import text_untold_mixologist from "../../assets/images/my_group/text_untold_mixologist.png";
import image_mixologist from "../../assets/images/my_group/image_mixologist.png";

const UntoldMixologist: React.FC = () => {
    const untoldMixologist = useUntoldMixologist()
    return (
        <Grid container className={untoldMixologist.container}>
            <Grid item xs={12} lg={6} className={untoldMixologist.containerItem}>
                <img src={text_untold_mixologist} alt={'text_untold_mixologist'} className={untoldMixologist.imgMixologistText}/>
            </Grid>
            <Grid item xs={12} lg={6} className={untoldMixologist.containerItem}>
                <img src={image_mixologist} alt={'image_mixologist'} className={untoldMixologist.imgMixologist}/>
            </Grid>
        </Grid>
    )
}

export default UntoldMixologist;