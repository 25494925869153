import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useBeMemberStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
            justifyContent: "center",
            padding: `${theme.spacing(30)} ${theme.spacing(10)} ${theme.spacing(10)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(15)} ${theme.spacing(1)} ${theme.spacing(5)}`,
            }
        },
        box: {
            backgroundColor: theme.palette.background.paper,
            position: "relative",
            padding: `${theme.spacing(10)} ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)}`,
            }
        },
        logo: {
            maxWidth: '450px',
            marginTop: '-20%',
            [theme.breakpoints.down('lg')]: {
                maxWidth: '250px',
                marginTop: '-25%',
            }
        },
        subLogoText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.2vh,1.2vw)',
            marginTop: theme.spacing(5),
            textAlign: "center",
            color: theme.palette.primary.light,
            '& .color_change': {
                color: theme.palette.secondary.main,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        boxBorderDottedContainer: {
            borderWidth: '10px',
            borderStyle: 'dotted',
            borderColor: theme.palette.secondary.main,
            marginTop: theme.spacing(10),
            [theme.breakpoints.down("lg")]: {
                marginTop: theme.spacing(5),
            }
        },
        boxBorderDottedItem: {
            position: "relative",
            textAlign: "center",
        },
        sectionItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        textFloatBorder: {
            display: "inline-block",
            margin: '0',
            fontFamily: 'PoplarKozel, sanserif',
            letterSpacing: '1px',
            fontSize: 'max(5vh,5vw)',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textAlign: "center",
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.main,
            textWrap: "nowrap",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginTop: 'min(-3vh,-3vw)',
            lineHeight: 1,
            '& span': {
                fontSize: 'max(3vh,3vw)',
                letterSpacing: '1px',
            }
        },
        textLittle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.2vh,1.2vw)',
            fontWeight: 'bold',
            marginTop: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.primary.light,
            padding: `0 ${theme.spacing(15)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)}`,
            }
        },
        textSubDidYouKnow: {
            fontFamily: theme.fonts.poplar,
            color: theme.palette.primary.light,
            fontSize: 'max(1.5vh,1.5vw)',
            marginTop: theme.spacing(1),
            padding: `0 ${theme.spacing(15)} ${theme.spacing(5)}`,
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(2)} ${theme.spacing(5)}`,
            },
        },
        button: {
            fontFamily: theme.fonts.poplar,
            backgroundColor: theme.colors.latte_cream,
            textTransform: 'uppercase',
            fontWeight: 900,
            color: theme.palette.primary.main,
            fontSize: 'max(3vh,3vw)',
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            marginTop: theme.spacing(5),
            '&:hover': {
                color: theme.palette.primary.light,
            },
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(3),
            }
        },
        textBenefits: {
            display: 'flex',
            gap: 5,
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            fontSize: `max(2vh,2vw)`,
            alignItems: 'center',
            fontStyle: 'italic',
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
            }
        },
        contentTeaserListItemIcon: {
            width: 'max(3vh,3vw)',
            height: 'max(3vh,3vw)',
            marginRight: theme.spacing(3),
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 900,
            fontSize: 'max(3vh,3vw)',
            paddingBottom: theme.spacing(3),
            color: theme.palette.primary.main,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        contentTeaserList: {
            fontFamily: theme.fonts.poplar,
        },
        contentTeaserListItem: {
            alignItems: 'center',
            padding: 0,
            '& .MuiListItemText-root': {
                '& span': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(1.5vh,1.5vw)',
                    color: theme.palette.primary.main,
                    margin: 0,
                    [theme.breakpoints.down('lg')]: {
                        fontSize: 'max(1.5vh,1.5vw)',
                    },
                }
            },
            '& .MuiListItemIcon-root': {
                [theme.breakpoints.down('lg')]: {
                    minWidth: '15px',
                },
                '& img': {
                    width: '15px',
                    height: 'auto',
                    marginTop: '4px',
                    marginBottom: '4px',
                    paddingRight: '4px',
                    [theme.breakpoints.down('lg')]: {
                        width: '10px',
                    },
                }
            }
        },
    }
});

export default useBeMemberStyles;