import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";
import {lighten} from "@mui/material";

const useMeetInfluencers = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
            backgroundSize: 'cover',
            justifyContent: "center",
            alignItems: 'center',
        },
        imgBanner: {
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                width: '95%',
            }
        },
        containerItem: {
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            alignContent: 'center',
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(1)}`,
            }
        },
        containerText: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        containerVideo: {
            display: "flex",
            padding: `${theme.spacing(3)} 0`,
            marginBottom: theme.spacing(3),
            justifyContent: "end",
            [theme.breakpoints.down('lg')]: {
                justifyContent: "center",
            }
        },
        containerSlideShow: {
            display: "flex",
            justifyContent: "center",
            padding: `${theme.spacing(1)} 0`,
        },
        textChallenge: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.light,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: "center",
            '& .MuiLink-root': {
                color: theme.palette.secondary.main,
            }
        },
        wrapperTextVideo: {
            margin: `${theme.spacing(3)}`,
            [theme.breakpoints.down('lg')]: {}
        },
        wrapperBoxInfluencers: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            alignContent: 'center',
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(3)}`,
            }
        },
        wrapperBoxInfluencersContainer: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            alignContent: 'center',
        },
        boxInfluencers: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            background: `${theme.colors.latte_cream}`,
            margin: `${theme.spacing(3)} 0`,
            padding: `${theme.spacing(5)} ${theme.spacing(5)} 50vh ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(2)} calc((100vw/1.65) - 15%) ${theme.spacing(2)}`,
            },
            [theme.breakpoints.up('xl')]: {
                paddingBottom: '60vh',
            }
        },
        boxInfluencersHeadText: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.main,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: 'center',
            fontWeight: "bold",
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(1.5vw,1.5vh)',
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            }
        },
        boxInfluencersTitle: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(2vw,2vh)',
            textAlign: 'center',
            fontWeight: 900,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vw,2vh)',
            },
            '& a': {
                color: theme.colors.desert_sand_gold,
            },
            '& .colorSand': {
                color: theme.colors.desert_sand_gold,
            }
        },
        spaceBottom: {
            paddingBottom: theme.spacing(5),
        },
        wrapperBoxInfluencersImg: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            alignContent: 'center',
        },
        boxInfluencersImg: {
            maxWidth: '1440px',
            maxHeight: '875px',
            width: '100%',
            height: 'auto',
            marginTop: '-40%',
            [theme.breakpoints.down('lg')]: {
                marginTop: '-50%',
            }
        },
        titleQuestDay: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 900,
            fontSize: 'max(2.5vh,2.5vw)',
            color: theme.palette.secondary.light,
            textAlign: "center",
            padding: `${theme.spacing(0)}`,
            textTransform: 'uppercase',
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(1)}`,
            }
        },
        introducingText: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            color: theme.palette.primary.light,
            letterSpacing: '3px',
            fontSize: 'max(1.5vh,1.5vw)',
            padding: ` 0 ${theme.spacing(2)}`
        },
        titleMeetInf: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(4vh,4vw)',
            }
        },
        subtitleMeetInf: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            color: lighten(theme.colors.latte_cream, 0.5),
            fontSize: 'max(2vh,2vw)',
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
        },
        wrapperTeaser: {
            paddingTop: theme.spacing(15),
            [theme.breakpoints.down('lg')]: {
                paddingTop: theme.spacing(10),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }
        },
        teaserWrapperItem: {
            borderBottomStyle: 'dotted',
            borderBottomWidth: theme.spacing(2),
            borderBottomColor: theme.colors.desert_sand_gold,
            margin: `0 ${theme.spacing(5)}`,
            '&:last-child': {
                borderBottom: 'none',
            },
            [theme.breakpoints.down('lg')]: {
                margin: `0 ${theme.spacing(1)}`,
            }
        },
        teaserContainer: {
            padding: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(1),
            }
        },
        teaserItem: {
            padding: `${theme.spacing(3)} 0 `,
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
        },
        boxText: {
            [theme.breakpoints.down('lg')]: {
                order: 2,
            }
        },
        boxImg: {
            padding: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
                order: 1,
            }
        },
        teaserText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vh,2vw)',
        },
        textRight: {
            textAlign: 'right'
        },
        img: {
            width: '100%',
        },
        textGoMap: {
            paddingTop: theme.spacing(5),
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(1.5vh,1.5vw)',
            textAlign: 'center',
        },
        button: {
            marginTop: theme.spacing(2),
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            backgroundColor: theme.colors.latte_cream,
            fontSize: 'max(1.5vh,1.5vw)',
            textAlign: 'center',
            '&:hover': {
                backgroundColor: theme.colors.dark_chocolate,
                color: theme.colors.latte_cream,
            }
        },
        titleRitual: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(3vh,3vw)',
                paddingTop: theme.spacing(3)
            }
        },
        timeIntervalContainer: {
            borderTopStyle: 'dotted',
            borderTopWidth: '15px',
            borderTopColor: theme.colors.desert_sand_gold,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: "center",
            gap: theme.spacing(10),
            paddingTop: theme.spacing(10),
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(2),
                paddingTop: theme.spacing(4),
                marginTop: theme.spacing(2),
                borderTopWidth: '5px',
            }
        },
        boxTime: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
            padding: theme.spacing(3),
            borderStyle: 'dotted',
            borderWidth: '15px',
            borderColor: theme.colors.desert_sand_gold,
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(1),
                padding: theme.spacing(3),
                marginTop: theme.spacing(1),
                fontSize: 'max(2vh,2vw)',
                borderWidth: '5px',
            }
        },
        boxClockIcon: {
            [theme.breakpoints.down('lg')]: {
                maxWidth: '33vw',
            }
        },
        keepEyesOnUsTest: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.light,
            fontSize: 'max(2vh,2vw)',
            textAlign: 'center',
        },
        prepareSurpriseText: {
            fontFamily: theme.fonts.poplar,
            color: theme.palette.primary.light,
            fontSize: 'max(5vh,5vw)',
            textAlign: 'center',
        },
        timeIntervalWrapperContainer: {},
        timeIntervalWrapperItem: {},
        mapBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            gap: theme.spacing(3),
        },
    }
});

export default useMeetInfluencers;