import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import useMyGroup from "../styles/my_group/myGroup";
import TeaserInviteFriends from "../components/my_group/TeaserInviteFriends";
import UntoldMixologist from "../components/my_group/UntoldMixologist";
import DidYouKnowComponent from "../components/DidYouKnowComponent";
import ListInfo from "../components/kozel/ListInfo";
import MyGroupMix from "../components/my_group/MyGroupMix";
import {useAppDispatch} from "../stores/store";
import {getMyGroup} from "../features/profile/myGroupSlice";
import GameSpinBottleHistory from "../components/kozelMeet/GameSpinBottleHistory";

const MyGroup: React.FC = () => {
    const dispatch = useAppDispatch();
    const myGroup = useMyGroup();

    useEffect(() => {
        dispatch(getMyGroup());
    }, [dispatch])

    const listInfoItems = [
        {text: 'Asigură-te că ai grupul de prieteni creat și câștigă puncte;'},
        {text: 'Mergi la Kozel Meeet și provoacă-ţi prietenii;'},
        {text: 'Accesează Berea mea și descoperă KOZEL MIX;'},
        {text: 'Vizitează locaţiile Kozel cu gașca ta, pentru un MIX ţapăn de distracţie, destindere, conectare și bere ţapănă din Cehia!'},
    ]
    return (
        <Grid container className={myGroup.container}>
            <Grid item xs={12}>
                <TeaserInviteFriends/>
            </Grid>
            <Grid item xs={12}>
                <MyGroupMix/>
            </Grid>
            <Grid item xs={12}>
                <GameSpinBottleHistory/>
            </Grid>
            <Grid item xs={12}>
                <UntoldMixologist/>
            </Grid>
            <Grid item xs={12}>
                <Typography className={myGroup.text}>Te așteptăm cu gașca de prieteni la UNTOLD.</Typography>
                <Typography className={myGroup.text}>Fă check-in la Kozel corner @UNTOLD, participă la provocări și
                    câștigă premii și experienţe pentru grupul tău!</Typography>
            </Grid>
            <Grid item xs={12}>
                <DidYouKnowComponent title={'Ce te așteaptă aici:'}>
                    <ListInfo items={listInfoItems}/>
                </DidYouKnowComponent>
            </Grid>
        </Grid>
    )
}

export default MyGroup;