import { useState, useEffect } from 'react';
import theme from "../styles/themes/default";

const useIsScreenDownLg = (): boolean => {
    const [isScreenDownLg, setIsScreenDownLg] = useState<boolean>(window.innerWidth < theme.breakpoints.values.lg);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenDownLg(window.innerWidth < theme.breakpoints.values.lg);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isScreenDownLg;
};

export default useIsScreenDownLg;
