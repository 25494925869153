import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";
import bg_landscape from "../../assets/images/vocea_romaniei/bg_landscape.jpg";
import bg_square from "../../assets/images/vocea_romaniei/bg_square.jpg";
import bg_portrait from "../../assets/images/vocea_romaniei/bg_portrait.jpg";

const useBannerVoceaRoStyle = createUseStyles((theme: Theme) => {
    return {
        containerWrapper: {
            backgroundColor: theme.colors.dark_chocolate,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        containerItemWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        bg_vocea_romaniei: {
            marginTop: theme.spacing(0),
            backgroundImage: `url("${bg_landscape}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            '@media (orientation: landscape)': {
                backgroundImage: `url("${bg_landscape}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },

            '@media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1.5/1)': {
                backgroundImage: `url("${bg_square}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },

            '@media (orientation: portrait)': {
                backgroundImage: `url("${bg_portrait}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },
        },
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            marginTop: theme.spacing(5),
            '&:first-child': {
                marginTop: theme.spacing(0),
            }
        },
        bannerWrapperItem: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            padding: `0 ${theme.spacing(3)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(1)}`,
            }
        },
        bannerContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        bannerItem: {
            display: 'flex',
            gap: theme.spacing(2),
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        bannerLogo: {
            filter: 'brightness(0) invert(1)',
            width: 'auto',
            height: '70px',
            maxWidth: '2000px',
            maxHeight: '600px',
            [theme.breakpoints.down('lg')]: {
                width: '40vw',
                height: 'auto',
            }
        },
        bannerTitle: {
            fontFamily: theme.fonts.poplar,
            letterSpacing: '1px',
            fontSize: 'max(4vh,4vw)',
            color: theme.palette.primary.light,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(4vh,4vw)',
            }
        },
        bannerLogoVoiceRo: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: '100%',
            height: 'auto',
            maxWidth: '470px',
            maxHeight: '230px'
        },
        text: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vw, 2vh)',
            fontWeight: 'bold',
        },
        bannerButton: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
            backgroundColor: theme.colors.latte_cream,
            fontFamily: theme.fonts.poplar,
            letterSpacing: '1px',
            color: theme.colors.dark_chocolate,
            fontSize: 'max(2.5vw, 2.5vh)',
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            }
        },
        teaser_img: {
            maxWidth: '1200px',
            maxHeight: '990px',
            width: '100%',
            height: 'auto'
        },
        boxBannerVoceaRomaniei: {
            padding: `${theme.spacing(10)} 0`,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',

            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(10)} ${theme.spacing(1)}`,
            }
        },
        title: {
            fontFamily: theme.fonts.poplar,
            letterSpacing: '1px',
            fontSize: 'max(5vh,5vw)',
            color: theme.palette.primary.light,
            textAlign: "center",
        },
        bannerText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.palette.primary.light,
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        textTeaser: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.palette.primary.light,
            paddingBottom: theme.spacing(3),
            textAlign: "center",
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        subtitleTeaser: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.palette.primary.light,
            paddingBottom: theme.spacing(3),
            textAlign: "center",
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        },
        titleTeaser: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2vh,2vw)',
            color: theme.colors.latte_cream,
            textAlign: "center",
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(3vh,3vw)',
            }
        },
        boxPromoSteps: {
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(2)}`,
            }
        },
        promoStepContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        promoBoxText: {
            [theme.breakpoints.down('lg')]: {
                paddingTop: theme.spacing(3),
            }
        },
        promoStepItem: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        promoBoxDetails:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        promoBoxImgNr: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        promoBoxImgPromo: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        promoStepSection:{
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        itemTeaser: {
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(2)}`,
            }
        },
        promoImgNr: {
            width: '100%',
            height: "auto",
            maxWidth: '100px',
            maxHeight: '200px',
        },
        blank:{
            [theme.breakpoints.down("lg")]:{
                display: 'none',
            }
        },
        promoImgPromo:{

        },
        promoImg: {
            width: '100%',
            height: "auto",
        },
        imgPathCopite: {
            width: '100%',
            height: "auto",
        },
        boxText: {
            backgroundColor: theme.colors.latte_cream,
            color: theme.colors.dark_chocolate,
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2vh,2vw)',
            padding: theme.spacing(3),
        },
        boxDetails: {
            color: theme.palette.primary.light,
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: 'max(1.8vh,1.8vw)',
            padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} 0`,
        },
        imgKozelFooter: {
            width: '100%',
            height: "auto",
        },
        teaserContentContainer: {
            padding: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
            }
        },
        navLink: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.8vh,1.8vw)',
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: theme.palette.primary.light,
            cursor: 'pointer',
        }
    }
});

export default useBannerVoceaRoStyle