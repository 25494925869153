import {createUseStyles} from 'react-jss';
import {Theme} from '../../themes/default';

const useMainStyles = createUseStyles((theme: Theme) => {
    return {
        titleBox: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(3),
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            fontWeight: 'bold',
            color: theme.colors.dark_chocolate,
            textAlign: "center",
        },
        subTitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            fontWeight: 'bold',
            color: theme.colors.dark_chocolate,
            textAlign: "center",
        },
        stepsWrapper: {
            justifyContent: 'space-around',
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(10),
            paddingLeft: 'max(10vw,10vh)',
            paddingRight: 'max(10vw,10vh)',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 'max(2vw,2vh)',
                paddingRight: 'max(2vw,2vh)',
            }
        },
        wrapperStep:{
            '& a': {
                color: theme.colors.dark_chocolate,
            },
            '&:hover a': {
                color: theme.colors.latte_cream,
            },
            [theme.breakpoints.down('md')]:{
                marginTop: theme.spacing(5),
                marginRight: theme.spacing(2),
            }
        },
        textBeforeFormSection: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            fontWeight: 'bold',
            padding: `max(10vw,10vh) max(10vw,10vh) max(2vw,2vh) max(10vw,10vh)`,
            textAlign: "center",
            [theme.breakpoints.down('md')]: {
                padding: `max(10vw,10vh) max(2vw,2vh) max(2vw,2vh) max(2vw,2vh)`,
                letterSpacing: '1px',
                fontSize: 'max(2.5vw,2.5vh)',
                fontWeight: 'normal',
            },
        }
    }
});

export default useMainStyles;
