import React from 'react';
import {Grid, Typography} from "@mui/material";
import useFooterScreenStyles from "../../styles/kozelMeetBoardGame/footer_screen";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";
import InfoIcon from "@mui/icons-material/Info";
import clsx from "clsx";
import {useAppSelector} from "../../stores/store";

const FooterScreen: React.FC = () => {
    const styles = useFooterScreenStyles();
    const { isAuthenticated } = useAppSelector(state => state.auth);
    return (
        <Grid container
              className={clsx(styles.boxContainer,styles.bgLateCream)}
        >
            <Grid item xs={12} lg={6} mt={5}>
                <Typography className={styles.title}>Hai în comunitatea Kozel Meeet!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} mt={5}>
                <Typography className={styles.subtitle}>Invită-ţi prietenii, câștigă puncte și premii
                    surpiză.</Typography>
            </Grid>
            {!isAuthenticated && (
                <>
                    <Grid item xs={12} lg={6} mt={5}>
                        <NavLink to={paths.register} className={styles.button}>Devin membru</NavLink>
                    </Grid>
                    <Grid item xs={12} lg={8} mt={5}>
                        <Typography className={styles.textBenefits}><InfoIcon className={styles.contentTeaserListItemIcon}/>înscrierea
                            în comunitate îți aduce automat 50 de puncte</Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default FooterScreen;
