import React from "react";
import {Grid, Modal} from "@mui/material";
import usePopupComponentStyles from "../../styles/kozel/popupComponent";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface PopupComponentProps{
    isOpen: boolean,
    onClose: () => void,
    children: React.ReactNode,
}
const PopupComponent: React.FC<PopupComponentProps> = ({isOpen, onClose, children}) => {
    const styles = usePopupComponentStyles();
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="popup-title"
            aria-describedby="popup-description"
        >
            <Grid container className={styles.wrapperContainer}>
                <Grid item xs={12} className={styles.wrapperContainerItem}>
                    <Grid container className={styles.container}>
                        <HighlightOffIcon onClick={onClose} className={styles.closeButton}/>
                        <Grid item className={styles.containerItem}>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default PopupComponent;