import React from 'react';
import {Grid, Typography} from "@mui/material";
import meet_logo from "../../assets/images/kozel_meet/meet_logo.png";
import useCodeAccessLpStyles from "../../styles/kozelMeetBoardGameAccess/code_access_lp";
import clsx from "clsx";
import copite from '../../assets/images/kozel_meet/board_game/copite.png';
import games from '../../assets/images/kozel_meet/board_game/jocuri.jpg';
import {useAppSelector} from "../../stores/store";
import {isNull} from "lodash";


const CodeAccessLp: React.FC = () => {
    const styles = useCodeAccessLpStyles();
    const {currentCode, nextCode} = useAppSelector(state => state.kozelMeetBoardGame);

    const showCodeMessage = () => {
        if (currentCode === nextCode) {
            return currentCode;
        }

        if(isNull(nextCode)) {
            return currentCode;
        }

        return `${currentCode} sau ${nextCode}`
    }

    return (
        <Grid container className={styles.container}>
            {/* <Grid item xs={12} className={styles.boxBorderWithCenterTitle}>
                <Typography className={styles.centerTopTitle}>INTRODUCING</Typography>
            </Grid> */}
            <Grid item xs={10} lg={6} display={"flex"} justifyContent={"center"}>
                <img src={meet_logo} alt={'MEEET KOZEL LOGO'} className={styles.logoKozelMeet}
                     style={{maxWidth: '425px', maxHeight: '240px'}}/>
            </Grid>
            <Grid item xs={10} lg={6} display={"flex"} justifyContent={"center"} mt={5}>
                <Typography className={styles.title}>JOCURI, BERE, VOIE BUNĂ, JOI NE DISTRĂM ÎMPREUNĂ!</Typography>
            </Grid>
            <Grid item xs={12} lg={8} className={clsx(styles.item, styles.borderDotted)}>
                <Grid container className={styles.subContainer}>
                    <Grid item xs={12} className={clsx(styles.subItem, styles.boxTitle)}>
                        <Typography className={styles.title}>Felicitări!</Typography>
                        <Typography className={styles.title}>Poza a fost încărcată.</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.subItem}>
                        <Typography className={styles.text}>Codul de acces pentru jocuri este</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.subItem}>
                        <Typography className={styles.codeText}>{showCodeMessage()}</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.subItem}>
                        <Typography className={styles.text}>Acum, mergi la <u>Kozel Wall of Games</u> pentru a accesa
                            jocurile.
                            Te rugăm să lași jocurile în stare bună de utilizare, astfel încât să se bucure de ele și
                            alţi prieteni Kozel!</Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx(styles.subItem, styles.middleBorderDotted)}>
                        <Typography className={styles.subtitle}>Urmează acești pași simpli:</Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx(styles.subItem, styles.boxSteps)}>
                        <Typography className={styles.stepNumber}>1</Typography>
                        <Typography className={styles.stepText}>Mergi la Kozel Wall of Games și introdu codul pentru a
                            accesa jocul dorit.</Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx(styles.subItem, styles.boxSteps)}>
                        <Typography className={clsx(styles.stepText, styles.stepTextFirst)}>Alege-ţi jocul dorit,
                            închide ușiţa și schimbă cifrul cu unul aleatoriu pentru a da șansa și altor prieteni Kozel
                            să se bucure de experienţa jocului.</Typography>
                        <Typography className={styles.stepNumber}>2</Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx(styles.subItem, styles.boxSteps)}>
                        <Typography className={styles.stepNumber}>3</Typography>
                        <Typography className={styles.stepText}>După ce ce v-aţi distrat, duceţi jocul la loc (introdu
                            codul primit mai sus pentru a redeschide ușița) și schimbă cifrul cu unul
                            aleatoriu.</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}
                          alignContent={'center'} alignSelf={'center'}>
                        <img src={copite} alt={'steps kozel'} className={styles.img}
                             style={{maxWidth: '290px', maxHeight: "75px"}}/>
                    </Grid>
                    <Grid item xs={12} className={styles.subItem}>
                        <Typography className={styles.bigText}>Have FUN!</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.subItem}>
                        <img src={games} alt={'totem game'} className={styles.img}
                             style={{maxWidth: '790px', maxHeight: "440px"}}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CodeAccessLp;
