import { createUseStyles } from 'react-jss';

interface StyleProps {
    maxWidth: number | string;
}

const useStyles = createUseStyles({
    image: {
        width: '100%',
        maxWidth: (props: StyleProps) => props.maxWidth,
    },
});

export default useStyles;
