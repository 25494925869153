import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";
import {darken} from "@mui/material";
import bg_bottle from '../../assets/images/kozel_meet/game_bottle/bg_bottle.png';

const useBottleGameStyles = createUseStyles((theme: Theme) => {
    return {
        gameContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
            backgroundColor: theme.colors.dark_chocolate,
        },
        gameBox: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '75vh',
            width: '100%',
        },
        playerBox: {
            position: 'absolute',
            width: '10%',
            height: '10%',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                width: '20vw',
                height: '20vw',
            }
        },
        logoKozelMeet: {
            maxHeight: '10vh',
            width: 'auto',
        },
        playerIcon: {},
        playerName: {
            fontFamily: theme.fonts.apex,
            color: darken(theme.colors.latte_cream, .5),
            fontSize: 'max(2vh,2vw)',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            textWrap: 'nowrap',
            '&.active': {
                color: theme.colors.latte_cream,
            }
        },
        active: {
            color: theme.colors.latte_cream,
        },
        boxBottle: {
            backgroundImage: `url('${bg_bottle}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '30vw',
            height: '30vw',
            position: "relative",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('lg')]: {
                width: '60vw',
                height: '60vw',
            }
        },
        bottle: {
            width: '150px',
            height: '450px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: 'transform 3s ease-out',
            '& .spinning': {
                pointerEvents: 'none',
            },
        },
        spinButton: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            backgroundColor: theme.colors.latte_cream,
            borderRadius: 'max(6vw,6vh)',
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            marginTop: theme.spacing(5),
            fontSize: 'max(6vw,6vh)',
            cursor: 'pointer',
            '&.spinning': {
                visibility: 'hidden',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(3vw,3vh)',
                padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
            }
        },
        startWindow: {
            position: "absolute",
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.75)',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        challengeWindow: {
            extend: 'startWindow',
        },
        startWindowContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        challengeWindowContainer: {
            extend: 'startWindowContainer',
        },
        startWindowContainerItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
        },
        challengeWindowContainerItem: {
            extend: 'startWindowContainerItem',
        },
        startWindowIconArrow: {
            maxWidth: '150px',
            width: 'max(10vw,10vh)',
            height: 'auto',
            marginRight: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            }
        },
        challengeWindowIconArrow: {
            extend: 'startWindowIconArrow',
        },
        flipHorizontal: {
            transform: 'scaleX(-1)',
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(5),
        },
        title: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(10vw,10vh)',
            textAlign: "center",
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 1,
            '& .lowerSize': {
                fontSize: 'max(6vw,6vh)',
            }
        },
        challengeContainer: {
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
        },
        challengeContainerItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
        },
        challengePlayerBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
        },
        challengePlayerName: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vw,2vh)',
            fontWeight: 'bold',
        },
        titleChallenge: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vw,2vh)',
            fontWeight: 'bold',
        },
        textChallenge: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(3vw,3vh)',
            fontWeight: 'bold',
            textWrap: 'wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
            }
        },
        textFirstFrame: {
            extend: 'textChallenge',
        },
        imageSize: {
            [theme.breakpoints.down('lg')]: {
                width: '100%',
                height: 'auto',
            }
        },
        showPlayerName: {
            [theme.breakpoints.down('lg')]: {
                width: '100%',
                textWrap: 'wrap',
            }
        }
    }
});

export default useBottleGameStyles;
