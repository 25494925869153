import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {showNotification} from "../notification/notificationSlice";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';

export interface NcpPayloadProps {
    partner: string;
    birthdate: string;
    firstname: string;
    lastname: string;
    phone: string;
    receiptNr: string;
    receiptDate: string;
    receiptFile: string;
    accordingMarketing: boolean;
}
export interface NcpStateProps {
    loading: boolean;
}

const initialState: NcpStateProps = {
    loading: false,
}

export const insertNcp = createAsyncThunk(
    'ncp/insertNcp',
    async (payload: NcpPayloadProps, thunkAPI) => {
        try{
            const response = await apiInstance.post(apiEndpoints.ncpInsert, payload);
            thunkAPI.dispatch(showNotification({message: response.data.data['message'], variant: 'success'}));

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);


const ncpSlice = createSlice({
    name: "ncp",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(insertNcp.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(insertNcp.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(insertNcp.rejected, (state, action) => {
                state.loading = false;
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default ncpSlice.reducer;