import React from 'react';
import {Grid, Typography} from "@mui/material";
import FormNcp from "./FormNcp";
import useFormSectionStyles from "../../../styles/ncp/promotion_profi_october/form_section";

const FormSection: React.FC = () => {
    const formSectionStyle = useFormSectionStyles();

    return (
        <Grid container>
            <Grid item xs={12} className={formSectionStyle.bgWoodDark}>
                <Grid container className={formSectionStyle.containerFormNcp}>
                    <Grid item md={8} xs={12} className={formSectionStyle.itemFormNcp}>
                        <FormNcp partner={'profi-202410'}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={formSectionStyle.aboutPromotionSection}>
                        <Typography className={formSectionStyle.aboutPromotionText}>Durata
                            promoției: <b>14.10.2024 - 27.10.2024</b>.
                            Consultă gratuit regulamentul promoției pe <a
                                href={`/pdf/2024/`} target="_blank" rel="noreferrer"
                                style={{textDecoration: 'underline'}}>WWW.KOZEL.RO/REGULAMENTE</a>.</Typography>
                        <Typography className={formSectionStyle.aboutPromotionText}>NUMĂRUL TOTAL DE PREMII ESTE 251 ,
                            CU O VALOARE TOTALĂ NETĂ COMERCIALĂ ESTIMATIVĂ DE 36,652 RON CU TVA INCLUS. PREMIILE SE
                            ACORDĂ PRIN TRAGERE LA SORTI. LISTA LOCATIILOR PARTICIPANTE POATE FI CONSULTATĂ ÎN
                            REGULAMENTUL CAMPANIEI.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormSection;