export interface LocationItemProps {
    city: string,
    name: string,
    isSpecialKozelMeeet: boolean,
    lat: number,
    lng: number,
    zIndex: number,
    [key: string]: any;
}
export const listLocation: LocationItemProps[] = [
    {city:"ALBA IULIA", isSpecialKozelMeeet: true, name: "FRAM'S", lat: 46.070161, lng: 23.57000, zIndex: 100},
    {city:"ALBA IULIA", isSpecialKozelMeeet: true, name: "ART CAFEE DOWNTOWN", lat: 46.069384, lng: 23.578072, zIndex: 100},
    {city:"ARAD", isSpecialKozelMeeet: true, name: "LA BOTTEGA BEER &GRILL", lat: 46.173326, lng: 21.318218, zIndex: 100},
    {city:"ARAD", isSpecialKozelMeeet: true, name: "CLUB FLEX", lat: 46.194722, lng: 21.307111, zIndex: 100},
    {city:"PITEȘTI", isSpecialKozelMeeet: true, name: "PAPAO", lat: 46.194722, lng: 21.307111, zIndex: 100},
    {city:"PITEȘTI", isSpecialKozelMeeet: true, name: "URBAN HOME", lat: 46.194722, lng: 21.307111, zIndex: 100},
    {city:"ORADEA", isSpecialKozelMeeet: true, name: "ONE & ONLY LORDS PUB", lat: 47.054806, lng: 21.929694, zIndex: 100},
    {city:"ORADEA", isSpecialKozelMeeet: true, name: "MOSCOVA EXOTICA", lat: 47.058578, lng: 21.930278, zIndex: 100},
    {city:"BRAȘOV", isSpecialKozelMeeet: true, name: "WAU EVENT ", lat: 45.650011, lng: 25.603997, zIndex: 100},
    {city:"BRAȘOV", isSpecialKozelMeeet: true, name: "CRAZY BITE", lat: 45.6636729, lng: 25.564335, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "DRAFT PUB", lat: 44.446282, lng: 26.105823, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "THE PLACE", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "THE DUBLINER IRISH PUB - TITULESCU", lat: 44.446282, lng: 26.105823, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "BERARIA GAMBRINUS 2", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "BARAKA", lat: 44.446282, lng: 26.105823, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "GREEN HOURS", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "MOJO", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "CURTEA BERARILOR", lat: 44.430877, lng: 26.100714, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "TERASA CURTEA BERARILOR", lat: 44.427531, lng: 26.123628, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "TANKERIA", lat: 44.430412, lng: 26.10263, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "CROSSFIRE", lat: 44.430877, lng: 26.100714, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "CITY GRILL COVACI", lat: 44.4304066, lng: 26.100714, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "RIBS FOOD", lat: 44.430412, lng: 26.10263, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "LIMONCELLO CLUB", lat: 44.430877, lng: 26.100714, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "DISTRIKT", lat: 44.427531, lng: 26.123628, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "EQUINOXE CAFFE", lat: 44.430412, lng: 26.10263, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "LA POD", lat: 44.430877, lng: 26.100714, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "HANUL BERARILOR-CASA SOARE 2", lat: 44.428587, lng: 26.095471, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "BERARIA GAMBRINUS", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUCUREȘTI", isSpecialKozelMeeet: true, name: "ENCORE", lat: 44.443091, lng: 26.094164, zIndex: 100},
    {city:"BUZĂU", isSpecialKozelMeeet: true, name: "O'BROTHERS", lat: 45.151706, lng: 26.813505, zIndex: 100},
    {city:"BUZĂU", isSpecialKozelMeeet: true, name: "SPORT'S PUB", lat: 45.151706, lng: 26.813505, zIndex: 100},
    {city:"CLUJ NAPOCA", isSpecialKozelMeeet: true, name: "INSOMNIA EIDOS", lat: 46.768336, lng: 23.58966, zIndex: 100},
    {city:"CLUJ NAPOCA", isSpecialKozelMeeet: true, name: "BRUTO", lat: 46.777535, lng: 23.60271, zIndex: 100},
    {city:"CONSTANȚA", isSpecialKozelMeeet: true, name: "IRISH PUB", lat: 46.757127, lng: 23.5937, zIndex: 100},
    {city:"CONSTANȚA", isSpecialKozelMeeet: true, name: "THE LIBRARY", lat: 46.773430, lng: 23.589251, zIndex: 100},
    {city:"MAMAIA", isSpecialKozelMeeet: true, name: "BARRELS PUB", lat: 46.773430, lng: 23.589251, zIndex: 100},
    {city:"SFÂNTU GHEORGHE", isSpecialKozelMeeet: true, name: "SZIMPLA", lat: 45.864760, lng: 25.791046, zIndex: 100},
    {city:"SFÂNTU GHEORGHE", isSpecialKozelMeeet: true, name: "SEPSIFOOD", lat: 45.8792783, lng: 25.7995271, zIndex: 100},
    {city:"TÂRGOVIȘTE", isSpecialKozelMeeet: true, name: "TOWERS", lat: 44.929067, lng: 25.458916, zIndex: 100},
    {city:"TÂRGOVIȘTE", isSpecialKozelMeeet: true, name: "TOWERS 2", lat: 44.927688, lng: 25.458094, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: true, name: "THE TEMPLE BAR", lat: 44.327736, lng: 23.799890, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: true, name: "AMBRA ROVINE", lat: 44.321210, lng: 23.819502, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: true, name: "DOWN TOWN", lat: 44.316732, lng: 23.799183, zIndex: 100},
    {city:"CRAIOVA", isSpecialKozelMeeet: true, name: "SNOBI", lat: 44.304260, lng: 23.798246, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: true, name: "EUROMEDICAL PROVIDER (CBT)", lat: 47.163056, lng: 27.58213, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: true, name: "ATELIERUL DE BERE", lat: 47.16029, lng: 27.589635, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: true, name: "CORSO TERASA", lat: 47.167237, lng: 27.578245, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: true, name: "DOPPIO ZERO", lat: 47.176470, lng: 27.579813, zIndex: 100},
    {city:"IAȘI", isSpecialKozelMeeet: true, name: "BERARIUM", lat: 47.158316, lng: 27.589700, zIndex: 100},
    {city:"BAIA MARE", isSpecialKozelMeeet: true, name: "PODUL VIILOR 9", lat: 47.660885, lng: 23.58028, zIndex: 100},
    {city:"BAIA MARE", isSpecialKozelMeeet: true, name: "LA TOUR", lat: 47.658605, lng: 23.581035, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: true, name: "OFFICE CLUB", lat: 46.543182, lng: 24.564177, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: true, name: "TRANZIT", lat: 46.545191, lng: 24.556737, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: true, name: "DOME", lat: 46.547035, lng: 24.56404, zIndex: 100},
    {city:"TÂRGU MUREȘ", isSpecialKozelMeeet: true, name: "CASA BERII", lat: 46.552214, lng: 24.557047, zIndex: 100},
    {city:"PLOIEȘTI", isSpecialKozelMeeet: true, name: "TRES OLIVOS", lat: 44.941004, lng: 26.028227, zIndex: 100},
    {city:"PLOIEȘTI", isSpecialKozelMeeet: true, name: "JAZZ", lat: 44.941004, lng: 26.028227, zIndex: 100},
    {city:"SIBIU", isSpecialKozelMeeet: true, name: "MUSIC PUB", lat: 45.798741, lng: 24.151824, zIndex: 100},
    {city:"SIBIU", isSpecialKozelMeeet: true, name: "THE CORNER", lat: 45.791753, lng: 24.139148, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: true, name: "CURTEA BERARILOR LA FABRICA", lat: 45.748798, lng: 21.22699, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: true, name: "CURTEA BERARILOR LA HAN", lat: 45.761451, lng: 21.248916, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: true, name: "RIVIERE", lat: 45.748798, lng: 21.22699, zIndex: 100},
    {city:"TIMIȘOARA", isSpecialKozelMeeet: true, name: "NORA", lat: 45.756015, lng: 21.231795, zIndex: 100},
]