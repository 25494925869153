import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useCodeAccessLpStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
            paddingTop: theme.spacing(5),
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            [theme.breakpoints.down('lg')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }
        },
        item: {
            display: "flex",
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
                marginTop: theme.spacing(5),
            }
        },
        boxBorderWithCenterTitle: {
            borderTopWidth: '3px',
            borderTopColor: theme.colors.ivory_beige,
            borderTopStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: '100%',
        },
        centerTopTitle: {
            color: theme.colors.ivory_beige,
            fontFamily: theme.fonts.apex,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '25px',
            fontSize: '3vw',
            marginTop: '-1.5vw',
            textAlign: "center",
            backgroundColor: theme.colors.dark_chocolate,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            lineHeight: 1,
            [theme.breakpoints.down('lg')]: {
                letterSpacing: '10px',
            }
        },
        logoKozelMeet: {
            paddingBottom: theme.spacing(5),
            width: '75%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
        },
        title: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.ivory_beige,
            fontSize: 'max(3vh,3vw)',
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            lineHeight: 1.3,
        },
        borderDotted: {
            borderRadius: '25px',
            borderStyle: "dotted",
            borderColor: theme.colors.desert_sand_gold,
            borderWidth: "10px",
            padding: theme.spacing(5),
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
                borderWidth: "5px",
            }
        },
        subContainer: {
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        },
        subItem: {
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingBottom: theme.spacing(2),
            }
        },
        boxTitle: {
            flexDirection: 'column',
        },
        text: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.ivory_beige,
            fontSize: 'max(1.5vh,1.5vw)',
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            lineHeight: 1.3,
        },
        codeText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.ivory_beige,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 'max(1.5vh,1.5vw)',
            textTransform: 'uppercase',
            textAlign: 'center',
            lineHeight: 1.3,
            borderRadius: '25px',
            borderStyle: "dotted",
            borderColor: theme.colors.desert_sand_gold,
            borderWidth: "10px",
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            letterSpacing: theme.spacing(3),
            paddingLeft: theme.spacing(13),
            [theme.breakpoints.down('lg')]: {
                letterSpacing: theme.spacing(2),
                padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
                paddingLeft: theme.spacing(6),
                borderWidth: "5px",
            }
        },
        middleBorderDotted: {
            borderTopStyle: "dotted",
            borderTopColor: theme.colors.desert_sand_gold,
            borderTopWidth: "10px",
            margin: `${theme.spacing(10)} 0`,
            [theme.breakpoints.down('lg')]: {
                margin: `${theme.spacing(1)} 0`,
                borderWidth: "5px",
            }
        },
        subtitle: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.ivory_beige,
            backgroundColor: theme.colors.dark_chocolate,
            fontSize: 'max(2.5vh,2.5vw)',
            textAlign: 'center',
            marginTop: 'min(-1.75vh,-1.75vw)',
            lineHeight: 1,
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
                padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
                marginTop: 'min(-2.5vh,-2.5vw)',
            }
        },
        boxSteps: {
            flexDirection: 'row',
            justifyContent: 'start',
            paddingBottom: theme.spacing(5),
        },
        stepNumber: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.ivory_beige,
            fontSize: 'max(3vh,3vw)',
            textAlign: 'center',
            lineHeight: 1.3,
            borderRadius: '25px',
            borderStyle: "dotted",
            borderColor: theme.colors.desert_sand_gold,
            borderWidth: "10px",
            width: '100px',
            height: '100px',
            minWidth: '100px',
            minHeight: '100px',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            [theme.breakpoints.down('lg')]: {
                width: '50px',
                height: '50px',
                minWidth: '50px',
                minHeight: '50px',
                borderWidth: "5px",
            }
        },
        stepText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.ivory_beige,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 'max(1.5vh,1.5vw)',
            textAlign: 'left',
            paddingLeft: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingLeft: theme.spacing(3),
            }
        },
        stepTextFirst:{
            textAlign: 'right',
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingRight: theme.spacing(3),
            }
        },
        bigText: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.ivory_beige,
            fontSize: 'max(5vh,5vw)',
        },
        img: {
            width: '100%',
        },
    };
})

export default useCodeAccessLpStyles;
