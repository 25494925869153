import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useHelloComponentStyles = createUseStyles((theme: Theme) => {
    return {
        wrapperContainer: {
            padding: `${theme.spacing(5)} ${theme.spacing(15)}`,
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
            }
        },
        container: {
            backgroundColor: theme.colors.latte_cream,
            padding: theme.spacing(5)
        },
        containerItem: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        borderTopDotted: {
          borderTopWidth: '10px',
          borderTopColor: theme.colors.dark_chocolate,
          borderTopStyle: "dotted",
        },
        img: {
            maxWidth: '350px',
            width: '100%',
            height: 'auto',
        },
        title: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            fontWeight: 900,
            fontSize: 'max(5vh,5vw)',
            paddingTop: theme.spacing(5),
        },
        buttonEdit: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(1.5vh,1.5vw)',
            textDecoration: 'underline',
            textTransform: 'none',
            marginBottom: theme.spacing(5),
        },
        textRank: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(2.5vh,2.5vw)',
            paddingTop: theme.spacing(5),
            textAlign: "center",
            textTransform: 'uppercase',
        },
        textAchivementsNextRank: {
            extend: 'textRank',
            fontSize: 'max(1.8vh,1.8vw)',
            paddingTop: theme.spacing(2),
        },
        textNextRankInfo: {
            extend: 'textRank',
            fontSize: 'max(1.3vh,1.3vw)',
            paddingTop: theme.spacing(2),
        },
        statusCoinsBox: {
            borderWidth: '10px',
            borderColor: theme.colors.dark_chocolate,
            borderStyle: "dotted",
            borderRadius: '100%',
            padding: '25px',
            textAlign: "center",
            width: '250px',
            height: '250px',
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: "column",
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('lg')]:{
                width: '150px',
                height: '150px',
            }
        },
        nrCoins: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(7vh,7vw)',
            fontWeight: 900,
            lineHeight: 1
        },
        textCoins: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(3vh,3vw)',
            lineHeight: 1
        },
        textGoats: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(3vh,3vw)',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            lineHeight: 1
        },
        formInput: {
            marginTop: theme.spacing(3),
            maxWidth: '500px',
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
    }
});

export default useHelloComponentStyles;