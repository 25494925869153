import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';

const useRegisterStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
            },
            formContainer: {
                justifyContent: 'center',
                alignItems: "center",
                backgroundColor: theme.colors.latte_cream,
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(5),
                padding: theme.spacing(3),
            },
            thankYouContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: "center",
                backgroundColor: theme.palette.primary.light,
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(5),
                padding: `0 ${theme.spacing(3)} ${theme.spacing(3)}`,
            },
            title: {
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(3vw,3vh)',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                marginTop: theme.spacing(5),
                textAlign: 'center',
            },
            subtitle: {
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(2vw,2vh)',
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                marginTop: theme.spacing(1),
                textAlign: 'center',
                marginBottom: theme.spacing(5),
            },
            successTitle: {
                extend: 'title',
                marginTop: theme.spacing(3),
                fontSize: 'max(5vh,5vw)',
                lineHeight: 1
            },
            successSubtitle: {
                extend: 'subtitle',
                fontSize: 'max(3vh,3vw)',
                marginBottom: theme.spacing(2),
            },
            formInput: {
                marginTop: theme.spacing(3),
                '& .MuiInputBase-root': {
                    fontFamily: theme.fonts.apex,
                    fontSize: '24px',
                    lineHeight: 1,
                    fontWeight: 'bold',
                    borderRadius: theme.spacing(2),
                    letterSpacing: '1px',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.primary.main,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '18px',
                        fontWeight: 'normal',
                    },
                },
                '& .MuiInputLabel-root': {
                    fontFamily: theme.fonts.apex,
                    fontWeight: 'bold',
                    fontSize: '24px',
                    letterSpacing: '1px',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '18px',
                        fontWeight: 'normal',
                    },
                },
                '& .MuiInputLabel-shrink': {
                    fontSize: '24px',
                    letterSpacing: '1px',
                    color: theme.palette.primary.main,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '18px',
                        fontWeight: 'normal',
                    },
                }
            },
            formControlCheckbox: {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                marginTop: theme.spacing(3),
                display: 'flex',
                justifyContent: 'space-between',
                '& p': {
                    textAlign: "left",
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            formControlCheckboxLabel: {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                '& a': {
                    color: 'inherit',
                    textDecoration: 'underline',
                    textTransform: 'uppercase',
                    fontStyle: 'italic',
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    lineHeight: 1,
                },
            },
            submitButton: {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                fontWeight: 'bold',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.main,
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(5),
                padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
                '&:hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.secondary.main,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginTop: theme.spacing(3),
                    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
                },
                [theme.breakpoints.only('xs')]: {
                    fontSize: '14px',
                    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
                }
            },
            textHaveAccount: {
                fontFamily: theme.fonts.apex,
                fontSize: 'max(1.5vw,1.5vh)',
                color: theme.palette.primary.main,
                textAlign: "center",
                '& span': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }
            },
            textJuniorMixologist: {
                fontFamily: theme.fonts.apex,
                fontSize: 'max(2.5vw,2.5vh)',
                color: theme.palette.primary.main,
                textAlign: "center",
            },
            calendar: {
                '& .MuiPickersDay-root': {
                    backgroundColor: 'red',
                    color: 'blue',
                },
                '& .MuiPickersDay-root:hover': {
                    backgroundColor: 'darkred',
                },
                '& .MuiPickersDay-root.Mui-selected': {
                    backgroundColor: 'darkred',
                    color: 'white',
                },
                '& .MuiPickersDay-root.Mui-selected:hover': {
                    backgroundColor: 'darkred',
                },
            },
            successIcon: {
                maxWidth: '350px',
                height: 'auto',
               [theme.breakpoints.down('lg')]: {
                   width: '50vw',
                   height: 'auto',
               }
            },
        }
    }
)

export default useRegisterStyles;