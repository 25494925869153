import React, { SyntheticEvent } from 'react';
import {Box, Modal} from "@mui/material";
import useModalWrapperStyles from "../styles/modalWrapper";
interface ModalWrapperProps {
    isOpen: boolean;
    onRequestClose: (event: SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown' | 'other') => void;
    children: React.ReactNode;
    customClass?: string;
}
const ModalWrapper: React.FC<ModalWrapperProps> =({ isOpen, onRequestClose, children, customClass }) => {

    const modalWrapperStyles = useModalWrapperStyles();

    return (
        <Modal
            open={isOpen}
            onClose={onRequestClose}
            slotProps={{
                backdrop: {
                classes: {
                  root: modalWrapperStyles.myBackdrop,
                },
              }}
            }
            aria-labelledby="personal-data-form-title"
            aria-describedby="personal-data-form-description"
        >
            <Box className={customClass ? customClass : modalWrapperStyles.container}>
                {children}
            </Box>
        </Modal>
    )
};

export default ModalWrapper;