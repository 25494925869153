import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import useJoinUsStyles from "../../styles/kozelMeetBoardGameAccess/join_us";
import InfoIcon from "@mui/icons-material/Info";
import icon_arrow from '../../assets/images/icon_arrow.png';
import clsx from "clsx";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";

const JoinUs: React.FC = () => {
    const styles = useJoinUsStyles();

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} lg={6} className={clsx(styles.item, styles.borderDottedBottom)}>
                <Typography className={styles.title}>Și mai multă distracție?</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.subtitle}>Intră în comunitatea Kozel Meeet!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.text}>Fă-ţi cont pe site, invită-ţi prietenii, câștigă puncte și tranformă-le în premii și experienţe pentru tine și grupul tău!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <NavLink to={paths.register} className={styles.btn}>CREEAZĂ CONT</NavLink>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.textBenefits}><InfoIcon className={styles.contentTeaserListItemIcon}/>înscrierea în comunitate îți aduce automat 50 de puncte</Typography>
            </Grid>
            <img src={icon_arrow} alt={'arrow'} className={styles.arrow}/>
        </Grid>
    );
}

export default JoinUs;
