import {createUseStyles} from 'react-jss';
import {Theme} from '../../themes/default';
import bgWoodDark from '../../../assets/images/ncp/ncp_bg1.jpg';

const useFirstScreenStyles = createUseStyles((theme: Theme) => {
    return {
        title: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(5vw,5vh)',
            fontWeight: 'bold',
            '& .colorSand': {
                color: theme.colors.desert_sand_gold,
            },
            lineHeight: 1.3,
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingBottom: theme.spacing(2),
            }
        },
        subTitle: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.desert_sand_gold,
            fontSize: 'max(2vw,2vh)',
            fontWeight: 'bold',
            lineHeight: 1.3,
            padding: `${theme.spacing(0)} ${theme.spacing(40)} ${theme.spacing(5)} ${theme.spacing(40)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0)}`,
            }
        },
        boxWoodDark: {
            backgroundImage: `url(${bgWoodDark})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            padding: theme.spacing(8),
            display: "flex",
            flexDirection:'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
            position: "relative",
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
            }
        },
        paddingTopNormal: {
            paddingTop: theme.spacing(5),
        },
        textGroupPrize: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            gap: theme.spacing(1/4),
        },
        textCount: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.desert_sand_gold,
            fontSize: 'max(4vw,4vh)',
            fontWeight: 'bold',
            lineHeight: 0.8,
        },
        multiply: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.desert_sand_gold,
            fontSize: 'max(4vw,4vh)',
            fontWeight: 'bold',
            transform: 'rotate(45deg)',
            lineHeight: 0.8,
        },
        textPrizeBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
        },
        textPrizeTitle: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.desert_sand_gold,
            fontSize: 'max(4vw,4vh)',
            fontWeight: 'bold',
            lineHeight: 0.8,
        },
        textPrizeText: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.desert_sand_gold,
            fontSize: 'max(2vw,2vh)',
            fontWeight: 'bold',
            lineHeight: 1,
            letterSpacing: '1px',
        },
        imgPrize: {
            height: 'auto',
            width: '100%',
        },
        promotionInterval:{
            display: 'flex',
            justifyContent: "space-around",
            alignItems: 'center',
            alignContent: 'center',
            background: theme.colors.desert_sand_gold,
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]:{
                flexDirection: 'column'
            }
        },
    }
});

export default useFirstScreenStyles;