import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {getPersonalData} from "../profile/personalDataProfileSlice";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {showNotification} from "../notification/notificationSlice";
import {ApiFailResponse, ApiSuccessResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';
import {getCoins} from "../profile/profileSlice";

interface AuthState {
    isAuthenticated: boolean;
    accessToken: string | null;
    refreshToken: string | null;
    error: string;
    loading: boolean;
    isLoginModalOpen: boolean;
    isRegisterModalOpen: boolean;
    isPersonalDataModalOpen: boolean;
    hasIdentity: boolean;
}

const initialState: AuthState = {
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    error: '',
    loading: false,
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isPersonalDataModalOpen: false,
    hasIdentity: false,
};

interface AuthArgs {
    phone: string;
    password: string;
    keepLoggedIn: boolean;
}

interface PersonalDataArgs {
    // firstName: string;
    // lastName: string;
    email: string | null;
    birthdate: string;
    // accordMarketing: boolean;
    // accordMarketingSms: boolean;
    accordMarketingEmail: boolean;
}

export const login = createAsyncThunk<
    any,
    AuthArgs,
    { rejectValue: ApiFailResponse }
>(
    'login/login',
    async ({phone, password, keepLoggedIn}: AuthArgs, thunkAPI) => {
        try {
            const response = await apiInstance.post<ApiSuccessResponse>(apiEndpoints.login, {
                phone,
                password,
            });

            const {accessToken, refreshToken} = response.data.data;

            let hasIdentity = false;
            if (keepLoggedIn) {
                localStorage.setItem('access_token', accessToken);
                localStorage.setItem('refresh_token', refreshToken);
                localStorage.setItem('keep_logged_in', 'true');
            } else {
                sessionStorage.setItem('access_token', accessToken);
                sessionStorage.setItem('refresh_token', refreshToken);
                localStorage.removeItem('keep_logged_in');
            }

            return {accessToken: accessToken, refreshToken: refreshToken, hasIdentity: hasIdentity};

        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);


export const autoLogin = createAsyncThunk(
    'login/autoLogin',
    async (_, thunkAPI) => {

        const keepLoggedIn = localStorage.getItem('keep_logged_in') === 'true';
        const storage = keepLoggedIn ? localStorage : sessionStorage;

        const accessToken = storage.getItem('access_token');
        const refreshToken = storage.getItem('refresh_token');

        if (accessToken && refreshToken) {
            return {accessToken, refreshToken};
        } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('keep_logged_in');
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');
        }
    }
);

export const updateAccessToken = createAsyncThunk(
    'login/updateToken',
    async (_, thunkAPI) => {
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            const response = await apiInstance.post<ApiSuccessResponse>(apiEndpoints.refresh, {refreshToken});

            const {access_token, refresh_token} = response.data.data;


            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('keep_logged_in', 'true');

            return {access_token, refresh_token};
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
)

export const validateToken = createAsyncThunk(
    'login/validateToken',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get<ApiSuccessResponse>(apiEndpoints.validateToken);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
)

export const updatePersonalData = createAsyncThunk(
    'contact/updatePersonalData',
    async ({
               // firstName,
               // lastName,
               email,
               birthdate,
               // accordMarketing,
               // accordMarketingSms,
               accordMarketingEmail
           }: PersonalDataArgs, thunkAPI) => {
        try {
            const response = await apiInstance.post<ApiSuccessResponse>(apiEndpoints.contactUpdatePersonalData, {
                // firstName,
                // lastName,
                email,
                birthdate,
                // accordMarketing,
                // accordMarketingSms,
                accordMarketingEmail,
            });

            await thunkAPI.dispatch(getPersonalData());
            thunkAPI.dispatch(showNotification({message: response.data.message, variant: 'success'}));
            thunkAPI.dispatch(getCoins());
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('keep_logged_in');
            sessionStorage.removeItem('access_token');

            return initialState;
        },
        openLoginModal(state) {
            state.isLoginModalOpen = true;
        },
        closeLoginModal(state) {
            state.isLoginModalOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.accessToken = action.payload.accessToken
                state.refreshToken = action.payload.refreshToken
                state.isAuthenticated = true;
                state.isLoginModalOpen = false;
            })
            .addCase(login.rejected, (state, action: PayloadAction<ApiFailResponse | undefined>) => {
                state.loading = false;
                state.error = action?.payload?.message || 'Failed to login. Please check your code.';
                // state.isLoginModalOpen = true;
            })
            .addCase(autoLogin.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(autoLogin.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.isLoginModalOpen = false;
            })
            .addCase(autoLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = 'Failed to auto login.';
            })
            .addCase(updateAccessToken.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(updateAccessToken.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.accessToken = action.payload.access_token;
                state.refreshToken = action.payload.refresh_token;
                state.isLoginModalOpen = false;
            })
            .addCase(updateAccessToken.rejected, (state, action) => {
                state.loading = false;
                state.error = 'Failed to keep login.';
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('keep_logged_in');
            })
            .addCase(validateToken.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(validateToken.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = action.payload.data.valid
            })
            .addCase(validateToken.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
            })
            .addCase(updatePersonalData.pending, state => {
                state.loading = true;
            })
            .addCase(updatePersonalData.fulfilled, state => {
                state.loading = false;
                state.hasIdentity = true;
                state.isPersonalDataModalOpen = false;
            })
            .addCase(updatePersonalData.rejected, state => {
                state.loading = false;
                state.hasIdentity = false;
            })
        ;
    },
});

export const {
    logout,
    openLoginModal,
    closeLoginModal,
} = authSlice.actions;

export default authSlice.reducer;
