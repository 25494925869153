import React, {useEffect, useState} from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import TeaserFrame2 from "../TeaserFrame2";
import icon_my_team from '../../assets/images/my_group/icon_my_team.png'
import logo_untold from '../../assets/images/logo_untold.png'
import useTeaserInviteFriends from "../../styles/my_group/teaserInviteFriends";
import theme from "../../styles/themes/default";
import GroupInvite from "./GroupInvite";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import PopupComponent from "../kozel/PopupComponent";
import clsx from "clsx";
import useGroupInvite from "../../styles/my_group/groupInvite";
import {useLocation} from "react-router-dom";
import {joinGroupKey} from "../RedirectJoinGroupCOmponent";
import {joinContactGroup} from "../../features/profile/myGroupSlice";

const TeaserInviteFriends: React.FC = () => {
    const teaserInviteFriends = useTeaserInviteFriends();
    const stylesJoinPopup = useGroupInvite();
    const dispatch = useAppDispatch();
    const [isOpenPopupInvite, setIsOpenPopupInvite] = useState<boolean>(false);
    const [isOpenPopupJoin, setIsOpenPopupJoin] = useState<boolean>(false);
    const [joinReferralCode, setJoinReferralCode] = useState<string>('')
    const {id, members, errorsJoin} = useAppSelector((state) => state.myGroup);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const joinCode = searchParams.get(joinGroupKey);

    useEffect(() => {
        setJoinReferralCode(joinCode || '');
        if (joinCode) {
            setIsOpenPopupJoin(true);
        }
    }, [joinCode])
    const handleClosePopup = () => {
        setIsOpenPopupInvite(false);
    };
    const handleOpenPopup = () => {
        setIsOpenPopupInvite(true);
    };
    const handleOpenPopupJoinGroup = () => {
        setIsOpenPopupJoin(true);
    }
    const handleClosePopupJoinGroup = () => {
        setIsOpenPopupJoin(false);
    }

    const handleJoinGroup = () => {
        if (joinReferralCode !== '') {
            dispatch(joinContactGroup({referralCode: joinReferralCode}));
            return;
        }

    }
    const checkNrMembers = () => {
        if (!members) {
            return 0;
        }

        return members.length;
    }
    return (
        <Grid container className={teaserInviteFriends.container}>
            <Grid item xs={12} className={teaserInviteFriends.containerItem}>
                <TeaserFrame2
                    title={<Typography className={teaserInviteFriends.teaserTitle}>Mixul meu de prieteni</Typography>}
                    titleMarginTop={'min(-3vh, -3vw)'}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                >
                    <Grid container className={teaserInviteFriends.teaserContentContainer}>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <img src={icon_my_team} alt={'createGroup'}
                                 className={teaserInviteFriends.teaserContentImage}/>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Typography className={teaserInviteFriends.teaserContentDescription}>Kozel se savurează cel
                                mai bine împreună!</Typography>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Typography className={teaserInviteFriends.teaserContentDescription}>
                                Invită-ţi gașca de prieteni pe kozel.ro, formează-ţi mixul și bucuraţi-vă de premii și
                                experienţe ţapene la UNTOLD.</Typography>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <img src={logo_untold} alt={'logo_untold'}
                                 className={teaserInviteFriends.teaserContentImage}/>
                        </Grid>
                        <Grid item xs={12} lg={checkNrMembers() !== 0 ? 12 : 6} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Button onClick={handleOpenPopup} className={teaserInviteFriends.teaserContentButtonInvite}>
                                INVITĂ PRIETENI
                            </Button>
                        </Grid>
                        { checkNrMembers() === 0 && <Grid item xs={12} lg={6} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Button onClick={handleOpenPopupJoinGroup}
                                    className={teaserInviteFriends.teaserContentButtonInvite}>
                                ALĂTURĂ-TE PRIETENILOR TĂI
                            </Button>
                        </Grid>}
                    </Grid>
                </TeaserFrame2>
            </Grid>
            <GroupInvite isOpenPopup={isOpenPopupInvite} handleClosePopup={handleClosePopup}/>
            {!id && <PopupComponent isOpen={isOpenPopupJoin} onClose={handleClosePopupJoinGroup}>
                <Grid container className={stylesJoinPopup.container}>
                    <Grid item xs={12} className={stylesJoinPopup.containerItem}>
                        <Typography className={stylesJoinPopup.text}>Alătură-te prietenilor tăi în comunitatea Kozel și
                            jucați-vă impreună!</Typography>
                    </Grid>
                    <Grid item xs={12} className={clsx(stylesJoinPopup.containerItem, stylesJoinPopup.MT5)}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label={'Referral code'}
                            value={joinReferralCode}
                            onChange={e => setJoinReferralCode(e.target.value)}
                            className={stylesJoinPopup.inputReferralField}
                            aria-readonly={true}
                            error={!!errorsJoin}
                            helperText={errorsJoin}
                        />
                    </Grid>
                    <Grid item xs={12} className={clsx(stylesJoinPopup.containerItem, stylesJoinPopup.MT5)}>
                        <Button className={stylesJoinPopup.button} onClick={handleJoinGroup}>
                            Alătură-te
                        </Button>
                    </Grid>
                </Grid>
            </PopupComponent>}
        </Grid>
    );
}

export default TeaserInviteFriends;