// importImages.ts
import dscf0101 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0101.jpg';
import dscf0106 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0106.jpg';
import dscf0108 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0108.jpg';
import dscf0123 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0123.jpg';
import dscf0307 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0307.jpg';
import dscf0332 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0332.jpg';
import dscf0333 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0333.jpg';
import dscf0340 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0340.jpg';
import dscf0352 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0352.jpg';
import dscf0355 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0355.jpg';
import dscf0356 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0356.jpg';
import dscf0358 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0358.jpg';
import dscf0362 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0362.jpg';
import dscf0365 from '../../assets/images/kozel_meet/board_game/carousel/DSCF0365.jpg';

const images = [
    {src: dscf0101, alt: 'dscf0101',},
    {src: dscf0106, alt: 'dscf0106',},
    {src: dscf0108, alt: 'dscf0108',},
    {src: dscf0123, alt: 'dscf0123',},
    {src: dscf0307, alt: 'dscf0307',},
    {src: dscf0332, alt: 'dscf0332',},
    {src: dscf0333, alt: 'dscf0333',},
    {src: dscf0340, alt: 'dscf0340',},
    {src: dscf0352, alt: 'dscf0352',},
    {src: dscf0355, alt: 'dscf0355',},
    {src: dscf0356, alt: 'dscf0356',},
    {src: dscf0358, alt: 'dscf0358',},
    {src: dscf0362, alt: 'dscf0362',},
    {src: dscf0365, alt: 'dscf0365',},
]
export default images;
