import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../stores/store';
import {Button, CircularProgress, FormControlLabel, Grid, Switch, TextField, Typography} from '@mui/material';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import useRegisterStyles from "../styles/register";
import {register, RegisterPayload, sendPhoneCode} from "../features/auth/registerSlice";
import {openLoginModal} from "../features/auth/authSlice";
import goatGif from '../assets/images/goat-this.gif';
import {useLocation} from "react-router-dom";
import {joinGroupKey} from "../components/RedirectJoinGroupCOmponent";

const phoneNumberValidationRule = new RegExp('^(07)[0-9]{8}$', 'i');
const codeValidationRule = new RegExp('^[0-9]{6}$', 'i');

interface StateErrors {
    birthdate: string,
    phone: string,
    code: string,
    password: string,
    confirmPassword: string,
    accordingTerms: string,
}

const RegisterPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const registerStyle = useRegisterStyles();
    const {isCodeSent, error, loading, isRegisterSuccess} = useAppSelector((state) => state.register);
    const [birthdate, setBirthdate] = useState<Dayjs | null>(null);
    const [phoneInput, setPhoneInput] = useState('');
    const [codeInput, setCodeInput] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [termsAccording, setTermsAccording] = useState(false);
    const [isDatePickerBirthdateOpen, setIsDatePickerBirthdateOpen] = useState(false);
    const [errors, setErrors] = useState<Partial<StateErrors>>({});
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const joinCode = searchParams.get(joinGroupKey);

    useEffect(() => {
        if (!isCodeSent) {
            resetState();
        }
    }, [isCodeSent]);

    const resetState = () => {
        setBirthdate(null);
        setPhoneInput('');
        setCodeInput('');
        setPassword('');
        setConfirmPassword('');
        setTermsAccording(false);
        setIsDatePickerBirthdateOpen(false);
        setErrors({});
    }

    const handleSendCode = () => {
        if (validatePayloadSendCode()) {
            dispatch(sendPhoneCode({birthdate: birthdate?.format('YYYY-MM-DD') || '', phone: phoneInput}));
        }
    };
    const validatePhone = (phone: string) => {
        if (!phoneNumberValidationRule.test(phone)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                phone: 'Phone number must begin with 07 and has 10 digits',
            }));
            return false;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
        }));

        return true;
    }
    const validateCode = (code: string) => {
        if (!codeValidationRule.test(code)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                code: 'Code must have 6 digits',
            }));
            return false;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            code: '',
        }));

        return true;
    }
    const validatePassword = (pass: string) => {
        if (pass.length < 8) {
            setErrors(prevErrors => ({
                ...prevErrors,
                password: 'Parola trebuie sa contina cel putin 8 caractere',
            }));
            return false;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            password: '',
        }));

        return true;
    }
    const validateConfirmPassword = (confirmPass: string) => {

        if (password !== confirmPass) {
            setErrors(prevErrors => ({
                ...prevErrors,
                confirmPassword: 'Confirmarea parolei nu este identica cu parola.',
            }));
            return false;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            confirmPassword: '',
        }));

        return true;
    }
    const validateTermsAccording = (termsAccord: boolean) => {
        if (!termsAccord) {
            setErrors(prevErrors => ({
                ...prevErrors,
                accordingTerms: 'Trebuie sa fii de acord cu termenii si conditiile.',
            }));
            return false;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            accordingTerms: '',
        }));

        return true;
    }
    const validatePayloadRegister = () => {
        return validateBirthdate(birthdate)
        && validatePhone(phoneInput)
        && validateCode(codeInput)
        && validatePassword(password)
        && validateConfirmPassword(confirmPassword);
    }

    const validatePayloadSendCode = () => {
        return validateBirthdate(birthdate)
            && validatePhone(phoneInput)
            && validateTermsAccording(termsAccording);
    }
    const handleRegister = () => {
        if (validatePayloadRegister()) {
            const payload: RegisterPayload = {
                birthdate: birthdate?.format('YYYY-MM-DD') || '',
                phone: phoneInput,
                code: codeInput,
                password,
                confirmPassword,
            }
            if (joinCode && joinCode.length > 0) {
                payload.referralCode = joinCode;
            }

            dispatch(register(payload))
        }
    };
    const handleSetPhoneInput = (value: string) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            phone: '',
        }));
        setPhoneInput(value.replace(/[^\d.]/g, ''));
    }
    const handleSetCodeInput = (value: string) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            code: '',
        }));
        setCodeInput(value.replace(/[^\d.]/g, ''));
    }

    const handleBirthdateChange = (newValue: Dayjs | null) => {
        setBirthdate(newValue);
        if(validateBirthdate(newValue)){
            setIsDatePickerBirthdateOpen(false);
        }
    };

    const handleBirthdateFocus = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDatePickerBirthdateOpen(true);
    };

    const validateBirthdate = (date: Dayjs | null) => {
        if (!date) {
            setErrors(prevErrors => ({
                ...prevErrors,
                birthdate: 'Trebuie să aveți cel puțin 18 ani.',
            }));
            return false;
        }
        const today = dayjs();
        const age = today.diff(date, 'year');
        if (age < 18) {
            setErrors(prevErrors => ({
                ...prevErrors,
                birthdate: 'Trebuie să aveți cel puțin 18 ani.',
            }));
            return false;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            birthdate: '',
        }));
        return true;

    };
    const stepValidatePhone = () => !isCodeSent && !isRegisterSuccess;
    const stepCreateAccount = () => isCodeSent && !isRegisterSuccess;

    if (isRegisterSuccess) {
        return (
            <Grid container className={registerStyle.container}>
                <Grid item xs={12} lg={6} xl={4} className={registerStyle.thankYouContainer}>
                    <img src={goatGif} alt={'Kozel'} className={registerStyle.successIcon}/>
                    <Typography className={registerStyle.successTitle}>Goatiful!</Typography>
                    <Typography className={registerStyle.successSubtitle}>Contul tau a fost creat.</Typography>
                    <Typography className={registerStyle.textJuniorMixologist}>Ești <b>Junior Virtual Țapster</b> momentan.</Typography>
                    <Typography className={registerStyle.textHaveAccount}>Loghează-te și mergi la secțiunea Profilul
                        tău, compltează-ți datele și câștigă puncte!</Typography>
                    <Typography className={registerStyle.textHaveAccount}>Apoi mergi la secțiunea Kozel Meeet și
                        crează-ți echipa!</Typography>
                    <Button className={registerStyle.submitButton} onClick={(e) => {
                        dispatch(openLoginModal())
                    }}>Mă loghez</Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className={registerStyle.container}>
            <Grid item xs={12} lg={6} xl={4} className={registerStyle.formContainer}>
                <Typography className={registerStyle.title}>
                    Creare cont
                </Typography>
                <Typography className={registerStyle.subtitle}>
                    {!isCodeSent ? '-Validare Telefon-' : ''}
                </Typography>
                {error && <Typography color="error">{error}</Typography>}

                {stepValidatePhone() && (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: 'center'}}>
                        <DatePicker
                            value={birthdate}
                            onAccept={handleBirthdateChange}
                            label={'Data nașterii*'}
                            open={isDatePickerBirthdateOpen}
                            onOpen={() => setIsDatePickerBirthdateOpen(true)}
                            onClose={() => setIsDatePickerBirthdateOpen(false)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!errors.birthdate,
                                    helperText: errors.birthdate,
                                    className: registerStyle.formInput,
                                    variant: 'outlined',
                                    color: 'primary',
                                    onClick: handleBirthdateFocus,
                                },
                            }}
                            className={registerStyle.calendar}
                        />
                        <TextField
                            label="Phone Number"
                            fullWidth
                            margin="normal"
                            value={phoneInput}
                            className={registerStyle.formInput}
                            onChange={(e) => handleSetPhoneInput(e.target.value)}
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />
                        <FormControlLabel
                            className={registerStyle.formControlCheckbox}
                            labelPlacement={'start'}
                            control={
                                <Switch
                                    checked={termsAccording}
                                    onChange={(e) => setTermsAccording(e.target.checked)}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                            }
                            label={<Typography className={registerStyle.formControlCheckboxLabel}>Am citit integral, am
                                inteles și sunt de acord cu
                                <a target={'_blank'} rel="noreferrer" href={`/pdf/nota-de-informare_prelucrarea-datelor-cu-caracter-personal_site-kozel_obs-mv_v2_30-march-2022.pdf`}> Nota de Informare</a></Typography>} />
                                {/* <a target={'_blank'} rel="noreferrer" href={`/pdf/tc-ro.pdf`}> Termenii și Condițiile</a></Typography>} /> */}
                        {errors.accordingTerms && <Typography color="error">{errors.accordingTerms}</Typography>}
                        <Button onClick={handleSendCode} disabled={loading} className={registerStyle.submitButton}>
                            TRIMITE SMS {loading ? <CircularProgress size={24}/> : ''}
                        </Button>
                    </div>)}
                {stepCreateAccount() && (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: 'center'}}>
                        <TextField
                            label="Verification Code"
                            fullWidth
                            margin="normal"
                            className={registerStyle.formInput}
                            value={codeInput}
                            onChange={(e) => handleSetCodeInput(e.target.value)}
                            error={!!errors.code}
                            helperText={errors.code}
                        />

                        <TextField
                            type={'password'}
                            label="Parola"
                            fullWidth
                            margin="normal"
                            className={registerStyle.formInput}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <TextField
                            type={'password'}
                            label="Confirmare parola"
                            fullWidth
                            margin="normal"
                            className={registerStyle.formInput}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />
                        <Button onClick={handleRegister} disabled={loading} className={registerStyle.submitButton}>
                            {loading ? <CircularProgress size={24}/> : 'Inregistrare'}
                        </Button>
                    </div>
                )}
                <Typography className={registerStyle.textHaveAccount}>Ai cont deja? <br/> <span
                                                                                             onClick={(e) => {
                                                                                                 dispatch(openLoginModal())
                                                                                             }}>Acceseaza contul
                    aici</span></Typography>
            </Grid>
        </Grid>
    );
};

export default RegisterPage;
