import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import useChallengeOpsStyle from "../../styles/challengeUntold/challengeOps";
import kozel_meeet from '../../assets/images/kozel_meet/meet_logo.png';
import TeaserFrame2 from "../TeaserFrame2";
import theme from "../../styles/themes/default";
import clsx from "clsx";
import {useAppSelector} from "../../stores/store";
import {gameNameApi} from "../ChallengeUntold";
import useActivity from "../../hooks/useActivity";
import PopupComponent from "../kozel/PopupComponent";
import map_challenge_untold from "../../assets/images/untold/map_challenge_untold.png";

const ChallengeOps: React.FC = () => {
    const styles = useChallengeOpsStyle();
    const {games} = useAppSelector(state => state.game);
    const {state} = games[gameNameApi];
    const {registerActivity} = useActivity();
    const [isOpenPopupMap, setIsOpenPopupMap] = useState(false);

    useEffect(() => {
        registerActivity({
            name: 'UNTOLD_CHALLENGE_FAIL',
            payload: {},
            callback: () => {
            },
        })
    }, []);

    return <Grid container className={styles.container}>
        <Grid item xs={12} lg={6} className={styles.item}>
            <img src={kozel_meeet} alt={'kozel_meeet'} className={styles.logo} style={{maxWidth: '400px'}}/>
        </Grid>
        <Grid item xs={12} lg={6} className={clsx(styles.item, styles.wrapperTeaserFrame)}>
            <TeaserFrame2
                title={<Typography className={styles.teaserFrameTitle}>Ooops!</Typography>}
                backgroundBoxTitle={theme.colors.dark_chocolate}
                titleMarginTop={'min(-5vh,-5vw)'}
            >
                <Grid container className={styles.teaserContainer}>
                    <Grid item xs={12} className={styles.teaserItem}>
                        <Typography className={styles.teaserTitle}>Ai greșit ordinea. :)</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.teaserItem} flexDirection={'column'}>
                        <Typography className={styles.teaserText}>
                            {state && state.lastStep === null && 'Te rugăm să mergi la Provocarea 1. (scanează totemul corespunzător)'}
                            {state && state.lastStep === 1 && 'Te rugăm să mergi la Provocarea 2. (scanează totemul corespunzător)'}
                            {state && state.lastStep === 2 && 'Te rugăm să mergi la Provocarea 3. (scanează totemul corespunzător)'}
                            {state && state.lastStep === 3 && 'Te rugăm să mergi la Provocarea Finală. (scanează totemul corespunzător)'}
                        </Typography>
                        <Typography className={styles.teaserText}>
                            Cere sprijinul brand ambasadoarei Kozel sau vezi <Button onClick={() => {setIsOpenPopupMap(true)}}>Harta provocărilor aici</Button> și identifică
                            fiecare totem Kozel în ordine.
                        </Typography>
                        <Typography className={styles.teaserTextSuccess}><b>Succes!</b></Typography>
                    </Grid>
                </Grid>
            </TeaserFrame2>
        </Grid>
        <PopupComponent isOpen={isOpenPopupMap} onClose={() => {setIsOpenPopupMap(false)}}>
            <img src={map_challenge_untold} alt={'map_challenge_untold'} className={styles.img}/>
        </PopupComponent>
    </Grid>
}

export default ChallengeOps;