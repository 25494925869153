import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const romaniaTimeZone = 'Europe/Bucharest';
const currentDatetime = dayjs().tz(romaniaTimeZone).format('YYYY-MM-DD HH:mm:ss');
export const isAfterSeptember16th = () => {
    const targetDate = dayjs.tz('2024-09-16 00:01:00', romaniaTimeZone);
    // return true;
    return dayjs(currentDatetime).isAfter(targetDate);
};

export const isBeforeNovember15th = () => {
    const targetDate = dayjs.tz('2024-11-15 23:59:00', romaniaTimeZone);
    // return true;
    return dayjs(currentDatetime).isBefore(targetDate);
};

export const isBeforeAugust31 = () => {
    const targetDate = dayjs.tz('2024-08-31 23:59:00', romaniaTimeZone);
    // return false;
    return dayjs(currentDatetime).isBefore(targetDate);
}

export const isAfterOctober14 = () => {
    const targetDate = dayjs.tz('2024-10-14 00:01:00', romaniaTimeZone);
    // return true;
    return dayjs(currentDatetime).isAfter(targetDate);
}

export const isBeforeOctober27 = () => {
    const targetDate = dayjs.tz('2024-10-27 23:59:00', romaniaTimeZone);
    // return true;
    return dayjs(currentDatetime).isBefore(targetDate);
}