import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import {darken, lighten} from "@mui/material";

const useJoinUsStyles = createUseStyles((theme: Theme) => {
    return {
        container:{
            backgroundColor: theme.colors.latte_cream,
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(5),
            position: "relative",
            [theme.breakpoints.down("lg")]: {
                padding: `${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(0)}`,
            }
        },
        item:{
            paddingBottom: theme.spacing(5),
        },
        borderDottedBottom: {
            borderBottomStyle: "dotted",
            borderBottomColor: theme.colors.dark_chocolate,
            borderBottomWidth: "10px",
            paddingBottom: theme.spacing(5),
            marginBottom: theme.spacing(5),
            width: '100%',
        },
        title:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.dark_chocolate,
            textTransform: 'uppercase',
            textAlign: 'center',
        },
        subtitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vw,2.5vh)',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.colors.dark_chocolate,
        },
        text: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.colors.dark_chocolate,
            fontWeight: theme.typography.fontWeightBold,
        },
        btn: {
            backgroundColor: theme.colors.dark_chocolate,
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.colors.latte_cream,
            padding: `${theme.spacing(1)} ${theme.spacing(25)}`,
            '&:hover': {
                color: lighten(theme.colors.dark_chocolate, 0.5),
                backgroundColor: darken(theme.colors.latte_cream,0.5),
            },
            [theme.breakpoints.down("lg")]: {
                fontSize: 'max(2vw,2vh)',
                padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
            }
        },
        textBenefits: {
            display: 'flex',
            gap: 5,
            fontFamily: theme.fonts.apex,
            color: theme.colors.dark_chocolate,
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            fontSize: `max(1vh,1vw)`,
            alignItems: 'center',
            fontStyle: 'italic',
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
            }
        },
        contentTeaserListItemIcon: {
            width: 'max(2.2vh,2.2vw)',
            height: 'max(2.2vh,2.2vw)',
            marginRight: theme.spacing(1),
        },
        arrow: {
            position: "absolute",
            right: '15%',
            top: '50%',
            transform: 'translateY(-50%) scaleX(-1)',
            width: '100px',
            height: "auto",
            zIndex: 100,
            [theme.breakpoints.down("lg")]: {
                display: 'none',
            }
        },
    };
})

export default useJoinUsStyles;
