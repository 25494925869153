import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import bgImgDark from '../assets/images/bg1.png';
import bgImgLight from '../assets/images/bg2.png';
import ritual_servire_1 from '../assets/images/ritual_servire_1.png';
import ritual_servire_2 from '../assets/images/ritual_servire_2.png';
import ritual_servire_3 from '../assets/images/ritual_servire_3.png';
import ritual_servire_4 from '../assets/images/ritual_servire_4.png';
import ritual_servire_5 from '../assets/images/ritual_servire_5.png';

const useServingRitualStyles = createUseStyles((theme: Theme) => {
    return {
        section:{
            position: "relative",
        },
        subSectionLeft:{
            backgroundImage: `url(${bgImgLight})`,
            padding: theme.spacing(10),
            backgroundSize: 'cover',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3),
                order: 1
            }
        },
        subSectionRight:{
            backgroundImage: `url(${bgImgDark})`,
            padding: theme.spacing(10),
            backgroundSize: 'cover',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3),
                order: 2
            }
        },
        title:{
            fontFamily: theme.fonts.poplar,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(6vw,6vh)',
            textAlign: 'center',
            padding: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(4vw,4vh)',
                padding: theme.spacing(2),
            }
        },
        sectionTitle:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            textAlign: 'left',
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(10),
            '&.contrast': {
                color: theme.palette.secondary.light,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(5.5vw,5.5vh)',
                letterSpacing: '1px',
                paddingBottom: theme.spacing(5),
            }
        },
        sectionSubtitle:{
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: 'left',
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(10),
            '&.contrast': {
                color: theme.palette.secondary.light,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(2.5vw,2.5vh)',
                paddingBottom: theme.spacing(5),
            }
        },
        bgImgSection:{
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(2),
            '& video': {
                height: '65vh',
                margin: '0 auto',
                boxShadow: '10px 10px 10px #0005',
                [theme.breakpoints.down('lg')]:{
                    height: 'auto',
                    width: '100%'
                }
            },
        },
        bgImgRitualServire1: {
            backgroundImage: `url(${ritual_servire_1})`,
        },
        bgImgRitualServire2: {
            backgroundImage: `url(${ritual_servire_2})`,
        },
        bgImgRitualServire3:{
            backgroundImage: `url(${ritual_servire_3})`,
        },
        bgImgRitualServire4:{
            backgroundImage: `url(${ritual_servire_4})`,
        },
        bgImgRitualServire5:{
            backgroundImage: `url(${ritual_servire_5})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        button: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1vw,1vh)',
            padding: `${theme.spacing(2)}`,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            '&:hover,&:active': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
            }

        },
    }
});

export default useServingRitualStyles;