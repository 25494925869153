import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useMyGroupMix = createUseStyles((theme: Theme) => {
    return {
        wrapperContainer: {
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(3),
            }
        },
        container: {
            backgroundColor: theme.colors.latte_cream,
            padding: `${theme.spacing(5)} ${theme.spacing(15)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
            }
        },
        sectionContainerImg: {
          maxWidth: '300px',
            width: '100%',
            height: 'auto',
        },
        containerItem: {
            paddingBottom: theme.spacing(5),
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            flexDirection: 'column',
        },
        borderTopDotted: {
            borderTopWidth: '15px',
            borderTopStyle: 'dotted',
            borderTopColor: theme.colors.dark_chocolate,
            paddingBottom: 0,
            [theme.breakpoints.down('lg')]: {
                borderTopWidth: '10px',
            }
        },
        containerItemTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(6vh, 6vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: 900,
            letterSpacing: 0,
            marginTop: theme.spacing(5),
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(4vh, 4vw)',
            }
        },
        containerItemText: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vh, 3vw)',
            color: theme.colors.dark_chocolate,
            letterSpacing: 0,
            display: "block",
            textAlign: 'left',
            width: '100%',
            '&:last-child': {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh, 2vw)',
                '&:last-child': {
                    marginTop: theme.spacing(2),
                },
            }
        },
        sectionContainer: {
            '& .MuiGrid-root': {
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                flexDirection: 'column',
            }
        },
        sectionGroupView: {
            justifyContent: "center",
            alignItems: "center",
        },
        sectionGroupViewMember: {
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            flexDirection: 'column',
            marginTop: theme.spacing(3),
            '&.noMember': {
                opacity: 0.5
            }
        },
        memberIcon: {
            maxWidth: '150px',
            width: '100%',
            height: 'auto',
        },
        memberName: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vh, 2vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: 'bold',
        },
        memberStatus: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh, 1.5vw)',
            color: theme.colors.dark_chocolate,
        },
    }
});

export default useMyGroupMix;