import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {showNotification} from "../notification/notificationSlice";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse, ApiSuccessResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';
import {LocationItemProps} from "../../components/locations/listLocations";

export interface BoardGamePayloadProps {
    photo: File;
    location: LocationItemProps;
    extra?: { [key: string]: any };
}
export interface BoardGameStateProps {
    loading: boolean;
    success: boolean;
    errorMessage: string|null,
    currentCode: string|null,
    nextCode: string|null,
}

const initialState: BoardGameStateProps = {
    loading: false,
    success: false,
    errorMessage: null,
    currentCode: null,
    nextCode: null,
}

export const insertBoardGame = createAsyncThunk<
    ApiSuccessResponse,
    BoardGamePayloadProps,
    { rejectValue: ApiFailResponse }
>(
    'kozel-meet/insertBoardGame',
    async (payload: BoardGamePayloadProps, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append('photo', payload.photo);
            formData.append('location', JSON.stringify(payload.location));
            if (payload.extra) {
                formData.append('extra', JSON.stringify(payload.extra));
            }

            const response = await apiInstance.post(apiEndpoints.kozelMeetBoardGame, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            thunkAPI.dispatch(showNotification({ message: 'Formular înregistrat cu success', variant: 'success' }));

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);



const boardGameSlice = createSlice({
    name: "kozel-meet-board-game",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(insertBoardGame.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(insertBoardGame.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.errorMessage = null;
                state.currentCode = action.payload?.data?.current?.code || null
                state.nextCode = action.payload?.data?.next?.code || null
            })
            .addCase(insertBoardGame.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.errorMessage = action.payload?.message || null;
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default boardGameSlice.reducer;