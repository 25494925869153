import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiFailResponse, ApiSuccessResponse} from "../../utils/interfaces/apiTypes";
import apiInstance from "../../api/api";
import apiEndpoints from "../../utils/api/endpoints";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {gameNameApi as rollTheBottle} from "../../components/kozelMeet/BottleGame";
import {gameNameApi as challengeUntold} from "../../components/ChallengeUntold";

interface GameInitialStateProps {
    state: any,
    details: any,
    errors: string,
    history: any,
    option: any,
}

interface GamesInitialStateProps {
    loading: boolean;
    games: {
        [key: string]: GameInitialStateProps;
    };
}

const initialStateGame: GameInitialStateProps = {
    state: null,
    details: null,
    errors: '',
    history: '',
    option: '',
}


const gameInitialState: GamesInitialStateProps = {
    loading: false,
    games: {
        [rollTheBottle]: initialStateGame,
        [challengeUntold]: initialStateGame,
    },
}

interface GetGameStatusProps {
    gameName: string
}

interface SetGameStatusProps {
    gameName: string
    payload: any,
}

interface GetGameDetailsProps {
    gameName: string
}

interface ThunkResponse {
    gameName: string;
    responseData: ApiSuccessResponse;
}

interface ThunkFailResponse {
    gameName: string;
    responseData: ApiFailResponse;
}

export const getGameStatus = createAsyncThunk<
    ThunkResponse,
    GetGameStatusProps,
    { rejectValue: ThunkFailResponse }
>(
    'game/get_status',
    async ({gameName}: GetGameStatusProps, thunkAPI) => {
        try {
            const response = await apiInstance.get(`${apiEndpoints.gameGetState}/${gameName}`);

            return {
                gameName,
                responseData: response.data,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({
                gameName,
                responseData: handleAxiosError(error) as ApiFailResponse,
            });
        }
    }
);

export const setGameStatus = createAsyncThunk<
    ThunkResponse,
    SetGameStatusProps,
    { rejectValue: ThunkFailResponse }
>(
    'game/set_status',
    async ({gameName, payload}: SetGameStatusProps, thunkAPI) => {
        try {
            await apiInstance.post(`${apiEndpoints.gameSetState}/${gameName}`, payload);

            return {
                gameName,
                responseData: {data: payload, message: ''}
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({
                gameName,
                responseData: handleAxiosError(error) as ApiFailResponse,
            });
        }
    }
);

export const getGameDetails = createAsyncThunk<
    ThunkResponse,
    GetGameDetailsProps,
    { rejectValue: ThunkFailResponse }
>(
    'game/get_details',
    async ({gameName}: GetGameDetailsProps, thunkAPI) => {
        try {
            const response = await apiInstance.get(`${apiEndpoints.gameGetDetails}/${gameName}`);

            return {
                gameName,
                responseData: response.data,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({
                gameName,
                responseData: handleAxiosError(error) as ApiFailResponse,
            });
        }
    }
);

export const getGameHistory = createAsyncThunk<
    ThunkResponse,
    GetGameDetailsProps,
    { rejectValue: ThunkFailResponse }
>(
    'game/get_history',
    async ({gameName}: GetGameDetailsProps, thunkAPI) => {
        try {
            const response = await apiInstance.get(`${apiEndpoints.gameGetHistory}/${gameName}`);

            return {
                gameName,
                responseData: response.data,
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({
                gameName,
                responseData: handleAxiosError(error) as ApiFailResponse,
            });
        }
    }
);


const gameSlice = createSlice({
    name: 'games',
    initialState: gameInitialState,
    reducers: {
        setOption(state, action) {
            state.games[action.payload.gameName].option = action.payload.responseData
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGameDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGameDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.games[action.payload.gameName].details = action.payload.responseData.data
            })
            .addCase(getGameDetails.rejected, (state, action) => {
                state.loading = false;
                state.games[action.payload?.gameName || ''].errors = action.payload?.responseData.message || ''
            })
            .addCase(getGameStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGameStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.games[action.payload.gameName].state = action.payload.responseData.data
            })
            .addCase(getGameStatus.rejected, (state, action) => {
                state.loading = false;
                state.games[action.payload?.gameName || ''].errors = action.payload?.responseData.message || ''
            })
            .addCase(setGameStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(setGameStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.games[action.payload.gameName].state = action.payload.responseData.data
            })
            .addCase(setGameStatus.rejected, (state, action) => {
                state.loading = false;
                state.games[action.payload?.gameName || ''].errors = action.payload?.responseData.message || ''
            })
            .addCase(getGameHistory.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGameHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.games[action.payload.gameName].history = action.payload.responseData.data
            })
            .addCase(getGameHistory.rejected, (state, action) => {
                state.loading = false;
                state.games[action.payload?.gameName || ''].errors = action.payload?.responseData.message || ''
            })
    }
});

export const {
    setOption
} = gameSlice.actions;

export default gameSlice.reducer;