import React, {useState} from 'react';
import useFormSectionStyles from "../../styles/ncp/form_section";
import {Button, Grid, Typography} from "@mui/material";
import ImageComponent from "../../utils/ImageComponent";
import prizes_ncp_1 from '../../assets/images/ncp/prizes_ncp_1.png';
import prizes_ncp_2 from '../../assets/images/ncp/prizes_ncp_2.png';
import logo_carrefour_dark from '../../assets/images/ncp/logo_carrefour.png';
import logo_carrefour_light from '../../assets/images/ncp/logo_carrefour_light.png';
import logo_auchan_dark from '../../assets/images/ncp/logo_auchan.png';
import logo_auchan_light from '../../assets/images/ncp/logo_auchan_light.png';
import logo_mega_dark from '../../assets/images/ncp/logo_mega.png';
import logo_mega_light from '../../assets/images/ncp/logo_mega_light.png';
import logo_kaufland_dark from '../../assets/images/ncp/logo_kaufland.png';
import logo_kaufland_light from '../../assets/images/ncp/logo_kaufland_light.png';
import logo_profi_dark from '../../assets/images/ncp/logo_profi.png';
import logo_profi_light from '../../assets/images/ncp/logo_profi_light.png';
import logo_penny_dark from '../../assets/images/ncp/logo_penny.png';
import logo_penny_light from '../../assets/images/ncp/logo_penny_light.png';
import logo_anabella_dark from '../../assets/images/ncp/logo_anabella.png';
import logo_anabella_light from '../../assets/images/ncp/logo_anabella_light.png';
import logo_bertis_dark from '../../assets/images/ncp/logo_bertis.png';
import logo_bertis_light from '../../assets/images/ncp/logo_bertis_light.png';
import logo_merkur_dark from '../../assets/images/ncp/logo_mercur.png';
import logo_merkur_light from '../../assets/images/ncp/logo_mercur_light.png';
import logo_safeway_dark from '../../assets/images/ncp/logo_safeway.png';
import logo_safeway_light from '../../assets/images/ncp/logo_safeway_light.png';

import img_card_penny from '../../assets/images/card-penny.png';

import digit_1 from '../../assets/images/digit_1.png';
import digit_2 from '../../assets/images/digit_2.png';
import digit_3 from '../../assets/images/digit_3.png';
import clsx from "clsx";
import FormNcp from "./FormNcp";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const romaniaTimeZone = 'Europe/Bucharest';

export const ncp1 = {
    carrefour: 'carrefour',
    auchan: 'auchan',
    kaufland: 'kaufland',
    mega: 'mega',
    penny: 'penny',
    profi: 'profi',
};
const ncp1Logo = {
    [ncp1.carrefour]: {
        light: logo_carrefour_light,
        dark: logo_carrefour_dark,
    },
    [ncp1.auchan]: {
        light: logo_auchan_light,
        dark: logo_auchan_dark,
    },
    [ncp1.kaufland]: {
        light: logo_kaufland_light,
        dark: logo_kaufland_dark,
    },
    [ncp1.mega]: {
        light: logo_mega_light,
        dark: logo_mega_dark,
    },
    [ncp1.penny]: {
        light: logo_penny_light,
        dark: logo_penny_dark,
    },
    [ncp1.profi]: {
        light: logo_profi_light,
        dark: logo_profi_dark,
    },
}
export const ncp2 = {
    anabella: 'anabella',
    bertis: 'bertis',
    safeway: 'safeway',
    merkur: 'merkur',
}
const ncp2Logo = {
    [ncp2.anabella]: {
        light: logo_anabella_light,
        dark: logo_anabella_dark,
    },
    [ncp2.bertis]: {
        light: logo_bertis_light,
        dark: logo_bertis_dark,
    },
    [ncp2.safeway]: {
        light: logo_safeway_light,
        dark: logo_safeway_dark,
    },
    [ncp2.merkur]: {
        light: logo_merkur_light,
        dark: logo_merkur_dark,
    },
}

const ncpsLogo = {
    ...ncp1Logo,
    ...ncp2Logo,
}

const currentDatetime = dayjs().tz(romaniaTimeZone).format('YYYY-MM-DD HH:mm:ss');
const isAfterAugust5th = () => {
    const targetDate = dayjs.tz('2024-08-05 00:01:00', romaniaTimeZone);
    return dayjs(currentDatetime).isAfter(targetDate);
};

const isBeforeAugust16th = () => {
    const targetDate = dayjs.tz('2024-08-16 23:59:00', romaniaTimeZone);
    return dayjs(currentDatetime).isBefore(targetDate);
};

const getPartners = () => {
    let partners = {};
    if (isBeforeAugust16th()) {
        partners = {
            ...partners,
            ...ncp1,
        }
    }
    if (isAfterAugust5th()) {
        partners = {
            ...partners,
            ...ncp2,
        }
    }

    return partners;
}
export const partners = getPartners();
export const regulamenteParteneriNcp1 = {
    [ncp1.carrefour]: 'Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Carrefour.pdf',
    [ncp1.auchan]: 'Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Auchan.pdf',
    [ncp1.kaufland]: 'Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Kaufland.pdf',
    [ncp1.mega]: 'Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Mega Image.pdf',
    [ncp1.penny]: 'Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Penny.pdf',
    [ncp1.profi]: 'Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival“ - Profi.pdf',
};
export const regulamenteParteneriNcp2 = {
    [ncp2.anabella]: 'Regulament KOZEL Mixeaza Tapan si castiga-TT-august.pdf',
    [ncp2.bertis]: 'Regulament KOZEL Mixeaza Tapan si castiga-TT-august.pdf',
    [ncp2.safeway]: 'Regulament KOZEL Mixeaza Tapan si castiga-TT-august.pdf',
    [ncp2.merkur]: 'Regulament KOZEL Mixeaza Tapan si castiga-TT-august.pdf',
};
export const regulamenteParteneri = {
    ...regulamenteParteneriNcp1,
    ...regulamenteParteneriNcp2,
};
const messageTitleByPartner = {
    [ncp1.carrefour]: 'Kozel și Carrefour te premiază!',
    [ncp1.auchan]: 'Kozel și Auchan te premiază!',
    [ncp1.kaufland]: 'Kozel și Kaufland te premiază!',
    [ncp1.mega]: 'Kozel și Mega-Image te premiază!',
    [ncp1.penny]: 'Kozel și Penny Market te premiază!',
    [ncp1.profi]: 'Kozel și Profi te premiază!',
    [ncp2.anabella]: 'Kozel și Anabella te premiază!',
    [ncp2.bertis]: 'Kozel și Bertis te premiază!',
    [ncp2.safeway]: 'Kozel și Safeway te premiază!',
    [ncp2.merkur]: 'Kozel și Merkur te premiază!',
}
const FormSection: React.FC = () => {
    const formSectionStyle = useFormSectionStyles();
    const [partner, setPartner] = useState(isBeforeAugust16th() ? ncp1.carrefour : ncp2.anabella);
    const handleChangePartner = (partnerNAme: string) => {
        setPartner(partnerNAme)
    }


    const ContentStep2Default = () => (
        <Grid item xs={12} className={formSectionStyle.prizeStep}>
            {isPartnerNcp1() ?
            <Typography className={clsx(formSectionStyle.prizeStepText)}>Înscrie numărul de
                bon, completează formularul, încarcă poza bonului și intri automat în tragerea la sorți pentru unul
                dintre premiile campaniei!</Typography>:
                <Typography className={clsx(formSectionStyle.prizeStepText)}>Înscrie numărul
                    de bon de la casa de macat în formularul de mai jos</Typography>}
            <img src={digit_2} alt={'digit_2'}
                 className={clsx(formSectionStyle.digitImg)}/>
        </Grid>
    );

    const ContentStep2Penny = () => (
        <Grid item xs={12} className={formSectionStyle.prizeStep}>
            <Typography className={clsx(formSectionStyle.prizeStepText)}>Folosește cardul PENNY <br/>în magazin și
                participă automat la campanie</Typography>
            <img src={digit_2} alt={'digit_2'} className={clsx(formSectionStyle.digitImg)}/>
            <img src={img_card_penny} alt={'img_card_penny'} className={formSectionStyle.step2PennyCard}/>
        </Grid>
    );

    const isPartnerNcp1 = () => {
        return Object.values(ncp1).includes(partner);
    }

    return (
        <Grid container>
            <Grid item xs={12} className={formSectionStyle.partnerSelectionBox}>
                <Grid container justifyContent={'space-around'}
                      style={{...(isPartnerNcp1() ? {order: 1} : {})}}
                >
                    {Object.values(partners)
                        .filter((item) => Object.values(ncp1).includes(item+''))
                        .map((item, index) => {
                            return (
                                <Grid key={index} item xs={2}
                                      className={`${formSectionStyle.partnerSelection} ${partner === item ? 'active' : ''} `}>
                                    <Button onClick={e => handleChangePartner(item+'')}>
                                        {partner === item ?
                                            <ImageComponent src={ncpsLogo[item+''].light} alt={`logo_${item+''}_light`}/> :
                                            <ImageComponent src={ncpsLogo[item+''].dark} alt={`logo_${item+''}_dark`}/>}
                                    </Button>
                                </Grid>
                            )
                        })}
                </Grid>
                <Grid container justifyContent={'space-around'}
                      style={{...(!isPartnerNcp1() ? {order: 1} : {})}}
                >
                    {Object.values(partners)
                        .filter(item => Object.values(ncp2).includes(item+''))
                        .map((item, index) => {
                            return (
                                <Grid key={index} item xs={2}
                                      className={`${formSectionStyle.partnerSelection} ${partner === item ? 'active' : ''} `}>
                                    <Button onClick={e => handleChangePartner(item+'')}>
                                        {partner === item ?
                                            <ImageComponent src={ncpsLogo[item+''].light} alt={`logo_${item+''}_light`}/> :
                                            <ImageComponent src={ncpsLogo[item+''].dark} alt={`logo_${item+''}_dark`}/>}
                                    </Button>
                                </Grid>
                            )
                        })}
                </Grid>
            </Grid>
            <Grid item xs={12} className={formSectionStyle.bgWoodDark}>
                <Grid container>
                    <Grid item xs={12} className={formSectionStyle.titlePromotionBox}>
                        <Typography
                            className={formSectionStyle.titlePromotion}>{messageTitleByPartner[partner]}</Typography>
                        {isPartnerNcp1() ?
                            <Typography className={formSectionStyle.subTitlePromotion}>1 iulie - 16 august
                                2024</Typography> :
                            <Typography className={formSectionStyle.subTitlePromotion}>5 august - 31 august
                                2024</Typography>}
                    </Grid>
                    <Grid item xs={12} className={formSectionStyle.bannerPrizes}>
                        {isPartnerNcp1() ?
                            <img src={prizes_ncp_1} alt={'prizes_ncp_1'}/> :
                            <img src={prizes_ncp_2} alt={'prizes_ncp_2'}/>}
                    </Grid>
                    <Grid item xs={12} className={formSectionStyle.prizeStep}>
                        <img src={digit_1} alt={'digit_1'} className={formSectionStyle.digitImg}/>
                        {isPartnerNcp1() ?
                            <Typography className={formSectionStyle.prizeStepText}>Cumpară Kozel Premium sau Kozel Dark
                                în
                                orice combinație, de minim 25 lei pe un singur bon fiscal.</Typography> :
                            <Typography className={formSectionStyle.prizeStepText}>Cumpără de minim 10 LEI Kozel Premium
                                sau
                                Kozel Dark</Typography>}
                    </Grid>
                    {partner === ncp1.penny ? <ContentStep2Penny/> : <ContentStep2Default/>}
                    <Grid item xs={12} className={formSectionStyle.prizeStep}>
                        <img src={digit_3} alt={'digit_3'} className={formSectionStyle.digitImg}/>
                        {isPartnerNcp1() ?
                            <Typography className={formSectionStyle.prizeStepText}>Păstrează bonul fiscal pentru
                                validarea
                                premiului în cazul în care vei fi declarat potențial câștigător.</Typography> :
                            <Typography className={formSectionStyle.prizeStepText}>Și poți câștiga prin tragere la sorți
                                unul dintre premiile campaniei</Typography>}
                    </Grid>
                </Grid>
                <Grid container className={formSectionStyle.containerFormNcp}>
                    <Grid item md={8} xs={12} className={formSectionStyle.itemFormNcp}>
                        <FormNcp partner={partner}/>
                    </Grid>
                </Grid>
                <Grid container>

                    {isPartnerNcp1() ?
                        <Grid item xs={12} className={formSectionStyle.aboutPromotionSection}>
                            <Typography className={formSectionStyle.aboutPromotionText}>Durata
                                promoției: <b>01.07.2024-16.08.2024</b>.
                                Consultă gratuit regulamentul promoției <a
                                    href={`/pdf/2024/${regulamenteParteneri[partner]}`} target="_blank" rel="noreferrer"
                                    style={{textDecoration: 'underline'}}>aici</a>.</Typography>
                            <Typography className={formSectionStyle.aboutPromotionText}>Numărul total de premii este de
                                950,
                                cu o valoare totală netă comercială estimativă
                                de 398.643,46 lei cu tva inclus, premiile se acordă prin tragere la sorti.
                                Lista locațiilor participante poate fi consultată in regulamentul companiei</Typography>
                        </Grid> :

                        <Grid item xs={12} className={formSectionStyle.aboutPromotionSection}>
                            <Typography className={formSectionStyle.aboutPromotionText}>Durata
                                promoției: <b>05.08.2024-31.08.2024</b>.
                                Consultă gratuit regulamentul promoției <a
                                    href={`/pdf/2024/${regulamenteParteneri[partner]}`} target="_blank" rel="noreferrer"
                                    style={{textDecoration: 'underline'}}>aici</a>.</Typography>
                            <Typography className={formSectionStyle.aboutPromotionText}>Numărul total de premii este 20,
                                cu o valoare totală netă comercială estimativă de 3.646,61 RON fără TVA. Premiile se
                                acordă prin tragere la sorți. Lista locațiilor participante poate fi consultată în
                                regulamentul campaniei. Imaginile premiilor sunt cu titlu de prezentare.</Typography>
                        </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormSection;