import {createUseStyles} from 'react-jss';
import {Theme} from '../../themes/default';
import pastThursday from '../../../assets/images/digit_1.png';
import currentThursday from '../../../assets/images/digit_2.png';
import futureThursday from '../../../assets/images/digit_3.png';

const useCalendarKozelMeetStyles = createUseStyles((theme: Theme) => {
        return {
            calendarHeader: {
                color: theme.colors.dark_chocolate,
                '& .fc-col-header-cell': {
                    fontFamily: theme.fonts.poplar,
                    fontWeight: theme.typography.fontWeightRegular,
                    backgroundColor: theme.colors.dark_chocolate,
                    border: '1px solid #3B2F2F',
                    color: theme.colors.ivory_beige,
                },
                '& .fc': {
                    borderColor: theme.colors.dark_chocolate,
                    border: '1px solid #3B2F2F',
                    '& .fc-day-disabled': {
                        backgroundColor: theme.colors.ivory_beige,
                    },
                    '&  .fc-daygrid-day': {
                        fontFamily: theme.fonts.poplar,
                        border: '1px solid #3B2F2F',
                        '&.fc-day-today': {
                            backgroundColor: theme.colors.ivory_beige,
                        },
                        '& .fc-daygrid-day-number': {
                            display: 'flex',
                            width: '100%',
                        }
                    }
                }
            },
            dayContainer: {
                position: "relative",
            },
            dayItem: {

            },
            imgKozel: {
                position: 'absolute',
                left: 0,
                top: 0,
                maxHeight: '5vw',
                maxWidth: '5vw',
                width: '100%',
                height: "auto",
            },
            imgSplashKozel: {                
                maxWidth: '100%',
                height: "auto",
            },
            dateDay: {
                position: 'absolute',
                right: 0,
                top: 0,
                fontFamily: theme.fonts.poplar,
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: 'max(2vw,2vh)',
                color: theme.colors.dark_chocolate,
                [theme.breakpoints.down('lg')]: {
                    fontSize: 'max(1vw,1vh)',
                }
            },
            pastThursday: {
                backgroundColor: theme.colors.ivory_beige,
                backgroundImage: `url(${pastThursday}) !important`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },
            currentThursday: {
                backgroundColor: theme.colors.ivory_beige,
                backgroundImage: `url(${currentThursday}) !important`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },
            futureThursday: {
                backgroundColor: theme.colors.ivory_beige,
                backgroundImage: `url(${futureThursday}) !important`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },
            dayCell: {
                backgroundColor: theme.colors.ivory_beige
            },
            boxActions: {
                justifyContent: 'center',
                gap: theme.spacing(2),
                alignItems: 'center',
                alignContent: 'center',
                paddingBottom: theme.spacing(5)
            },
            buttonPrevMonth: {
                padding: '0',
                '& .MuiSvgIcon-root': {
                    color: theme.colors.dark_chocolate,
                    fontSize: 'max(3vh,3vh)',
                }
            },
            buttonNextMonth: {
                padding: '0',
                '& .MuiSvgIcon-root': {
                    color: theme.colors.dark_chocolate,
                    fontSize: 'max(3vh,3vh)',
                }
            },
            textMonthYear: {
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(3vh,3vh)',
                color: theme.colors.dark_chocolate,
                lineHeight: 1,
            },
            container: {
                padding: theme.spacing(5),
                textAlign: 'center',
                backgroundColor: '#D1A85E',
                marginTop: '12vh',
                minHeight: '495px',
                [theme.breakpoints.down('lg')]:{
                    marginTop: '15vh',
                    padding: theme.spacing(3),
                }
            },
            popupWrapper: {
                backgroundColor: '#E3CB9E',
                padding: theme.spacing(5),
                height: '100%',
                [theme.breakpoints.down('lg')]:{
                    padding: theme.spacing(3),
                }
            },
            goatGif: {
                maxWidth: '300px',
                width: '100%',
                marginTop: 'min(-25vh,-25vw)',
                maxHeight: '350px',
                height: 'auto',
                '@media (min-width: 720px)': {
                    fontSize: '1.5vh',
                    marginTop: '-25vh',
                }
            },
            popupTitle: {
                fontFamily: 'PoplarKozel, sanserif',
                color: '#413121',
                fontSize: 'max(3vh, 3vw)',
                textTransform: 'uppercase',
                lineHeight: '1.2',
                marginBottom: '5vh',
                [theme.breakpoints.down('lg')]: {
                    marginBottom: '3vh',
                    fontSize: '3.5vh',
                }
            },
            popupSubtitle: {
                fontFamily: 'PoplarKozel, sanserif',
                color: '#413121',
                fontSize: 'max(2vh, 2vw)',
                textTransform: 'uppercase',
                lineHeight: '1.2',
                marginBottom: '1vh',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '2.5vh',
                }
            },
            button: {
                fontFamily: theme.fonts.poplar,
                padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
                fontSize: 'max(1.8vh,1.8vw)',
                backgroundColor: `${theme.palette.secondary.main}`,
                color: `${theme.palette.primary.main}`,
                marginTop: theme.spacing(6),
                letterSpacing: '1px',
                textTransform: 'none',
                '&:hover,&:active': {
                    backgroundColor: `${theme.palette.primary.main}`,
                    color: `${theme.palette.secondary.main}`,
                    border: `1px solid ${theme.palette.secondary.main}`,
                }
            },
            buttonContrast: {
                backgroundColor: `${theme.palette.primary.main}`,
                color: `${theme.palette.secondary.main}`,
                '&:hover,&:active': {
                    backgroundColor: `${theme.palette.secondary.main}`,
                    color: `${theme.palette.primary.main}`,
                    border: `1px solid ${theme.palette.primary.main}`,
                }
            },
        };
    }
)

export default useCalendarKozelMeetStyles;
