import React from "react";
import {Grid, Typography} from "@mui/material";
import useBannerVoceaRoStyle from "../../styles/voceaRomaniei/banner_vocea_romaniei";
import logo_late_cream from "../../assets/images/logo_late_cream.png";
import logo_vocea_romaniei from "../../assets/images/vocea_romaniei/logo_vocea_romaniei.png";
import teaser_img from "../../assets/images/vocea_romaniei/goat.png";
import clsx from "clsx";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";

const BannerVoiceRo: React.FC = () => {
    const styles = useBannerVoceaRoStyle();
    return (
        <Grid container>
            <Grid item xs={12} className={clsx(styles.item, styles.bg_vocea_romaniei)}>
                <Grid container
                      className={clsx(styles.boxBannerVoceaRomaniei)}>
                    <Grid item xs={12} lg={5} className={styles.bannerWrapperItem}>
                        <Grid container className={styles.bannerContainer}>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <img src={logo_late_cream} alt={'logo'} className={styles.bannerLogo}/>
                                <Typography className={styles.bannerTitle}>
                                    te trimite la
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <img src={logo_vocea_romaniei} alt={'logo vocea romaniei'}
                                     className={styles.bannerLogoVoiceRo}/>
                            </Grid>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <Typography className={styles.bannerText}>Înscrie-te în tragerea la sorți
                                    pentru biletele la semifinala și finala Vocea României!</Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.bannerItem}>
                                <NavLink to={paths.voceaRomaniei} className={styles.bannerButton}>MĂ ÎNSCRIU</NavLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5} className={styles.bannerWrapperItem}>
                        <img src={teaser_img} alt={'teaser_vocea_romaniei'}
                             className={styles.teaser_img}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default BannerVoiceRo;