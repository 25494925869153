import React, {useCallback, useEffect, useState} from 'react';
import { GoogleMapLocation, GoogleMapComponent } from '../../components/GoogleMapComponent';
import { listLocation } from '../../components/locations/specialLocations';
import { Grid, Typography } from '@mui/material';
import crown from '../../assets/images/icons/crown.png';
import CalendarKozelMeet from '../kozelMeetBoardGame/whatFindHere/CalendarKozelMeet';
import useSpecialLocationStyles from '../../styles/kozelMeet/specialLocations';

const KozelMeetSpecialLocations: React.FC = () => {
    const styles = useSpecialLocationStyles();

    const [locations, setLocations] = useState<GoogleMapLocation[]>([]);
    
    useEffect(() => {
        setLocations(listLocation);
    }, []);

    return  (
        <>
            <GoogleMapComponent locations={locations}/>            
            <Grid container className={styles.container} pt={4}>
                <Grid item xs={12}>
                    <Typography className={styles.title}>Joia Țapănăă!</Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Typography className={styles.title}>Dă start distracției! Te așteptăm joia în
                        locațiile Kozel, cu berea&nbsp;<img src={crown} alt={'crown'} className={styles.crownIcon} />&nbsp;din Cehia, jocuri țapene și
                        oameni faini!</Typography>
                </Grid>
                <Grid item xs={10} lg={8} mt={4}>
                    <CalendarKozelMeet />
                </Grid>
            </Grid>
        </>
    );
};

export default KozelMeetSpecialLocations;
