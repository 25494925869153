import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useFooterScreenStyles = createUseStyles((theme: Theme) => {
        return {
            boxContainer: {
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
            },
            bgLateCream: {
                backgroundColor: theme.colors.latte_cream,
            },
            title: {
                fontFamily: theme.fonts.poplar,
                color: theme.colors.dark_chocolate,
                fontSize: 'max(3vh,3vw)',
                fontWeight: theme.typography.fontWeightBold,
                textAlign: 'center',
                lineHeight: 1.1,
            },
            subtitle: {
                fontFamily: theme.fonts.apex,
                color: theme.colors.dark_chocolate,
                fontSize: 'max(2vh,2vw)',
                fontWeight: theme.typography.fontWeightBold,
                textAlign: 'center',
                lineHeight: 1.1,
            },
            button: {
                fontFamily: theme.fonts.poplar,
                backgroundColor: theme.colors.dark_chocolate,
                textTransform: 'uppercase',
                fontWeight: 900,
                color: theme.colors.latte_cream,
                fontSize: 'max(1.8vh,1.8vw)',
                padding: `${theme.spacing(2)} ${theme.spacing(15)}`,
                marginTop: theme.spacing(5),
                '&:hover': {
                    backgroundColor: theme.colors.latte_cream,
                    color: theme.colors.dark_chocolate,
                },
                [theme.breakpoints.down('lg')]: {
                    marginTop: theme.spacing(3),
                    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
                }
            },
            textBenefits: {
                display: 'flex',
                gap: 5,
                fontFamily: theme.fonts.apex,
                color: theme.colors.dark_chocolate,
                padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
                fontSize: `max(1.5vh,1.5vw)`,
                alignItems: 'center',
                fontStyle: 'italic',
                paddingBottom: theme.spacing(5),
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
                }
            },
            contentTeaserListItemIcon: {
                width: 'max(2.2vh,2.2vw)',
                height: 'max(2.2vh,2.2vw)',
                marginRight: theme.spacing(1),
            },
        };
    }
)

export default useFooterScreenStyles;
