import {createUseStyles} from "react-jss";
import {Theme} from "./themes/default";

const usePersonalDataFormStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center",
            backgroundColor: theme.palette.primary.light,
            margin: theme.spacing(5),
            padding: theme.spacing(3),
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.3vw,2.3vh)',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            marginTop: theme.spacing(2),
            textAlign: 'center',
        },
        subtitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vw,1.5vh)',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        formInput: {
            marginTop: theme.spacing(2),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        formControlCheckbox: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: 'max(1.5vw,1.5vh)',
            letterSpacing: '1px',
            display: 'flex',
            justifyContent: 'space-between',
            '& p': {
                textAlign: "left",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(1vw,1vh)',
                fontWeight: 'normal',
            },
        },
        formControlCheckboxLabel: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: 'max(1.5vw,1.5vh)',
            letterSpacing: '1px',
            marginTop: theme.spacing(1),
            color: theme.palette.primary.main,
            '& a': {
                color: 'inherit',
                textDecoration: 'underline',
                textTransform: 'uppercase',
                fontStyle: 'italic',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(1.5vw,1.5vh)',
                fontWeight: 'normal',
                lineHeight: 1,
            },
        },
        submitButton: {
            fontFamily: theme.fonts.apex,
            fontSize: '24px',
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: theme.spacing(3),
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '14px',
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            }
        },
        cancelButton: {
            fontFamily: theme.fonts.apex,
            fontSize: '24px',
            fontWeight: 'bold',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: theme.spacing(3),
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '14px',
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            }
        },
        descriptionText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.palette.primary.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(5),
            '& a': {
                textDecoration: 'underline',
            }
        },
        errorText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.palette.error.main,
            textAlign: "center",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        }
    }
});

export default usePersonalDataFormStyles;