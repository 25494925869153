import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useLoadingComponentStyles = createUseStyles((theme: Theme) => {
    return {
        loadingContainer: {
            position: 'relative',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        loadingBgGoatGif: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            maxWidth: '300px',
            maxHeight: '300px',
            width: '100%',
            height: 'auto',
        },
        loadingSpinner: {
            borderWidth: '8px',
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderTopWidth: '8px',
            borderTopColor: theme.colors.dark_chocolate,
            borderRadius: '50%',
            maxWidth: '350px',
            maxHeight: '350px',
            width: '100%',
            height: '100%',
            animation: '$spin 1s linear infinite',
        },
        '@keyframes spin': {
            from: {
                transform: 'rotate(0deg)',
            },
            to: {
                transform: 'rotate(360deg)',
            },
        },
    }
});

export default useLoadingComponentStyles;