import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useGameHistoryStyles = createUseStyles((theme: Theme) => {
    return {
        container: {},
        containerItem: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'top',
        },
        containerHistoryList: {
            padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
            maxHeight: '85vh',
            overflow: 'scroll',
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(0)}`,
                justifyContent: 'center',
            }
        },
        containerItemHistoryList: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.colors.dark_chocolate,
            padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
            '&:last-child': {
                borderBottom: "none",
                paddingBottom: 0,
            }
        },
        senderText: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            color: theme.colors.dark_chocolate,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: 'center',
        },
        challengeText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.dark_chocolate,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: 'center',
        },
        receiverText: {
            extend: 'senderText',
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            color: theme.colors.dark_chocolate,
            fontSize: 'max(5vw,5vh)',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(4vw,4vh)',
                paddingBottom: theme.spacing(3),
            }
        },
        togglePopup: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            color: theme.colors.latte_cream,
            backgroundColor: theme.colors.dark_chocolate,
            padding: `${theme.spacing(2)}`,
            fontSize: 'max(1.5vw,1.5vh)',
            textTransform: 'uppercase',
        },

    }
})

export default useGameHistoryStyles;