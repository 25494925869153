import React, {useEffect, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import useChallengeFinalStyle from "../../styles/challengeUntold/challengeFinal";
import kozel_meeet from "../../assets/images/kozel_meet/meet_logo.png";
import clsx from "clsx";
import TeaserFrame2 from "../TeaserFrame2";
import theme from "../../styles/themes/default";
import image_mixologist from '../../assets/images/my_group/image_mixologist.png'
import useActivity from "../../hooks/useActivity";
import Confetti from 'react-confetti';

const ChallengeFinal: React.FC = () => {
    const componentRef = useRef<HTMLDivElement>(null);
    const styles = useChallengeFinalStyle();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const {registerActivity} = useActivity();

    useEffect(() => {
        if (componentRef.current) {
            const { width, height } = componentRef.current.getBoundingClientRect();
            setDimensions({ width, height });
        }
        registerActivity({
            name: 'UNTOLD_CHALLENGE_FINAL',
            payload: {},
            callback: () => {},
        })
    }, []);

    return (
        <Grid container className={styles.container} ref={componentRef}>
            {dimensions.width !== 0 && dimensions.height !== 0 && <Confetti
                width={dimensions.width}
                height={dimensions.height}
                gravity={0.05}
                recycle={true}
                numberOfPieces={1000}
            />}
            <Grid item xs={12} lg={6} className={styles.item}>
                <img src={kozel_meeet} alt={'kozel_meeet'} className={styles.logo} style={{maxWidth: '400px'}}/>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.motoText}>Întotdeauna e loc pentru încă un prieten!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={clsx(styles.item, styles.wrapperTeaserFrame)}>
                <TeaserFrame2
                    title={<Typography className={styles.teaserFrameTitle}>ȚAPĂN!</Typography>}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                    titleMarginTop={'min(-5vh,-5vw)'}
                >
                    <Grid container className={styles.teaserContainer}>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <Typography className={styles.teaserTitle}>Felicitări! Tu și grupul tău ați rezolvat deja toate provocările!</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem} flexDirection={'column'}>
                            <Typography className={styles.teaserText}>
                                Suntem siguri că te-ai distrat pe cinste alături de echipa ta și prieteni noi!
                            </Typography>
                            <Typography className={styles.teaserText}>
                                <b>În KOZEL MEEET toată lumea este câștigătoare! Marele premiu este însăși distracţia și
                                    conexiunea ȚAPĂNĂ pe care aţi creat-o în timpul provocărilor!</b>
                            </Typography>
                            <Typography className={styles.teaserText}>
                                Pentru a celebra finalizarea provocărilor, lucrul în echipă și conexiunile create aici,
                                te invităm la Kozel Meeet House unde aveți fiecare câte o bere rece pe care să o savurați împreună!
                            </Typography>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
            <Grid item xs={12} lg={4} className={styles.item}>
                <Typography className={styles.barAccessText}>Pentru a avea acces la bar, arată acest mesaj brand
                    ambasadoarei.</Typography>
            </Grid>
            <Grid item xs={12} lg={4} className={styles.item}>
                <Typography className={styles.messageCzech}>Na zdraví!</Typography>
            </Grid>
            <Grid item xs={12} lg={4} className={styles.item}>
                <img src={image_mixologist} alt={'image_mixologist'} className={styles.imgMixologist} />
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <img src={kozel_meeet} alt={'kozel_meeet'} className={styles.logo} style={{maxWidth: '400px'}}/>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.motoText}>Întotdeauna e loc pentru încă un prieten!</Typography>
            </Grid>
        </Grid>
    )
}

export default ChallengeFinal;