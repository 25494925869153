import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import apiInstance from "../../api/api";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse, ApiSuccessResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';

export interface RegisterPayload {
    birthdate: string,
    phone: string,
    code: string,
    password: string,
    confirmPassword: string,
    referralCode?: string,
}

interface RegisterState {
    loading : boolean;
    isCodeSent : boolean;
    error: string;
    isRegisterSuccess : boolean;
}

export interface RegisterSendPhoneCode {
    birthdate: string,
    phone: string,
}


const initialState:RegisterState = {
    loading : false,
    isCodeSent : false,
    isRegisterSuccess: false,
    error: '',

}

export const register = createAsyncThunk(
    'register/register',
    async (payload: RegisterPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post(apiEndpoints.register, payload)

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);


export const sendPhoneCode = createAsyncThunk<
    ApiSuccessResponse,
    RegisterSendPhoneCode,
    { rejectValue: ApiFailResponse }
>(
    'register/sendVerificationCode',
    async (payload: RegisterSendPhoneCode, thunkAPI) => {
        try {
            const response = await apiInstance.post<ApiSuccessResponse>(apiEndpoints.registerSendCode, payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.loading = true;
            })
            .addCase(register.fulfilled, (state) => {
                state.loading = false;
                state.isRegisterSuccess = true;
            })
            .addCase(register.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload?.message
            })
            .addCase(sendPhoneCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendPhoneCode.fulfilled, (state) => {
                state.loading = false;
                state.isCodeSent = true;
            })
            .addCase(sendPhoneCode.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.isCodeSent = false;
                state.error = action.payload?.message
            })
        ;
    },
});

export default authSlice.reducer;