import React, { useState, useEffect } from "react";
import useStyles from "../styles/slider";
interface SliderProps {
    children: React.ReactNode[];
    interval?: number;
}

const Slider: React.FC<SliderProps> = ({ children, interval = 3000 }) => {
    const classes = useStyles();
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === children.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? children.length - 1 : prevIndex - 1
        );
    };

    // Auto-derulare slide-uri la intervale regulate
    useEffect(() => {
        const sliderInterval = setInterval(() => {
            handleNext();
        }, interval);

        // Curățare interval la demontarea componentului
        return () => {
            clearInterval(sliderInterval);
        };
    }, [currentIndex, interval]);

    return (
        <div className={classes.sliderContainer}>
            <div
                className={classes.sliderWrapper}
                style={{ transform: `translateX(-${currentIndex * 100}vw)` }}
            >
                {children.map((child, index) => (
                    <div key={index} className={classes.slide}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Slider;
