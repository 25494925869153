import {createUseStyles} from 'react-jss';
import {Theme} from '../../themes/default';

const useTeaserBeMemberStyles = createUseStyles((theme: Theme) => {
        return {
            teaserTitle: {
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(2vh,2vw)',
                color: theme.colors.latte_cream,
            },
            boxTeaserFrame: {
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(2),
                }
            },
            boxLeft: {
                [theme.breakpoints.up('lg')]: {
                    borderRightStyle: "dotted",
                    borderRightWidth: "10px",
                    borderRightColor: theme.colors.desert_sand_gold,
                },
                [theme.breakpoints.down('lg')]: {
                    borderBottomStyle: "dotted",
                    borderBottomWidth: "10px",
                    borderBottomColor: theme.colors.desert_sand_gold,
                },
            },
            boxRight:{
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5),
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    paddingTop: theme.spacing(2)
                },
            },
            title: {
                fontFamily: theme.fonts.poplar,
                color: theme.colors.latte_cream,
                fontSize: 'max(3vh,3vw)',
                letterSpacing: '1px',
                textTransform:'uppercase',
                lineHeight: 1.3,
            },
            subtitle:{
                fontFamily: theme.fonts.poplar,
                color: theme.colors.latte_cream,
                fontSize: 'max(1.5vh,1.5vw)',
                letterSpacing: '1px',
                textTransform:'uppercase',
                lineHeight: 1,
            },
            goatGif:{
                maxWidth: '65%'
            }
        };
    }
)

export default useTeaserBeMemberStyles;
