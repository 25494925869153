import React from 'react';
import {Grid, Typography} from "@mui/material";
import useThursdayInviteStyles from "../../styles/kozelMeetBoardGame/thursday_invite";
import clsx from "clsx";
import icon_arrow from '../../assets/images/icon_arrow.png';
import icon_clock from '../../assets/images/icons/icon_clock.png';
import icon_calendar from '../../assets/images/icons/icon_calendar.png';

const ThursdayInvite: React.FC = () => {
    const styles = useThursdayInviteStyles();
    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} lg={6} className={styles.boxImg}>
                <img src={icon_clock} alt={'clock'} className={styles.imgIconClock} style={{ maxWidth: '230px', maxHeight: '235px'}}/>
            </Grid>
            <Grid item xs={12} lg={6}  className={styles.boxBorderDots}>
                <Typography className={styles.title}>Kozel Meeet te invită joia în pub-uri </Typography>
                <img src={icon_arrow} alt={'arrow left'} className={styles.arrowLeft} style={{ maxWidth: '138px', maxHeight: '302px'}}/>
                <img src={icon_arrow} alt={'arrow right'} className={clsx(styles.arrowRight)} style={{ maxWidth: '138px', maxHeight: '302px'}} />
            </Grid>
            <Grid item xs={12} lg={6} >
                <Typography className={styles.title}>Ce găsești aici?</Typography>
            </Grid>
            <Grid item xs={12} lg={6} >
                <Typography className={styles.text}>Mixul &#354;APăn Kozel, Jocuri &#354;APENE, povocări și oameni super.
                    Fii gata oricând să mai primești pe cineva la masa ta, în echipă – unde-s mulţi, puterea crește 😎</Typography>
            </Grid>
            <Grid item xs={10} lg={6}>
                <Grid container className={styles.boxSaveToCalendar}>
                    <Grid item xs={10}>
                        <Typography className={styles.subtitle}>Salvează Joia Kozel în calendar
                            și fii nelipsit de la bere, distracție
                            și boardgames!
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <img src={icon_calendar} alt={'icon calendar'} className={styles.imgIconCalendar}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ThursdayInvite;
