import React, { useEffect, useRef, useState } from 'react';
import {Grid, Typography} from "@mui/material";
import clsx from "clsx";
import logoKozelMeeet from '../assets/images/kozel_meet/meet_logo.png';
import icon_user from '../assets/images/icons/icon_user.png';
import icon_100_points from '../assets/images/icons/points_icon.png';
import useKozelMeetBoardGameCongratsStyles from "../styles/kozelMeetBoardGame/kozel_meet_board_game_congrats";
import TeaserFrame2 from "../components/TeaserFrame2";
import {NavLink, useLocation} from "react-router-dom";
import {paths} from "../AppRouters";
import {useAppSelector} from "../stores/store";
import useActivity from '../hooks/useActivity';


const TeaserChildrenNotLogged: React.FC = () => {
    const styles = useKozelMeetBoardGameCongratsStyles();
    return(
        <Grid container className={styles.teaserContentContainer}>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={styles.teaserSubtitle}>MĂ BUCUR CĂ EȘTI AICI.</Typography>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <img src={icon_user} alt={'icon_user'} className={styles.userAddIcon} style={{ maxWidth: '250px', maxHeight: '250px',}}/>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={styles.teaserContentSubtitle}>Creează cont pe site pentru a revendica cele 100 de puncte!</Typography>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <NavLink to={paths.register} className={styles.teaserContentButton}>CREEAZĂ CONT</NavLink>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={clsx(styles.teaserContentTitle, styles.haveFunText)}>Have fun în continuare!</Typography>
            </Grid>
        </Grid>
    );
}
const TeaserChildrenPointPrize: React.FC = () => {
    const {registerActivity} = useActivity(); 
    const styles = useKozelMeetBoardGameCongratsStyles();
    const hasTriggeredActionLog = useRef(false);
    const location = useLocation();

    useEffect(() => {
        if (!hasTriggeredActionLog.current) {
            let activityName = '';

            switch (location.pathname) {
                case paths.kozelMeetBoardGame.game1:
                    activityName = 'KOZEL_MEET_TAP_OCLOCK_GAME_1';
                    break;
                case paths.kozelMeetBoardGame.game2:
                    activityName = 'KOZEL_MEET_TAP_OCLOCK_GAME_2';
                    break;    
                case paths.kozelMeetBoardGame.game3:
                    activityName = 'KOZEL_MEET_TAP_OCLOCK_GAME_3';
                    break;
            }

            registerActivity({
                name: activityName,
                payload: {},
                callback: () => {},
            });

            hasTriggeredActionLog.current = true;
        }
    }, []);

    return(
        <Grid container className={styles.teaserContentContainer}>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={styles.teaserSubtitle}>MĂ BUCUR CĂ EȘTI AICI.</Typography>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <img src={icon_100_points} alt={'icon_100_points'} className={styles.prizeMsgImg} style={{ maxWidth: '400px', maxHeight: '330px' }}/>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={styles.teaserContentTitle}>Felicitări!</Typography>
            </Grid>
            <Grid item xs={12} className={clsx(styles.teaserContentItem, styles.borderDottedBottom)}>
                <Typography className={styles.teaserContentSubtitle}>În plus față de distracția pe care ai câștigat-o cu grupul tău, jucând acest joc, primești 100 de puncte în contul tău, bonus de la Țapul Kozel.</Typography>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={styles.teaserContentSubtitle}>Vezi toate locațiile în care au loc
                    evenimentele Kozel Meeet și vino în fiecare joi cu gașca ta</Typography>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <Typography className={styles.teaserContentTitle}>JOCURI, BERE, VOIE BUNĂ, JOI NE DISTRĂM
                    ÎMPREUNĂ!</Typography>
            </Grid>
            <Grid item xs={12} className={styles.teaserContentItem}>
                <NavLink to={paths.kozelMeet} className={styles.teaserContentButton}>DESCOPERĂ KOZEL MEEET</NavLink>
            </Grid>
        </Grid>
    );
}

const KozelMeetBoardGameCongrats: React.FC = () => {
    const styles = useKozelMeetBoardGameCongratsStyles();
    const {isAuthenticated} = useAppSelector(state => state.auth);
    return (
        <Grid container
              flexDirection={'column'}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"center"}
              className={clsx(styles.bgDarkChoco,styles.container)}
        >
            <Grid item xs={10} lg={4} mt={2} textAlign={'center'}>
                <img src={logoKozelMeeet} alt={'Logo Kozel meeet'} className={styles.logoKozelMeet} style={{ maxWidth: '430px', maxHeight: '235px'}}/>
            </Grid>
            <Grid item xs={10} lg={4} mt={2}>
                <Typography className={styles.subtitle}>Întotdeauna e loc pentru
                    încă un <span className={styles.colorDesertSandGold}>prieten!</span></Typography>
            </Grid>
            <Grid item xs={12} lg={4} mt={8} mb={8} className={styles.boxTeaserFrame2}>
                <TeaserFrame2
                    title={<Typography className={styles.teaserTitle}>ȚAPĂN!</Typography>}
                    titleMarginTop={'min(-3vh,-3vw)'}
                >
                    {isAuthenticated ? <TeaserChildrenPointPrize/> :<TeaserChildrenNotLogged/>}
                </TeaserFrame2>
            </Grid>
        </Grid>
    );
}

export default KozelMeetBoardGameCongrats;
