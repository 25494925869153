import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import useChallenge3Style from "../../styles/challengeUntold/challenge3";
import kozel_meeet from "../../assets/images/kozel_meet/meet_logo.png";
import clsx from "clsx";
import TeaserFrame2 from "../TeaserFrame2";
import theme from "../../styles/themes/default";
import untold_challenge_step_3 from "../../assets/images/untold/untold_challenge_step_3.png";
import imgZar1 from "../../assets/images/untold/zar1.png";
import imgZar2 from "../../assets/images/untold/zar2.png";
import imgZar3 from "../../assets/images/untold/zar3.png";
import imgZar4 from "../../assets/images/untold/zar4.png";
import imgZar5 from "../../assets/images/untold/zar5.png";
import imgZar6 from "../../assets/images/untold/zar6.png";
import {NavLink} from "react-router-dom";
import {paths} from "../../AppRouters";
import useActivity from "../../hooks/useActivity";

const Challenge3: React.FC = () => {
    const styles = useChallenge3Style();
    const {registerActivity} = useActivity();

    useEffect(() => {
        registerActivity({
            name: 'UNTOLD_CHALLENGE_3',
            payload: {},
            callback: () => {},
        })
    }, []);

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} lg={6} className={styles.item}>
                <img src={kozel_meeet} alt={'kozel_meeet'} className={styles.logo} style={{maxWidth: '400px'}}/>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.item}>
                <Typography className={styles.motoText}>Întotdeauna e loc pentru încă un prieten!</Typography>
            </Grid>
            <Grid item xs={12} lg={6} className={clsx(styles.item, styles.wrapperTeaserFrame)}>
                <TeaserFrame2
                    title={<Typography className={styles.teaserFrameTitle}>ȚAPĂN!</Typography>}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                    titleMarginTop={'min(-5vh,-5vw)'}
                >
                    <Grid container className={styles.teaserContainer}>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <Typography className={styles.accessText}>Ai accesat a treia (și ultima) provocare. La sfârșit ai parte de o surpriză ȚAPĂNĂĂ!</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <img src={untold_challenge_step_3} alt={'untold_challenge_step_3'}
                                 className={styles.imgStep}
                                 style={{maxWidth: '240px'}}/>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <Typography className={styles.teaserTitle}>THE GOAT BOARD</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem} flexDirection={'column'}>
                            <Typography className={styles.teaserText}>
                                Felicitări! Ai ajuns la ultima provocare!
                            </Typography>
                            <Typography className={styles.teaserText}>
                                Desemnează cel mai norocos membru din echipă pentru a da cu zarul.
                            </Typography>
                            <Typography className={styles.teaserText}>
                                În funcție de căsuța pe care pică zarul, aveți o nouă provocare de rezolvat în echipă.
                            </Typography>
                            <Typography className={styles.teaserText}>
                                Dacă ratezi și zarul ajunge în afara board-ului, dai încă o dată cu zarul. Dacă ratezi și a doua oară, recomandăm să lași alt coechipier să își încerce norocul :).
                            </Typography>
                            <Typography className={styles.teaserTextSuccess}><b>Succes!</b></Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserItem}>
                            <Grid container justifyContent={'center'} alignItems={'stretch'} alignContent={'start'}>
                                <Grid item xs={12} lg={6} className={styles.boxZar}>
                                    <img src={imgZar1} alt={'imgZar1'} className={styles.imgZar}/>
                                    <Typography className={styles.zarTitle}>Zarul a căzut pe caseta cu nr. 1</Typography>
                                    <Typography className={styles.zarText}>ȚAPUL Kozel tocmai ce-a trecut pe-aici.Găsește urmele de ȚAP! N-ai cum să le ratezi, sunt maaari!</Typography>
                                </Grid>

                                <Grid item xs={12} lg={6} className={styles.boxZar}>
                                    <img src={imgZar2} alt={'imgZar2'} className={styles.imgZar}/>
                                    <Typography className={styles.zarTitle}>Zarul a căzut pe caseta cu nr. 2</Typography>
                                    <Typography className={styles.zarText}>Rezolvă puzzle-ul cât de rapid poți!</Typography>
                                </Grid>

                                <Grid item xs={12} lg={6} className={styles.boxZar}>
                                    <img src={imgZar3} alt={'imgZar3'} className={styles.imgZar}/>
                                    <Typography className={styles.zarTitle}>Zarul a căzut pe caseta cu nr. 3</Typography>
                                    <Typography className={styles.zarText}>Tradu mesajul GOATY - There is always room for one more!Așează cuvintele în ordinea corectă.</Typography>
                                </Grid>

                                <Grid item xs={12} lg={6} className={styles.boxZar}>
                                    <img src={imgZar4} alt={'imgZar4'} className={styles.imgZar}/>
                                    <Typography className={styles.zarTitle}>Zarul a căzut pe caseta cu nr. 4</Typography>
                                    <Typography className={styles.zarText}>Ordonează halbele de bere în funcție de cantitate - de la cea mai mică la cea mai mare!</Typography>
                                </Grid>

                                <Grid item xs={12} lg={6} className={styles.boxZar}>
                                    <img src={imgZar5} alt={'imgZar5'} className={styles.imgZar}/>
                                    <Typography className={styles.zarTitle}>Zarul a căzut pe caseta cu nr. 5</Typography>
                                    <Typography className={styles.zarText}>Rezolvă puzzle-ul cu cuvinte încrucișate și află mesajul ȚAPĂN!</Typography>
                                </Grid>

                                <Grid item xs={12} lg={6} className={styles.boxZar}>
                                    <img src={imgZar6} alt={'imgZar6'} className={styles.imgZar}/>
                                    <Typography className={styles.zarTitle}>Zarul a căzut pe caseta cu nr. 6</Typography>
                                    <Typography className={styles.zarText}>NOROCOSULE!</Typography>
                                    <Typography className={styles.zarText}>Ai finalizat provocările! Mergi direct la Premiu :)</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography className={styles.prizesText}>Rezolvarea celei de-a treia provocări aduce 50 de puncte grupului tău pe
                    kozel.ro</Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography className={styles.recommendGroupText}>Asigură-te că ai grupul format - accesează secțiunea
                    &nbsp;<NavLink to={paths.myGroup}>Grupul meu</NavLink> și invită-ți prietenii în comunitatea Kozel
                    Meeet!</Typography>
            </Grid>
        </Grid>
    )
}

export default Challenge3;