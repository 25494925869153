import React, {useCallback, useEffect, useState} from 'react';
import {listLocation} from '../components/locations/listLocations';
import { GoogleMapLocation, GoogleMapComponent } from '../components/GoogleMapComponent';

const KozelLocations: React.FC = () => {
    const [locations, setLocations] = useState<GoogleMapLocation[]>([]);
    
    useEffect(() => {
        setLocations(listLocation);
    }, []);

    return  (
        <>
            <GoogleMapComponent locations={locations}/>
        </>
    );
};

export default KozelLocations;
