import React from 'react';
import {Grid, Typography} from "@mui/material";
import Step from "../components/ncp/Step";
import useKozelNcpStyles from "../styles/ncp/kozel_ncp";
import MixTapan from "../components/ncp/MixTapan";
import FormSection from "../components/ncp/FormSection";
import {isBeforeAugust31} from "../utils/dateTimesUtils";

const KozelNcp: React.FC = () => {
    const kozelNcpStyles = useKozelNcpStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <MixTapan/>
            </Grid>
            {isBeforeAugust31() &&
                <Grid item xs={12}>
                    <Grid container className={kozelNcpStyles.stepsWrapper}>
                        <Grid item md={3} xs={12} className={kozelNcpStyles.wrapperStep}>
                            <Step index={1}>Cumpără kozel premimum & kozel dark pe un singur bon fiscal</Step>
                        </Grid>
                        <Grid item md={3} xs={12} className={kozelNcpStyles.wrapperStep}>
                            <Step index={2}>Înscrie numarul de bon pe <a href="https://www.kozel.ro" rel="noreferrer"
                                                                         target="_blank">www.kozel.ro</a> și încarcă
                                poza bonului</Step>
                        </Grid>
                        <Grid item md={3} xs={12} className={kozelNcpStyles.wrapperStep}>
                            <Step index={3}>Poți câștiga prin tragere la sorți unul dintre premiile campaniei</Step>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={kozelNcpStyles.textBeforeFormSection}>Selectează magazinul din care ai
                            achiziționat produsele Kozel Premium și Kozel Dark și introdu bonul fiscal:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormSection/>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

export default KozelNcp;