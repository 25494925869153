import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useGroupInvite = createUseStyles((theme: Theme) => {
    return {
        container: {
            justifyContent: "center",
            textAlign: "center",
            alignItems: 'center',
            alignContent: 'center',
        },
        containerItem: {
            display: 'flex',
            justifyContent: "center",
            textAlign: "center",
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
        },
        MT5: {
          marginTop: theme.spacing(5),
        },
        goatGif: {
            maxWidth: '350px',
            width: '25vw',
            height: 'auto',
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            color: theme.colors.latte_cream,
            fontWeight: 900,
        },
        text: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vw,2vh)',
            color: theme.colors.dark_chocolate,
            fontWeight: 'bold',
            paddingTop: theme.spacing(5)
        },
        text2: {
          extend:'text',
          fontWeight: 'normal',
        },
        inputTextField: {
            marginTop: theme.spacing(2),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        inputInvitationField: {
            marginTop: theme.spacing(2),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.colors.latte_cream,
                color: theme.colors.dark_chocolate,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
                '& .MuiOutlinedInput-input': {
                    textAlign: 'center',
                }
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.colors.golden_mustard,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        inputReferralField: {
            marginTop: theme.spacing(2),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.colors.latte_cream,
                color: theme.colors.dark_chocolate,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.colors.golden_mustard,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        button: {
            fontFamily: theme.fonts.apex,
            fontSize: '24px',
            fontWeight: 'bold',
            backgroundColor: theme.colors.latte_cream,
            color: theme.colors.dark_chocolate,
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.colors.latte_cream,
                backgroundColor: theme.colors.dark_chocolate,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: theme.spacing(3),
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '14px',
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            }
        },
        buttonCopy: {
            color: theme.colors.dark_chocolate,
            borderRightColor: theme.colors.dark_chocolate,
            borderRightStyle: 'solid',
            borderRightWidth: '2px',
            paddingRight: '5px',
            marginRight: '5px',
            cursor: 'pointer',
        },
        buttonShare: {
            color: theme.colors.dark_chocolate,
            borderLeftColor: theme.colors.dark_chocolate,
            borderLeftStyle: 'solid',
            borderLeftWidth: '2px',
            paddingLeft: '5px',
            marginLeft: '5px',
            cursor: 'pointer',
        }
    };
});

export default useGroupInvite;