import React from "react";
import {Grid, Typography} from "@mui/material";
import useMyGroupMix from "../../styles/my_group/myGroupMix";
import icon_tap_grup from '../../assets/images/my_group/icon_tap_grup.png';
import echipa_mea_icon from '../../assets/images/my_group/echipa_mea_icon.png';
import clsx from "clsx";
import {useAppSelector} from "../../stores/store";

const maxGroupMembers = 5;
const MyGroupMix: React.FC = () => {
    const myGroupMix = useMyGroupMix();
    const {members} = useAppSelector((state) => state.myGroup);
    const groupMembersCount = members ? members.length : 0;
    const leftInvitations = maxGroupMembers - groupMembersCount
    const groupParseView = Array.from({length: maxGroupMembers}, (_, i) => {
        return (members && members[i]) || {member_id: null, nick_name: ''};
    });

    if (!members) {
        return null;
    }
    return (
        <Grid container className={myGroupMix.wrapperContainer}>
            <Grid item xs={12}>
                <Grid container className={myGroupMix.container}>
                    <Grid item xs={12} className={myGroupMix.containerItem}>
                        <Grid container className={myGroupMix.sectionContainer}>
                            <Grid item xs={6}>
                                <img src={echipa_mea_icon} alt={'echipa_mea_icon'}
                                     className={myGroupMix.sectionContainerImg}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={myGroupMix.containerItemText}>Mai poți
                                    invita {leftInvitations} prieteni la tine în grup</Typography>
                                <Typography
                                    className={myGroupMix.containerItemText}> {groupMembersCount > 2 ? `${groupMembersCount - 1} prieteni au` : 'Un prieten a'} acceptat
                                    deja invitația</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={clsx(myGroupMix.containerItem, myGroupMix.borderTopDotted)}>
                        <Typography className={myGroupMix.containerItemTitle}>Mixul meu de prieteni</Typography>
                    </Grid>
                    <Grid item xs={12} className={myGroupMix.containerItem}>
                        <Grid container className={myGroupMix.sectionGroupView}>
                            {groupParseView.map((item, index) => (
                                <Grid key={index} item xs={6} lg={4}
                                      className={clsx(myGroupMix.sectionGroupViewMember, `${item.member_id ? '' : 'noMember'}`)}>
                                    <img src={icon_tap_grup} alt={'icon_tap_grup'} className={myGroupMix.memberIcon}/>
                                    <Typography className={myGroupMix.memberName}>
                                        {item.member_id ? (item.nick_name ? item.nick_name : 'G.O.A.T.#' + item.member_id) : '???'}
                                    </Typography>
                                    <Typography
                                        className={myGroupMix.memberStatus}>- {item.member_id ? 'Junior Virtual Tapster' : '???'} -</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default MyGroupMix;