import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useThursdayInviteStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                backgroundColor: theme.colors.latte_cream,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                paddingTop: theme.spacing(5),
            },
            boxImg: {
                display: "flex",
                justifyContent: 'center',
                alignItems: "center",
                alignContent: 'center',
            },
            imgIconClock: {
                textAlign: "center",
                alignSelf: 'center',
                width: '75%',
                height: 'auto',
            },
            boxBorderDots: {
                position: 'relative',
                borderBottomStyle: 'dotted',
                borderBottomWidth: '10px',
                borderBottomColor: theme.colors.dark_chocolate,
                paddingBottom: theme.spacing(5)
            },
            title: {
                paddingTop: theme.spacing(5),
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(3vw,3vh)',
                color: theme.colors.dark_chocolate,
                textAlign: "center",
            },
            arrowLeft: {
                position: "absolute",
                left: '0',
                top: '75%',
                transform: 'translate(-150%,0) rotate(-15deg)',
                width: '75px',
                height: 'auto',
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            },
            arrowRight: {
                position: "absolute",
                right: '0',
                top: '75%',
                transform: 'translate(150%,0) scaleX(-1) rotate(-15deg)',
                width: '75px',
                height: 'auto',
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            },
            text: {
                textAlign: 'center',
                fontSize: 'max(1.5vw,1.5vh)',
                fontFamily: theme.fonts.apex,
                fontWeight: theme.typography.fontWeightBold,
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
                }
            },
            boxSaveToCalendar: {
                justifyContent: 'center',
                alignItems: "center",
                alignContent: 'center',
                paddingBottom: theme.spacing(5),
            },
            subtitle: {
                textAlign: 'left',
                fontSize: 'max(1.5vw,1.5vh)',
                fontFamily: theme.fonts.poplar,
                fontWeight: theme.typography.fontWeightBold,
                [theme.breakpoints.down('lg')]: {
                    paddingRight: theme.spacing(3),
                }
            },
            imgIconCalendar: {
                width: '100%'
            },
        };
    }
)

export default useThursdayInviteStyles;
