import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useChallenge3Style = createUseStyles((theme: Theme) => {
    return {
        container: {
            justifyContent:'center',
            alignContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.colors.dark_chocolate,
            flexDirection: 'column',
            paddingTop: theme.spacing(3),
        },
        item:{
            display: 'flex',
            justifyContent:'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        logo: {
            width: '100%',
            [theme.breakpoints.down('lg')]:{
                width: '75%',
            }
        },
        motoText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vh,2vw)',
            fontWeight: 'bold',
            textAlign: "center",
            paddingTop: theme.spacing(3),
        },
        wrapperTeaserFrame: {
            padding: theme.spacing(3),
            marginTop: theme.spacing(15),
            [theme.breakpoints.down('lg')]:{
                marginTop: theme.spacing(5),
            }
        },
        teaserFrameTitle:{
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
            fontWeight: 'bold',
            textAlign: "center",
        },
        teaserContainer: {
            justifyContent:'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: `0 ${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]:{
                padding: `0 ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(2)}`,
            }
        },
        teaserItem: {
            display: 'flex',
            justifyContent:'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: `0 ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]:{
                padding: `0 ${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(0)}`,
            }
        },
        accessText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vh,2vw)',
            fontWeight: 'bold',
            textAlign: "center",
        },
        imgStep: {
            width: '100%',
            height: 'auto',
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
            fontWeight: 'bold',
            textAlign: "center",
            [theme.breakpoints.down('lg')]:{
                fontSize: 'max(4vh,4vw)',
            }
        },
        teaserText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vh,2vw)',
            paddingBottom: `${theme.spacing(5)}`,
            textAlign: "center",
            '&:last-child': {
                paddingBottom: `${theme.spacing(1)}`,
            }
        },
        teaserTextSuccess:{
            fontFamily: theme.fonts.poplar,
            color: theme.colors.latte_cream,
            fontSize: 'max(5vh,5vw)',
            paddingBottom: `${theme.spacing(5)}`,
            textAlign: "center",
            fontWeight: 'lighter',
            letterSpacing: '5px',
        },
        prizesText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vh,2vw)',
            paddingTop: `${theme.spacing(5)}`,
            paddingBottom: `${theme.spacing(5)}`,
            textAlign: "center",
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)}`,
            }
        },
        recommendGroupText: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(2vh,2vw)',
            textAlign: "center",
            paddingBottom: `${theme.spacing(5)}`,
            '& a': {
                textDecoration: 'underline',
                color: theme.colors.latte_cream,
            },
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)}`,
            }
        },
        boxZar: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            alignContent: 'center',
            padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(2)}`,
        },
        imgZar: {
            maxWidth: '250px',
            width: '15vw',
            [theme.breakpoints.down('lg')]: {
                width: '35vw',
            }
        },
        zarTitle: {
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(1.3vh,1.3vw)',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2.5vh,2.5vw)',
            }
        },
        zarText:{
            fontFamily: theme.fonts.apex,
            color: theme.colors.latte_cream,
            fontSize: 'max(1vh,1vw)',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vh,2vw)',
            }
        }
    }
});

export default useChallenge3Style;