import React from 'react';
import LoginModal from "./components/LoginModal";
import {closeLoginModal} from "./features/auth/authSlice";
import {useAppDispatch} from "./stores/store";

const AppModals: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleLoginSuccess = () => {
        dispatch(closeLoginModal());
    };

    return <>
        <LoginModal
            onRequestClose={() => dispatch(closeLoginModal())}
            onLoginSuccess={handleLoginSuccess}
        />
    </>
}

export default AppModals;