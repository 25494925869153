import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../stores/store';
import {getCoins, getProfile, ProfileState} from '../features/profile/profileSlice';

import {CircularProgress, Grid, List, ListItem, ListItemText, Typography,} from '@mui/material';
import {getPersonalData} from "../features/profile/personalDataProfileSlice";
import useProfileStyles from "../styles/profile";
import HelloComponent from "../components/profile/HelloComponent";
import TeaserFrame2 from "../components/TeaserFrame2";
import theme from "../styles/themes/default";
import GameSpinBottleHistory from "../components/kozelMeet/GameSpinBottleHistory";
import GameChallengeUntoldHistory from "../components/profile/GameChallengeUntoldHistory";


const Profile: React.FC = () => {
    const dispatch = useAppDispatch();
    const profileStyles = useProfileStyles();
    const profile: ProfileState = useAppSelector((state) => state.profile);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getPersonalData());
        dispatch(getCoins());
    }, [dispatch]);

    if (profile.loading) {
        return <CircularProgress/>;
    }

    return (
        <Grid container className={profileStyles.container} flexDirection={'column'}>
            <Grid item xs={12}>
                <HelloComponent/>
            </Grid>
            <Grid item xs={12}>
                <GameSpinBottleHistory/>
            </Grid>
            <Grid item xs={12}>
                <GameChallengeUntoldHistory/>
            </Grid>
            <Grid item xs={12} className={profileStyles.backgroundCreamLate}>
                <TeaserFrame2
                    title={<Typography className={profileStyles.titleHowGetCoins}>Cum câștig puncte pe
                        kozel.ro</Typography>}
                    titleMarginTop={'min(-5vh,-5vw)'}
                    backgroundBoxTitle={theme.colors.latte_cream}
                    colorBorderDotted={theme.colors.dark_chocolate}
                >
                    <List className={profileStyles.listHowGetCoins}>
                        <ListItem><ListItemText>primești 50 puncte pentru că ești aici și ai cont pe
                            kozel.ro</ListItemText></ListItem>
                        <ListItem><ListItemText>grupul tău adună 50 puncte pentru fiecare prieten nou intrat în
                            grup</ListItemText></ListItem>
                        <ListItem><ListItemText>primești 50 de puncte când îți completezi datele din profilul
                            tău</ListItemText></ListItem>
                        <ListItem><ListItemText>primești 50 de puncte de loialitate de fiecare dată când mai faci 1 an
                            pe kozel.ro</ListItemText></ListItem>
                        <ListItem><ListItemText>În plus, fii pe fază de fiecare dată când avem campanii speciale, unde
                            vom comunica cum poți strânge extra puncte.</ListItemText></ListItem>
                    </List>
                </TeaserFrame2>
            </Grid>
        </Grid>
    );
};

export default Profile;
