import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useWhatFindHereStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                justifyContent: "center",
                alignItems: 'center',
                alignContent: 'center',
                backgroundColor: theme.colors.ivory_beige,
            },
            title: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
                textAlign: 'center',
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(3vh,3vw)',
                color: theme.colors.dark_chocolate,

                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
                }
            },
            gamesContainer: {
                justifyContent: "center",
                alignItems: 'stretch',
                alignContent: 'center',
            },
            gameItem: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "flex-start",
                alignItems: 'center',
                alignContent: 'center',
                padding: `${theme.spacing(0)} ${theme.spacing(5)}`,
                [theme.breakpoints.down('lg')]:{
                    padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                }
            },
            img100w: {
                width: '100%',
            },
            gameText: {
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
                textAlign: 'center',
                fontFamily: theme.fonts.apex,
                fontSize: 'max(1.5vh,1.5vw)',
                color: theme.colors.dark_chocolate,
                fontWeight: theme.typography.fontWeightBold,
                [theme.breakpoints.down('lg')]:{
                    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
                },
                '& a': {
                    color: 'inherit',
                    textDecoration: 'underline',
                    textTransform: 'uppercase',
                    fontStyle: 'italic',
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    lineHeight: 1,
                },
            },
            crownIcon: {
                width: 'auto',
                height: 'max(3vh,3vw)',

            },
            containerSlideShow: {
                display: "flex",
                justifyContent: "center",
                padding: `${theme.spacing(1)} 0`,
            },
        };
    }
)

export default useWhatFindHereStyles;
