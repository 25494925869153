import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const totalScroll = scrollY;
            const scrollDuration = 500;
            let startTime: number | null = null;

            const scrollStep = (timestamp: number) => {
                if (!startTime) {
                    startTime = timestamp;
                }
                const progress = timestamp - startTime;
                const percentage = Math.min(progress / scrollDuration, 1);
                const easeOutQuad = (t: number) => t * (2 - t);

                window.scrollTo(0, totalScroll * (1 - easeOutQuad(percentage)));

                if (progress < scrollDuration) {
                    window.requestAnimationFrame(scrollStep);
                }
            };

            window.requestAnimationFrame(scrollStep);
        };

        handleScroll();
    }, [pathname]);

    return null;
};

export default ScrollToTop;
