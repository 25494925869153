import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    Typography
} from "@mui/material";
import meet_logo from "../../assets/images/kozel_meet/meet_logo.png";
import useFormRequestAccessStyles from "../../styles/kozelMeetBoardGameAccess/form_request_access";
// import {listLocation} from '../locations/listLocations';
import {listLocation} from '../locations/selfImplementedLocations';
import icon_arrow from '../../assets/images/icon_arrow.png';
import clsx from "clsx";
import {BoardGamePayloadProps, insertBoardGame} from '../../features/kozelMeet/boardGameSlice';
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {getProfile} from "../../features/profile/profileSlice";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const FormRequestAccess: React.FC = (props) => {
    const styles = useFormRequestAccessStyles();
    const [location, setLocation] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const dispatch = useAppDispatch();
    const {id, nick_name} = useAppSelector(state => state.profile);
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const {loading, errorMessage} = useAppSelector(state => state.kozelMeetBoardGame);
    const [sortedLocations, setSortedLocations] = useState(
        listLocation.sort(
            (a, b) => (a.city + a.name).localeCompare(b.city + b.name)
        )
    );
    // const [sortedLocations, setSortedLocations] = useState(listLocation.sort((a, b) => a.name.localeCompare(b.name)));

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getProfile());
        }
    }, [isAuthenticated]);
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedImage(file);
            setImageUrl(URL.createObjectURL(file));
        }
    };
    const handleSubmitImage = () => {
        if (selectedImage && location) {
            const payload: BoardGamePayloadProps = {
                location: listLocation[parseInt(location)],
                photo: selectedImage,
                extra: {},
            }
            if (isAuthenticated) {
                payload.extra = {userId: id, nick_name: nick_name}
            }
            dispatch(insertBoardGame(payload));
        }
    };
    const handleChange = (event: SelectChangeEvent) => {
        setLocation(event.target.value);
    };
    const isShowButtonSendForm = () => {
        if (loading) {
            return false;
        }
        if ((location?.toString().length < 1) ?? true) {
            return false;
        }

        return !!selectedImage;
    }

    const isShowMessageUploadPhoto = () => {
        if (loading) {
            return false;
        }

        return !isShowButtonSendForm();
    }

    return (
        <Grid container className={styles.container}>
            {/* <Grid item xs={12} className={styles.boxBorderWithCenterTitle}>
                <Typography className={styles.centerTopTitle}>INTRODUCING</Typography>
            </Grid> */}
            <Grid item xs={10} lg={4} display={"flex"} justifyContent={"center"}>
                <img src={meet_logo} alt={'MEEET KOZEL LOGO'} className={styles.logoKozelMeet}
                     style={{maxWidth: '425px', maxHeight: '240px'}}/>
            </Grid>
            <Grid item xs={10} lg={8} display={"flex"} justifyContent={"center"} mt={5}
                  className={styles.borderDottedBottom}>
                <Typography className={styles.title}>JOCURI, BERE, VOIE BUNĂ,<br/>
                    JOI NE DISTRĂM ÎMPREUNĂ</Typography>
            </Grid>
            <Grid item xs={10} lg={4} display={"flex"} justifyContent={"center"} mt={5}
                  className={styles.boxSelectLocations}>
                <Typography className={styles.textLocations}>Spune-ne locaţia în care te afli</Typography>
                <FormControl className={styles.formControlSelect}>
                    <Select
                        id="select-location"
                        value={location}
                        displayEmpty
                        label="VEZI LOCAȚIILE"
                        onChange={handleChange}
                        inputProps={{'aria-label': 'Without label'}}
                        className={styles.selectLocation}
                    >
                        <MenuItem value={''} selected={true} className={styles.menuItem}>ALEGE LOCAȚIA</MenuItem>
                        {sortedLocations.map((el, index) => (
                            <MenuItem key={index} value={index} className={styles.menuItem}>{el.city} - {el.name}</MenuItem>
                            // <MenuItem key={index} value={index} className={styles.menuItem}>{el.name} - {el.city}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={11} lg={10} display={"flex"} justifyContent={"center"} mt={5}
                  className={clsx(styles.item, styles.itemFormFile, styles.boxDotted)}>
                <Grid container className={clsx(styles.containerFormUpload, styles.borderDottedBottom)}>
                    <Grid item xs={12} lg={5} className={styles.containerItemFormUpload}>
                        {selectedImage && loading && <CircularProgress/>}
                        {isShowButtonSendForm()
                            && <Button className={styles.btnUploadFile} onClick={handleSubmitImage}>
                                Trimite poza
                            </Button>
                        }
                        {isShowMessageUploadPhoto() &&
                            <Typography className={styles.textUploadFile}>Încarcă o poză cu
                                berea Kozel și primești
                                codul de acces pentru
                                jocurile &#354;APENE!</Typography>}
                        {errorMessage && errorMessage.length > 0 &&
                            <Typography className={styles.errorMessageGetAccess}>{errorMessage}</Typography>}
                    </Grid>
                    <Grid item xs={12} lg={2} className={clsx(styles.containerItemFormUpload, styles.boxFormImgArrow)}>
                        <img src={icon_arrow} alt={'arrow'} className={styles.formIconArrow}/>
                    </Grid>
                    <Grid item xs={12} lg={5} className={styles.containerItemFormUpload}>
                        {imageUrl && <img src={imageUrl} alt="Poza selectată" className={styles.imgUploaded}/>}
                        <Button
                            component="label"
                            role={'img'}
                            variant={"contained"}
                            tabIndex={-1}
                            className={styles.btnUploadFile}
                        >
                            ÎNCARCĂ POZA
                            <VisuallyHiddenInput
                                type="file"
                                capture
                                onChange={handleImageChange}
                            />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={styles.textTips}>Tips: asigură-te că surprinzi esenţa berii! Pe ţapul
                            Kozel 🐐</Typography>
                        {/* <Typography className={styles.textTips}>Vezi <a target={'_blank'} rel="noreferrer"  href={`/pdf/2024/25-KOZEL x VOCEA ROMANIEI_regulament autentificat.pdf`}>regulamentul campaniei Kozel Meeet - Self-implemented</a></Typography>
                        <Typography className={styles.textTips}>Vezi <a target={'_blank'} rel="noreferrer"  href={`/pdf/2024/25-KOZEL x VOCEA ROMANIEI_regulament autentificat.pdf`}>regulamentul campaniei Kozel Meeet - Evenimente Speciale</a></Typography> */}
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default FormRequestAccess;
