import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import useServingRitualStyles from "../styles/serving_ritual";
import clsx from "clsx";
import DidYouKnowPresentation from "../components/DidYouKnowPresentation";
import video_1 from '../assets/videos/video-1.webm';
import video_2 from '../assets/videos/video-2.webm';
import video_3 from '../assets/videos/video-3.webm';
import video_4 from '../assets/videos/video-4.webm';

const ServingRitual: React.FC = () => {
    const servingRitualStyles = useServingRitualStyles();

    return (
        <Grid container>
            <Grid item xs={12} className={servingRitualStyles.section}>
                <Typography className={servingRitualStyles.title}>Savurează berea Kozel într-un mod
                    surprinzător</Typography>
            </Grid>
            <Grid item xs={12} className={servingRitualStyles.section}>
                <Grid container>
                    <Grid item md={6} sm={12} textAlign={'right'} className={clsx(servingRitualStyles.bgImgSection, servingRitualStyles.bgImgRitualServire1)}>
                        <video
                            autoPlay
                            loop
                            muted
                        >
                            <source
                                src={video_1}
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                    <Grid item md={6} sm={12} className={servingRitualStyles.subSectionRight}>
                        <Typography className={clsx(servingRitualStyles.sectionTitle, 'contrast')}>Modul perfect de a
                            turna
                            berea</Typography>
                        <Typography className={clsx(servingRitualStyles.sectionSubtitle, 'contrast')}>Modul perfect de a
                            turna în stil
                            tradițional ceh se numește “Hladinka”. Adică o halbă de bere plină, cu trei degete de spumă
                            cremoasă, care păstrează prospețimea și aroma.</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={servingRitualStyles.section}>
                <Grid container>
                    <Grid item md={6} sm={12} className={servingRitualStyles.subSectionLeft}>
                        <Typography className={servingRitualStyles.sectionTitle}>Kozel cu scorțisoară</Typography>
                        <Typography className={servingRitualStyles.sectionSubtitle}>Pentru echilibrul perfect între
                            dulce și condimentat, toarnă în halbă Kozel Dark și
                            apoi presară scorțisoară peste o formă specială de șablon, ca să obții logo-ul cu țapul
                            nostru pe spumă.</Typography>
                        <Typography className={servingRitualStyles.sectionSubtitle}><b>Vrei să faci propria ta artă?</b></Typography>
                        <Button className={servingRitualStyles.button}>Descarcă template-ul aici</Button>
                    </Grid>
                    <Grid item md={6} sm={12} className={clsx(servingRitualStyles.bgImgSection, servingRitualStyles.bgImgRitualServire2)}>
                        <video
                            autoPlay
                            loop
                            muted
                        >
                            <source
                                src={video_2}
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={servingRitualStyles.section}>
                <Grid container>
                    <Grid item md={6} sm={12} className={clsx(servingRitualStyles.bgImgSection, servingRitualStyles.bgImgRitualServire3)}>
                        <video
                            autoPlay
                            loop
                            muted
                        >
                            <source
                                src={video_3}
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                    <Grid item md={6} sm={12} className={servingRitualStyles.subSectionRight}>
                        <Typography className={clsx(servingRitualStyles.sectionTitle, 'contrast')}>Crustă de
                            miere</Typography>
                        <Typography className={clsx(servingRitualStyles.sectionSubtitle, 'contrast')}>Pentru un gust mai
                            intens de dulce și mirodenii, glazurează marginea halbei cu un
                            amestec de apă și miere, apoi trece-o prin scorțișoară și toarnă un Kozel Dark!</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={servingRitualStyles.section}>
                <Grid container>
                    <Grid item md={6} sm={12} className={servingRitualStyles.subSectionLeft}>
                        <Typography className={servingRitualStyles.sectionTitle}>Kozel Cut</Typography>
                        <Typography className={servingRitualStyles.sectionSubtitle}>Jumătate Kozel Premium Lager și
                            jumătate Kozel Dark! Mai întâi, umple o halbă pe
                            jumătate cu Kozel Premium, apoi completează cu Kozel Dark, care va pluti
                            deasupra.</Typography>
                    </Grid>
                    <Grid item md={6} sm={12} className={clsx(servingRitualStyles.bgImgSection, servingRitualStyles.bgImgRitualServire4)}>
                        <video
                            autoPlay
                            loop
                            muted
                        >
                            <source
                                src={video_4}
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={servingRitualStyles.section}>
                <Grid container>
                    <Grid item md={6} sm={12} textAlign={'right'} className={servingRitualStyles.bgImgRitualServire5}>
                    </Grid>
                    <Grid item md={6} sm={12} className={servingRitualStyles.subSectionRight}>
                        <Typography className={clsx(servingRitualStyles.sectionTitle, 'contrast')}>Kozel
                            Mix</Typography>
                        <Typography className={clsx(servingRitualStyles.sectionSubtitle, 'contrast')}>Amestecul perfect
                            de Kozel Premium și Kozel Dark. Toarnă ambele beri într-o halbă,
                            pentru un amestec cu gust plin și dulce, rubiniu la culoare!</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DidYouKnowPresentation title={'LUCRURI ȚAPENE'}
                                     subtitle={'Familia noastră de beri a câștigat peste 100 de premii!'}/>
            </Grid>
        </Grid>
    );
}

export default ServingRitual;