import React from 'react';
import useCarouselThumbsImagesStyles from "../styles/carouselThumbsImages";

interface CarouselImage {
    src: string;
    alt: string;
}

interface CarouselsProps {
    images: CarouselImage[];
    isForwardDir: boolean;
    duration: number; // in seconds
    slidesToShow: number;
}

const SlideShow: React.FC<CarouselsProps> = ({
                                                 images, ...props
                                             }) => {
    const carouselThumbsImagesStyles = useCarouselThumbsImagesStyles();

    return (
        <div className={carouselThumbsImagesStyles.slideContainer}
             style={{transform: `scaleX(${props.isForwardDir ? 1 : -1})`}}>
            <div className={carouselThumbsImagesStyles.slider}>
                <div
                    className={props.isForwardDir ? carouselThumbsImagesStyles.slideTrack : carouselThumbsImagesStyles.slideTrackBackward}>
                    {images.map((item, index) => (
                        <div key={index} className={carouselThumbsImagesStyles.slide}
                             style={{
                                 width: `${100/props.slidesToShow}vw`,
                                 height: `${100/props.slidesToShow}vw`
                             }}>
                            <img
                                src={item.src}
                                alt={`slide ${index}`}
                                className={carouselThumbsImagesStyles.slideImg}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SlideShow;
