import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {showNotification} from "../notification/notificationSlice";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import {ApiFailResponse} from "../../utils/interfaces/apiTypes";
import apiEndpoints from '../../utils/api/endpoints';

export interface ExternalCampaignPayloadProps {
    identify_name: string;
    birthdate: string;
    firstname: string;
    lastname: string;
    phone: string;
}
export interface ExternalCampaignStateProps {
    loading: boolean;
    success: boolean;
}

const initialState: ExternalCampaignStateProps = {
    loading: false,
    success: false,
}

export const insertExternalCampaign = createAsyncThunk(
    'externalCampaign/insertExternalCampaign',
    async (payload: ExternalCampaignPayloadProps, thunkAPI) => {
        try{
            const response = await apiInstance.post(apiEndpoints.externalCampaign, payload);
            thunkAPI.dispatch(showNotification({message: response.data.data['message'], variant: 'success'}));

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    }
);


const externalCampaignSlice = createSlice({
    name: "ncp",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(insertExternalCampaign.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(insertExternalCampaign.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(insertExternalCampaign.rejected, (state, action) => {
                state.loading = false;
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default externalCampaignSlice.reducer;